// ProtectedRoute.js

import React from 'react';
import {  Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/login" />;
  }

  if (user?.role !== "customer" || user?.status !== "active") {
    //return <Navigate to="/login" />;
  }

  return (
    <>
       {children}
    </>
  );
 
};

export default ProtectedRoute;
