import React, { useState } from "react";
import GoogleCaptcha from "../captcha";

import axiosWithAuth from "../../contexts/axiosWithAuth";
const ContactSection = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [plan, setPlan] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [sending, setSending] = useState(false);
  const [globalError, setGlobalError] = useState("");

  const [captcha, setCaptchaValue] = useState("");

  const handleRecaptchaChange = (value) => {
    // Handle CAPTCHA value change
    setCaptchaValue(value);
  };
  const sendMessage = async (credentials) => {
    try {
      setSending(true)
      const response = await axiosWithAuth().post(
        "/api/v1/send-message",
        credentials
      );
      return response;
    } catch (error) {
      throw error;
    } finally{
      setSending(false)
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = [];
    setGlobalError("");
    // Validate email
    if (!fullName) {
      newErrors.push("fullName");
    }
    if (!email) {
      newErrors.push("email");
    }
    if (!plan) {
      newErrors.push("plan");
    }
    if (!message) {
      newErrors.push("message");
    }

    // Validate checkbox
    if (!mobile) {
      newErrors.push("mobile");
    }
    if (!captcha) {
      setGlobalError("Captcha is required.");
    }

    // Set errors if any
    setErrors(newErrors);
    // If there are no errors, submit the form
    if (newErrors.length === 0 && !globalError) {
      try {
        let data = {
          message: message,
          mobile: mobile,
          email:email,
          fullName: fullName,
          plan: plan,
          captcha: captcha,
        };
        await sendMessage(data);
        setCompleted(true);
      } catch (error) {
        setGlobalError([error.response.data.message]);
      }
    }
  };

  return (
    /*==== Contact Area :: Start ==== */
    <section className="contact--area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mt_30">
            <div className="contact--text">
              <h4 className="tag-box">Your Queries Addressed</h4>
              <h3 className="sub--heading">Have a Question?</h3>
              <p>Learn More About MYSIGNAL Insurance and Takaful Solutions"</p>
              <div className="img--area">
                <img className="w-100" src="assets/images/contact.webp" alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt_30" id="contact">
         
            <div className="contact--form--box card--default text-center">
              <h3>Have a Question?</h3>
              <form onSubmit={handleSubmit}>
                <div className="input--group">
                  <input
                    className="form-control"
                    type="text"
                    name="fname"
                    id="fname"
                    placeholder="Full Name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                  <div
                    style={{
                      display: errors.includes("fullName") ? "block" : "none",
                    }}
                    className="invalid-feedback"
                  >
                    Full name is required
                  </div>
                </div>
                <div className="input--group">
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div style={{
                      display: errors.includes("email") ? "block" : "none",
                    }} className="invalid-feedback">Email is required</div>
                </div>
                <div className="input--group">
                  <input
                    className="form-control"
                    type="text"
                    name="number"
                    id="number"
                    placeholder="Mobile Number"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                  <div   style={{
                      display: errors.includes("phone") ? "block" : "none",
                    }} className="invalid-feedback">Email is required</div>
                </div>
                <div className="input--group mt_35">
                  <label htmlFor="plan">
                    Please select your preferred SIGNAL Plans
                  </label>
                  <select
                    className="form-select"
                    name="plan"
                    id="plan"
                    onChange={(e) => setPlan(e.target.value)}
                  >
                    <option value="SIGNAL Gold" defaultValue>
                      SIGNAL Gold
                    </option>
                    <option value="SIGNAL Life">SIGNAL Life</option>
                    <option value="SIGNAL Premier">SIGNAL Premier</option>
                    <option value="SIGNAL Family">SIGNAL Family</option>
                    <option value="SIGNAL i-Family">SIGNAL i-Family</option>
                    <option value="SIGNAL i-Life">SIGNAL i-Life</option>
                  </select>
                  <div  style={{
                      display: errors.includes("plan") ? "block" : "none",
                    }} className="invalid-feedback">Please Choose a plan</div>
                </div>
                <div className="input--group">
                  <textarea
                    className="form-control"
                    name="message"
                    id="message"
                    placeholder="Your Message"
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  <div  style={{
                      display: errors.includes("message") ? "block" : "none",
                    }} className="invalid-feedback">Message is required</div>
                </div>
                
                <div className="input--group btn--wrapper">
                  <GoogleCaptcha onChange={handleRecaptchaChange} />
                  
                </div>
                {globalError && (
                    <div class="mt-2 alert alert-danger">
                      <strong>Error!</strong> {globalError}
                    </div>
                  )}
                {completed ? (
                 
                    <div class="mt-2 alert alert-success">
                      <strong>Message Sent</strong>{" "}
                     
                    </div>
                
                ) : (
                  <div className="input--group btn--wrapper">
                    <button type="submit" disabled={sending} className="button btn-contact">
                     {sending  ? 'SENDING' : ' Send Message'}
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    /*==== Contact Area :: End ==== */
  );
};

export default ContactSection;
