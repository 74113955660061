import React from "react";
import Footer from "../components/footer";

import Header from "../components/header";
const PrivacyPage = () => {
  return (
    <>
      <Header title={"home"} />
      <div>
        <main>
          {/*} ==== Privacy Area :: Start ==== */}
          <section className="terms--area">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="terms--box">
                    <h1>Privacy & Policy</h1>
                    <p>
                      PLEASE NOTE: We reserve the right, at our sole discretion,
                      to change, modify or otherwise alter these Terms and
                      Conditions at any time. Unless otherwise indicated,
                      amendments will become effective immediately. Please
                      review these Terms and Conditions periodically. Your
                      continued use of the Site following the posting of changes
                      and/or modifications will constitute your acceptance of
                      the revised Terms and Conditions and the reasonableness of
                      these standards for notice of changes. For your
                      information, this page was last updated as of the date at
                      the top of these terms and conditions.
                    </p>
                    <p className="mt_20">
                      This Site may contain links to other independent
                      third-party Web sites ("Linked Sites”). These Linked Sites
                      are provided solely as a convenience to our visitors. Such
                      Linked Sites are not under our control, and we are not
                      responsible for and does not endorse the content of such
                      Linked Sites, including any information or materials
                      contained on such Linked Sites. You will need to make your
                      own independent judgment regarding your interaction with
                      these Linked Sites.
                    </p>
                    <p className="mt_20">
                      This Site and all its Contents are intended solely for
                      personal, non-commercial use. Except as expressly
                      provided, nothing within the Site shall be construed as
                      conferring any license under our or any third party's
                      intellectual property rights, whether by estoppel,
                      implication, waiver, or otherwise. Without limiting the
                      generality of the foregoing, you acknowledge and agree
                      that all content available through and used to operate the
                      Site and its services is protected by copyright,
                      trademark, patent, or other proprietary rights. You agree
                      not to: (a) modify, alter, or deface any of the
                      trademarks, service marks, trade dress (collectively
                      "Trademarks") or other intellectual property made
                      available by us in connection with the Site; (b) hold
                      yourself out as in any way sponsored by, affiliated with,
                      or endorsed by us, or any of our affiliates or service
                      providers; (c) use any of the Trademarks or other content
                      accessible through the Site for any purpose other than the
                      purpose for which we have made it available to you; (d)
                      defame or disparage us, our Trademarks, or any aspect of
                      the Site; and (e) adapt, translate, modify, decompile,
                      disassemble, or reverse engineer the Site or any software
                      or programs used in connection with it or its products and
                      services.The framing, mirroring, scraping or data mining
                      of the Site or any of its content in any form and by any
                      method is expressly prohibited.
                    </p>
                    <p className="mt_20">
                      By using the Site web sites you agree to indemnify us and
                      affiliated entities (collectively "Indemnities") and hold
                      them harmless from any and all claims and expenses,
                      including (without limitation) attorney's fees, arising
                      from your use of the Site web sites, your use of the
                      Products and Services, or your submission of ideas and/or
                      related materials to us or from any person's use of any
                      ID, membership or password you maintain with any portion
                      of the Site, regardless of whether such use is authorized
                      by you.
                    </p>
                    <p className="mt_20">
                      This Site is controlled, operated and administered by us
                      from our office in Kiev, Ukraine. We make no
                      representation that materials at this site are appropriate
                      or available for use at other locations outside of the
                      Ukraine and access to them from territories where their
                      contents are illegal is prohibited. If you access this
                      Site from a location outside of the Ukraine, you are
                      responsible for compliance with all local laws.
                    </p>
                    <p className="mt_20">
                      If any provision of these Terms and Conditions is held to
                      be invalid or unenforceable, the provision shall be
                      removed (or interpreted, if possible, in a manner as to be
                      enforceable), and the remaining provisions shall be
                      enforced. Headings are for reference purposes only and in
                      no way define, limit, construe or describe the scope or
                      extent of such section. Our failure to act with respect to
                      a breach by you or others does not waive our right to act
                      with respect to subsequent or similar breaches. These
                      Terms and Conditions set forth the entire understanding
                      and agreement between us with respect to the subject
                      matter contained herein and supersede any other agreement,
                      proposals and communications, written or oral, between our
                      representatives and you with respect to the subject matter
                      hereof, including any terms and conditions on any of
                      customer's documents or purchase orders
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* ==== Privacy Area :: End ==== */}
        </main>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPage;
