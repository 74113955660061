// axiosWithAuth.js

import axios from "axios";

const axiosWithAuth = (isDashboard = "") => {
  const instance = axios.create();

  instance.interceptors.request.use(
    async (config) => {
      const token =
        isDashboard === "stats"
          ? localStorage.getItem("accessStatsToken")
          : localStorage.getItem("accessToken");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      //console.log('error',error,isDashboard)
      const originalRequest = error.config;
      if (error.response.status === 328 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken =
          isDashboard === "stats"
            ? localStorage.getItem("refreshStatsToken")
            : localStorage.getItem("refreshToken");

            //console.log('refreshToken',refreshToken)
        if (refreshToken) {
          try {
            let url =
              isDashboard === "stats"
                ? "/api/v1/refresh-stats-token"
                : "/api/v1/refresh-token";
            let key =
              isDashboard === "stats" ? "accessStatsToken" : "accessToken";

            const response = await axios.post(url, {
              refreshToken: refreshToken,
            });
            const newAccessToken = response.data.accessToken;
            localStorage.setItem(key, newAccessToken);
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            return axios(originalRequest);
          } catch (error) {
            //('error',error,isDashboard)
            let key =
              isDashboard === "stats" ? "accessStatsToken" : "accessToken";
            let key2 =
              isDashboard === "stats" ? "refreshStatsToken" : "refreshToken";
            let item = localStorage.getItem("influencerId");
            let redirect =
              isDashboard === "stats"
                ? `/myinfluencerDashboard?id=${item}`
                : "/login";
            localStorage.removeItem(key);
            localStorage.removeItem(key2);
            window.location.href = redirect;
          }
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export default axiosWithAuth;
