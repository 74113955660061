import React from "react";

const EvolutionSection = () => {
  return (
    /*==== Hero Area :: Start ==== */

    <section className="evolution--area">
      <div className="container">
        {/* start section title  */}
        <div className="section--title">
          <h4 className="tag-box">Embarking on Innovation</h4>
          <h3>The Birth and Evolution of MYSIGNAL</h3>
          <p>
            From Conception to Connectivity, Unravel the Narrative of SIGNAL -
            Where Telco Meets Insurance in a Revolutionary Fusion.
          </p>
        </div>
        {/* end section title  */}
        <div className="row justify-content-center">
          <div className="col-xl-10 col-md-11 mt_30">
            <div className="row">
              <div className="col-md-7 mt_20">
                <div className="evo--text--box card--default">
                  <p>
                    SIGNAL (owned and managed by MySignal Marketing Sdn Bhd) is
                    a first of its kind telco-infused insurance and takaful
                    programme, providing crucial insurance and takaful
                    protection paired with a SIM card with customised telco
                    benefits. SIGNAL is neither an insurance/takaful provider
                    nor a telco operator, rather a collaboration between
                    renowned insurance and takaful providers (eg. Allianz,
                    Berjaya SOMPO etc) and leading telco operators in Malaysia
                    (eg. celcomdigi etc), offering a 2 in 1 unified mobile plan
                    for consumers
                  </p>
                </div>
              </div>
              <div className="col-md-5 mt_20">
                <div className="evo--img--box box1">
                  <img src="assets/images/evo1.webp" alt="" />
                </div>
              </div>
              <div className="col-md-7 mt_20">
                <div className="evo--img--box box2">
                  <img src="assets/images/evo2.webp" alt="" />
                </div>
              </div>
              <div className="col-md-5 mt_20">
                <div className="evo--img--box box3">
                  <img src="assets/images/evo3.webp" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    /*==== Hero Area :: End ==== */
  );
};

export default EvolutionSection;
