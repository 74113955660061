import React from 'react';

const SignalLifePlans = ({value,onPlanChange}) => {
    
    const handlePlanChange = (event) => {
            
        let value  = event.target.value
        let amount = value === "signallife" ? 62.00 : 72.00;
        onPlanChange(value,amount);
    };

    return (
        <div className="select--plan">
        <h4>Select Plan</h4>
        <div className="plan--option">
            <div className="input--group">
                <input type="radio" id="single-life" value="signallife" name="signallife" checked={value === "signallife"} onChange={handlePlanChange} />
                <label htmlFor="single-life">
                    <p>SIGNAL Life</p>
                    <span>RM62.00/Month</span>
                </label>
            </div>
            <div className="input--group ml_60">
                <input type="radio" id="single-life-plus" value="signallifeplus" name="signallifeplus" checked={value === "signallifeplus"} onChange={handlePlanChange} />
                <label htmlFor="single-life-plus">
                    <p>SIGNAL Life Plus</p>
                    <span>RM72.00/Month</span>
                </label>
            </div>
        </div>
    </div>
    );
}

export default SignalLifePlans;
