import React from 'react';

const ServiceProviders = ({onServiceChange}) => {
    
    const handleServiceChange = (event) => {
        onServiceChange(event.target.value);
    };

    return (
        <div className="plan--box telco--service mt_35">
			<div className="select--plan">
				<h4>Select Telco Service Provider</h4>
				<div className="plan--option">
					<div className="input--group">
						<input type="radio" onChange={handleServiceChange} checked id="celcomdigi" name="telco--group"
							value="celcomdigi" />
						<label htmlFor="celcomdigi">
							<p>celcomdigi</p>
						</label>
					</div>
				</div>
			</div>
	   </div>
    );
}

export default ServiceProviders;
