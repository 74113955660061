import React from "react";
import Footer from "../components/footer";

import Header from "../components/header";

const CareerPartner = () => {
  return (
    <>
      <Header title={"partner"} />
      <div>
      
        <section className="partners--area theme-background">
          <div className="container">
          <div className="section--title">
              <div className="col-md-12">
                <div className="inner--text">
                <h4 className="tag-box">Partners</h4>
                <h3 className="heading">
                Invitation to Collaborate with MYSIGNAL: Unlock New Income
                    Streams!
                </h3>
                </div>
              </div>
            </div>
            {/* end section title  */}
            <div className="row justify-content-center">
              <div className="row">
                <div className="col-md-12">
                  <div className="evo--text--box card--default">
                    <p className="font-20">
                      Are you ready to tap into an innovative opportunity that
                      promises not only significant income potential but also
                      recurring revenue streams? Look no further than MYSIGNAL –
                      a groundbreaking telco-infused insurance and takaful
                      programme that is revolutionizing the market landscape.
                    </p>
                  </div>
                </div>
                <div className="col-md-12 mt-5">
                  <div className="evo--text--box card--default">
                    <h3>Why Collaborate with MYSIGNAL?</h3>
                    <ul style={{ listStyleType: 'square' }}>
                      <li className="font-20">
                        <strong>Lucrative Income Potential:</strong> By
                        partnering with MYSIGNAL, you gain access to a lucrative
                        income stream driven by high-demand products that cater
                        to the evolving needs of consumers.
                      </li>
                      <li className="font-20">
                        <strong>Recurring Revenue Opportunities:</strong> Unlike
                        one-time sales, MYSIGNAL offers the opportunity for
                        recurring revenue through subscription-based models,
                        ensuring a steady stream of income over time.
                      </li>
                      <li className="font-20">
                        <strong>Market Differentiation:</strong> Stand out in
                        the competitive landscape by offering a truly unique
                        product that combines the best of telecommunications and
                        insurance services, setting your business apart from the
                        competition.
                      </li>
                      <li className="font-20">
                        <strong>Expanded Customer Reach:</strong> Leverage
                        MYSIGNAL's innovative product offering to reach new
                        customer segments and expand your market presence,
                        opening doors to new business opportunities.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-12 mt-5">
                  <div className="evo--text--box card--default">
                    <h3>How You Can Benefit : </h3>
                    <ul style={{ listStyleType: 'square' }}>
                      <li className="font-20">
                        <strong>Generous Commission Structures:</strong> Enjoy
                        competitive commission structures designed to reward
                        your efforts and drive mutual success.
                      </li>
                      <li className="font-20">
                        <strong>Comprehensive Training and Support:</strong>{" "}
                        Access dedicated training and support resources to equip
                        you with the knowledge and tools needed to succeed in
                        promoting and selling SIGNAL plans.
                      </li>
                      <li className="font-20">
                        <strong>Marketing Assistance:</strong> Benefit from
                        tailored marketing materials and support to effectively
                        promote SIGNAL plans to your target audience,
                        maximizing your earning potential.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-12 mt-5">
                  <div className="evo--text--box card--default">
                    <p className="font-20">
                      Join Us in Shaping the future of Telecommunications and
                      Insurance Infusion.
                    </p>
                    <p className="font-20">
                      Do not miss out on this unparalleled opportunity to
                      collaborate with MYSIGNAL and unlock new revenue streams
                      for your business. Whether you are an established
                      distributor or an aspiring entrepreneur, we invite you to
                      be part of our journey in redefining the intersection of
                      telecommunications and insurance.
                    </p>
                    <p className="font-20">
                      To learn more about partnership opportunities with
                      MYSIGNAL and to discuss how we can collaborate for mutual
                      success, please drop us a note providing your contact
                      details (Name, Mobile Number and/or Email) at
                      <b> partners@mysignal.com.my</b>. Our partnerships team will reach
                      out to you for a non-obligatory discussion.
                    </p>
                    <p className="font-20">
                      Together, let us pave the way for a future where
                      innovation meets opportunity, and where every connection
                      brings meaningful value to consumers' lives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default CareerPartner;
