import React from "react";

const ConnectSection = () => {
  return (
    /*==== Connect Area :: Start ==== */
    <section
      className="connect--area" id="connect"
      style={{ backgroundImage: "url(assets/images/testi-bg.webp)" }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9 col-md-11">
            <div className="row justify-content-center">
              <div className="col-lg-5 mt_30 order-sm-2">
                <div className="img--area">
                  <img src="assets/images/connect.webp" alt="" />
                </div>
              </div>
              <div className="col-lg-7 mt_30 order-sm-1">
                <div className="connect--text">
                  <h4 className="tag-box">Get in Touch</h4>
                  <h3 className="sub--heading">
                    Connect with Our Dedicated Team
                  </h3>
                  <p>
                    Reach Out for Support, Inquiries, Collaborations, or to
                    Explore Tailored Solutions for Your Insurance and Takaful
                    Needs. Our Experts Await to Assist You in Securing
                    Comprehensive Protection.
                  </p>
                  <div className="connect--box card--default">
                    <p>
                      <img src="assets/images/signal.svg" alt="" />
                      MySignal Marketing Sdn Bhd [201901003462 / 1312788 – U]
                    </p>
                    <p className="mt_15">
                      <img src="assets/images/location.svg" alt="" />
                      A-15-1, Block A, JayaONE 72A Jalan Prof. Diraja Ungku Aziz
                      46200 Petaling Jaya, Selangor Darul Ehsan, Malaysia
                    </p>
                    <a
                      className="mt_15 d-inline-block"
                      href="mailto:enquiry@mysignal.com.my"
                    >
                      <img src="assets/images/email.svg" alt="" />
                      enquiry@mysignal.com.my
                    </a>
                    <p className="mt_15">
                      <img src="assets/images/phone.svg" alt="" />
                      +603 2779 2419
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    /*==== Connect Area :: End ==== */
  );
};

export default ConnectSection;
