import React from "react";
import Footer from "../components/footer";
import HeroSection from "../components/sections/about-hero";
import EvolutionSection from "../components/sections/Evolution";
//import TeamSection from "../components/sections/team";
import PartnershipSection from "../components/sections/partnership";
import Header from "../components/header";
const AboutPage = () => {
  return (
    <>
      <Header title={"about"} />
      <div>
        <HeroSection />
        <EvolutionSection />
        {/* <TeamSection /> */}
        <PartnershipSection />
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
