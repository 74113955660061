import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";

const Header = ({ title, toClose }) => {
  const [joinOPen, setJoinOPen] = useState(false);

  const toggleSetDropdown = () => {
    setJoinOPen(!joinOPen);
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const { user, logout } = useAuth();
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  // Close menu on page load or when user is redirected
  useEffect(() => {
    setMenuOpen(false);
    if (toClose) {
      toggleMenuClasses();
    }
    // Call function to toggle menu classes
  }, [toClose]); // Trigger effect when title changes
  // Function to toggle the 'current' class on menu elements
  const toggleMenuClasses = () => {
    // Toggle 'current' class on menu elements
    document
      .querySelectorAll(".mobile-menu, .line-bottom, .line-top, .line-center")
      .forEach((element) => {
        element.classList.toggle("current");
      });
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {}
  };

  return (
    /*==== Header :: Start ==== */
    <header className="">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-3 col-lg-2 col-3">
            {/* logo start  */}
            <a href="/" className="logo">
              <img src="assets/images/logo.webp" alt="" />
            </a>
            {/* logo end  */}
          </div>
          <div className="col-xl-6 col-lg-8 col-6 d-flex align-items-center justify-content-around">
            {/* start menu  */}
            <ul className="menu">
              <li>
                <a href="/" className={title === "home" ? "active" : ""}>
                  Home
                </a>
              </li>
              <li>
                <a href="/#plans" className={title === "plans" ? "active" : ""}>
                  Our Plans
                </a>
              </li>
              <li>
                <a href="/about" className={title === "about" ? "active" : ""}>
                  About Us
                </a>
              </li>
              <li>
                <div>
                  <span
                    className={
                      title === "our-team" || title === "our-partner"
                        ? "dropdown-toggle active"
                        : "dropdown-toggle"
                    }
                    onClick={toggleSetDropdown}
                   
                    aria-haspopup="true"
                    aria-expanded={joinOPen ? "true" : "false"}
                  >
                    Join Us
                  </span>
                  {joinOPen && (
                    <div
                      className="custom-dropdown dropdown-menu menu show"
                      style={{
                        backgroundColor: "#f1e1cd",
                        border: "none",
                      }}
                    >
                      <a
                        href="/our-team"
                        className={`${
                          title === "team"
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }`}
                      >
                        {" "}
                        In Our Team
                      </a>{" "}
                      <a
                        href="/our-partner"
                        className={`${
                          title === "partner"
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }`}
                      >
                        As Our Partner
                      </a>
                    </div>
                  )}
                </div>
              </li>

              <li>
                <a
                  href="/#contact"
                  className={title === "contact" ? "active" : ""}
                >
                  Contact Us
                </a>
              </li>
            </ul>
            {/* end menu  */}
          </div>
          <div className="col-xl-3 col-lg-2 col-3">
            <div className="header--btn">
              {user ? (
                <div className="dropdown">
                  <button
                    className="button btn--soldify dropdown-toggle"
                    type="button"
                    onClick={toggleDropdown}
                  >
                    Welcome, {user}
                  </button>
                  {isOpen && (
                    <div className="dropdown-menu show">
                      <a className="dropdown-item" href="/dashboard">
                        Dashboard
                      </a>
                      <button className="dropdown-item" onClick={handleLogout}>
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <a className="button" href="/login">
                  Login
                </a>
              )}
            </div>
            {/* menu toggler */}
            <div
              className={`hamburger-menu ${menuOpen ? "current" : ""}`}
              onClick={() => {
                toggleMenu();
                toggleMenuClasses();
              }}
            >
              <span className={`line-top ${menuOpen ? "current" : ""}`}></span>
              <span
                className={`line-center ${menuOpen ? "current" : ""}`}
              ></span>
              <span
                className={`line-bottom ${menuOpen ? "current" : ""}`}
              ></span>
            </div>
          </div>
          {/* mobile menu  */}
          <div className={`mobile--menu d-none ${menuOpen ? "current" : ""}`}>
            <ul className="menu">
              <li>
                <a href="/" className={title === "home" ? "active" : ""}>
                  Home
                </a>
              </li>
              <li>
                <a href="/#plans" className={title === "plans" ? "active" : ""}>
                  Our Plans
                </a>
              </li>
              <li>
                <a href="/about" className={title === "about" ? "active" : ""}>
                  About Us
                </a>
              </li>
              <li>
                <div>
                  <span
                    href="#"
                    className={
                      title === "our-team" || title === "our-partner"
                        ? "dropdown-toggle active"
                        : "dropdown-toggle"
                    }
                    onClick={toggleSetDropdown}
                   
                    aria-haspopup="true"
                    aria-expanded={joinOPen ? "true" : "false"}
                  >
                    Join Us
                  </span>

                  {joinOPen && (
                    <div
                      className="custom-dropdown dropdown-menu menu show"
                      style={{
                        backgroundColor: "#f1e1cd",

                        border: "none",
                      }}
                    >
                      <a
                        href="/our-team"
                        className={`${
                          title === "team"
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }`}
                      >
                        {" "}
                        In Our Team
                      </a>{" "}
                      <a
                        href="/our-partner"
                        className={`${
                          title === "partner"
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }`}
                      >
                        As Our Partner
                      </a>
                    </div>
                  )}
                </div>
              </li>
              <li>
                <a
                  href="/#contact"
                  className={title === "contact" ? "active" : ""}
                >
                  Contact Us
                </a>
              </li>
            </ul>
            <div className="header--btn">
              {user ? (
                <div className="dropdown">
                  <button
                    className="button btn--soldify dropdown-toggle"
                    type="button"
                    onClick={toggleDropdown}
                  >
                    Welcome, {user}
                  </button>
                  {isOpen && (
                    <div className="dropdown-menu show">
                      <a class="dropdown-item" href="/dashboard">
                        Dashboard
                      </a>
                      <button class="dropdown-item" onClick={handleLogout}>
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <a className="button" href="/login">
                  Login
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
    /* ==== Header :: End ==== */
  );
};

export default Header;
