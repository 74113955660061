import React from "react";
import Footer from "../components/footer";
import DashboardSection from "../components/sections/dashboard";
import Header from "../components/header";
const DashboardPage = () => {
  return (
    <>
      <Header />
      <main>
        <DashboardSection />
      </main>
      <Footer />
    </>
  );
};

export default DashboardPage;
