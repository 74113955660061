import React from 'react';
import SignalILifePlans from './signalILifePlans';
import SignalIFamilyPlans from './signalIFamilyPlans';
import SignalLifePlans from './signalLifePlans';
import SignalGoldPlans from './signalGoldPlans';
import SignalPremierPlans from './signalPremierPlans';
import SignalFamilyPlans from './signalFamilyPlans';
const PlanList = ({title,option,onPurchaseChange}) => {
        
        
        const setPlanChange = (value,price) => {
            onPurchaseChange(value,price);
        };
        
        switch (title) {
            case "signal-i-life":
                return <SignalILifePlans value={option} onPlanChange={setPlanChange}
                          />;
             case "signal-i-family":
                return <SignalIFamilyPlans value={option} onPlanChange={setPlanChange}
                         />;
            case "signallife":
            return <SignalLifePlans value={option} onPlanChange={setPlanChange}
                        />;
            case "signalgold":
            return <SignalGoldPlans value={option} onPlanChange={setPlanChange}
                        />;
            case "signal_premier":
            return <SignalPremierPlans value={option} onPlanChange={setPlanChange}
                        />;
            case "signalfamily":
            return <SignalFamilyPlans value={option} onPlanChange={setPlanChange}
                        />;                                                            
            default:
                return null; // You can handle other cases accordingly
        }
    
}

export default PlanList;
