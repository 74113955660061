import React from "react";
const ImagePopup = ({ imageUrl, onClose }) => {
  const downloadImage = (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "image.webp";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="overlay" onClick={onClose}>
       <div className="popup">
        <img  className="medium-image" src={imageUrl} alt="" />
        <div className="button-container">
             
        <button className="popup-button close" onClick={onClose}>
            Close
          </button>
          <button
            className="popup-button download"
            onClick={() => downloadImage(imageUrl)}
          >
            Download
          </button>
            </div>
      </div>
    </div>
  );
};

export default ImagePopup;
