import React  from 'react';

const SignalILifePlans = ({value,onPlanChange}) => {
    
    const handlePlanChange = (event) => {
        onPlanChange(event.target.value,56.00);
    };

    return (
        <div className="select--plan">
        <h4>Select Plan</h4>
        <div className="plan--option">
            <div className="input--group">
                <input type="radio" id="single-life" name="signal-i-life" value="signal-i-life"  checked={value === "signal-i-life"} onChange={handlePlanChange} />
                <label htmlFor="single-life">
                    <p>SIGNAL i-Life</p>
                    <span>RM56.00/Month</span>
                </label>
            </div>
        </div>
    </div>
    );
}

export default SignalILifePlans;
