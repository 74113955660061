import React, { useState, useEffect } from "react";
import { useLocation , useNavigate, useParams  } from "react-router-dom";
import HeroSection from "../components/sections/hero";
import ProtectionSection from "../components/sections/protection";
import PartnershipSection from "../components/sections/partnership";
import InsurancePlansSection from "../components/sections/insurance_telco_plans";
import PlansSection from "../components/sections/takaful_telco_plans";
import ContactSection from "../components/sections/contact";
import FAQSection from "../components/sections/faq";
import ConnectSection from "../components/sections/connect";
import TestimonialSection from "../components/sections/testimonial";
import Header from "../components/header";
import Footer from "../components/footer";
import { PlanRedirect } from "../utils/utils";
const HomePage = () => {
  const location = useLocation();
  const navigate  = useNavigate();
   // Access the slug parameter from the URL
   const { slug } = useParams();
  const [title, setTitle] = useState("home");
  const [showPromo, setShowPromo] = useState(true);

  const [toClose, setToClose] = useState(false);
  useEffect(() => {
    // Scroll to the target section when the page loads or when the location changes
    const targetSectionId = location.hash.substring(1); // Remove the '#' character
    const targetSection = document.getElementById(targetSectionId);
    //const searchParams = new URLSearchParams(location.search);
    // const isShowParam = searchParams.get('isShow');

    // if (isShowParam === '1') {
    //   setShowPromo(true);
    // } else {
    //   setShowPromo(false);
    // }
    if (targetSection) {
      setTitle(targetSectionId);
      setToClose(true);
      // Use smooth scrolling behavior
      targetSection.scrollIntoView({ behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);
  // const handlePromoClose = () => {
  //   // Hide the promo image permanently
  //   setShowPromo(false);
  //   // You can store this in local storage to prevent showing it again
  //   localStorage.setItem("promoClosedAt", Date.now());
  // };
  useEffect(() => {
    // Check if the promo was closed within the last 24 hours
    const promoClosedAt = localStorage.getItem("promoClosedAt");
    if (promoClosedAt) {
      setShowPromo(false);
    }
  }, []);


   // Check if the slug is "contact", then redirect to the Contact page
   useEffect(() => {
    if (slug && slug !== "") {
      const url = PlanRedirect(slug)
      if(url){
        navigate(url)
      }
    }
  }, [slug,navigate]);

  return (
    <>
      <Header title={title} toClose={toClose} />
      <main>
        {showPromo && (
          <></>
        //   <div className="promo-container">
        //   <div className="promo-content">
        //     <img
        //       className="promo-image"
        //       src="assets/images/promo.png"
        //       alt=""
             
        //     />
        //     <div className="button-container">
        //       <button className="close-button btn btn-md" onClick={handlePromoClose}>
        //         Close
        //       </button>
            
        //     </div>
        //   </div>
        // </div>
        
        )}
        <HeroSection />
        <ProtectionSection />
        <InsurancePlansSection />
        <PlansSection />
        <FAQSection />
        <TestimonialSection />
        <ContactSection />
        <PartnershipSection />
        <ConnectSection />
      </main>
      <Footer />
    </>
  );
};

export default HomePage;
