import React, { useEffect, useState } from "react";
import AgreementBox from "./agreementBox";
import DatePicker from "react-datepicker";
import { AssetsURLWhenInfluencer } from "../../utils/utils";

const GetForm = ({
  category,
  onFormUpdate,
  errors,
  classValue,
  title,
  label,
  preformData,
  customErrors,
}) => {
  // Local state to hold the form data
  const [preformDataLocal, setFormData] = useState(preformData);
  //console.log("preformDataLocal",preformDataLocal)
  //console.log(customErrors[category]["nric"], category,errors);
  // Update local state when preformData changes
  useEffect(() => {
    setFormData(preformData);
  }, [preformData]);

  const countryOptions = [
    { value: "", label: "Nationality" },
    { value: "Afghanistan", label: "Afghanistan" },
    { value: "Albania", label: "Albania" },
    { value: "Algeria", label: "Algeria" },
    { value: "Andorra", label: "Andorra" },
    { value: "Angola", label: "Angola" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Azerbaijan", label: "Azerbaijan" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Belgium", label: "Belgium" },
    { value: "Benin", label: "Benin" },
    { value: "Bolivia", label: "Bolivia" },
    { value: "Brazil", label: "Brazil" },
    { value: "Bulgaria", label: "Bulgaria" },
    { value: "Cambodia", label: "Cambodia" },
    { value: "Cameroon", label: "Cameroon" },
    { value: "Canada", label: "Canada" },
    { value: "Chile", label: "Chile" },
    { value: "China", label: "China" },
    { value: "Colombia", label: "Colombia" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Croatia", label: "Croatia" },
    { value: "Czech Republic", label: "Czech Republic" },
    { value: "Denmark", label: "Denmark" },
    { value: "Dominican Republic", label: "Dominican Republic" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Egypt", label: "Egypt" },
    { value: "Estonia", label: "Estonia" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "Georgia", label: "Georgia" },
    { value: "Germany", label: "Germany" },
    { value: "Greece", label: "Greece" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Honduras", label: "Honduras" },
    { value: "Hungary", label: "Hungary" },
    { value: "Iceland", label: "Iceland" },
    { value: "India", label: "India" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Iran", label: "Iran" },
    { value: "Iraq", label: "Iraq" },
    { value: "Ireland", label: "Ireland" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Jamaica", label: "Jamaica" },
    { value: "Japan", label: "Japan" },
    { value: "Jordan", label: "Jordan" },
    { value: "Kazakhstan", label: "Kazakhstan" },
    { value: "Kenya", label: "Kenya" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Latvia", label: "Latvia" },
    { value: "Lebanon", label: "Lebanon" },
    { value: "Lithuania", label: "Lithuania" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Malta", label: "Malta" },
    { value: "Mexico", label: "Mexico" },
    { value: "Moldova", label: "Moldova" },
    { value: "Morocco", label: "Morocco" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Nigeria", label: "Nigeria" },
    { value: "Norway", label: "Norway" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Panama", label: "Panama" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Peru", label: "Peru" },
    { value: "Philippines", label: "Philippines" },
    { value: "Poland", label: "Poland" },
    { value: "Portugal", label: "Portugal" },
    { value: "Qatar", label: "Qatar" },
    { value: "Romania", label: "Romania" },
    { value: "Russia", label: "Russia" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Serbia", label: "Serbia" },
    { value: "Singapore", label: "Singapore" },
    { value: "Slovakia", label: "Slovakia" },
    { value: "Slovenia", label: "Slovenia" },
    { value: "South Africa", label: "South Africa" },
    { value: "South Korea", label: "South Korea" },
    { value: "Spain", label: "Spain" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Syria", label: "Syria" },
    { value: "Taiwan", label: "Taiwan" },
    { value: "Thailand", label: "Thailand" },
    { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    { value: "Tunisia", label: "Tunisia" },
    { value: "Turkey", label: "Turkey" },
    { value: "Uganda", label: "Uganda" },
    { value: "Ukraine", label: "Ukraine" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States", label: "United States" },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Venezuela", label: "Venezuela" },
  ];

  const postcodeOptions = [
    { value: "Perlis", label: "Perlis" },
    { value: "Kedah", label: "Kedah" },
    { value: "Penang", label: "Penang" },
    { value: "Perak", label: "Perak" },
    { value: "Selangor", label: "Selangor" },
    { value: "Negeri Sembilan", label: "Negeri Sembilan" },
    { value: "Malacca", label: "Malacca" },
    { value: "Johor", label: "Johor" },
    { value: "Pahang", label: "Pahang" },
    { value: "Kelantan", label: "Kelantan" },
    { value: "Terengganu", label: "Terengganu" },
    { value: "Sabah", label: "Sabah" },
    { value: "Sarawak", label: "Sarawak" },
    { value: "Kuala Lumpur", label: "Kuala Lumpur" },
    { value: "Labuan", label: "Labuan" },
    { value: "Putrajaya", label: "Putrajaya" },
  ];

  // const onDateFocus = (e) => {
  //   e.target.type = "date"; // Change type to date on focus
  //   e.target.setAttribute("placeholder", "Date of Birth [dd/mm/yyyy]"); // Remove placeholder on focus
  // };

  // const onDateBlur = (e) => {
  //   if (!e.target.value) {
  //     e.target.type = "text"; // Change type back to text if value is empty
  //     e.target.setAttribute("placeholder", "Date of Birth [dd/mm/yyyy]"); // Restore placeholder
  //   }
  // };

  const handleCheckBox = (event) => {
    event.preventDefault();
    let value = event.target.checked ? "1" : "";

    onFormUpdate(category, "riskLocationSameaslocation", value);
  };
  const [cursorPositions, setCursorPositions] = useState({});

  const setCursorPositionIfConditionMet = (
    nationality,
    preformDataLocal,
    input,
    name,
    category
  ) => {
    if (
      input &&
      preformDataLocal.nationality === nationality &&
      cursorPositions[category] &&
      cursorPositions[category].inputName === name
    ) {
      const cursorPosition = cursorPositions[category].cursorPosition;
      if (cursorPosition !== null && cursorPosition !== undefined) {
        input.setSelectionRange(cursorPosition, cursorPosition);
      }
    }
  };

  const handleDateChange = (name, value) => {
    onFormUpdate(category, name, value);
  };
  const handleChange = (name, event) => {
    let formattedValue = event.target.value;
    onFormUpdate(category, name, formattedValue);

    setCursorPositions((prevPositions) => ({
      ...prevPositions,
      [category]: {
        inputName: name,
        cursorPosition: event.target.selectionStart,
      },
    }));
  };
  const handleAgreementChange = (category, name, value) => {
    onFormUpdate(category, name, value);
  };
  // Function to handle file changes
  const handleFileChange = (name, files) => {
    const file = files[0]; // Assuming single file selection
    // Check if file is less than 500KB
    onFormUpdate(category, name, file);
  };

  const getImageURL = () => {
    let url = AssetsURLWhenInfluencer("upload.svg")
    return url
  };


  const shouldRenderForm = title && label;
  return (
    <div
      className={
        classValue ? classValue : "personal--details--box card--default"
      }
    >
      <div className="personal--details--box--heading radio--box--default">
        <div className="input--group">
          <label htmlFor={label ? label + " Details" : "Form Details"}>
            {title ? title + " Details" : "Details"}
          </label>
          <small className="d-block mt-2 text-secondary">
           
            {preformDataLocal[category]?.optional === "true" ? (
              <div className="input--group">
                <input
                  type="checkbox"
                  id={`exclude-child2-${category}`}
                  value={
                    preformDataLocal[category]?.required === "true"
                      ? "false"
                      : "true"
                  }
                  checked={preformDataLocal[category]?.required === "false"}
                  onChange={(e) => handleChange("required", e)}
                  name={`exclude-child2-${category}`}
                />
                <label htmlFor={`exclude-child2-${category}`}>
                  <p>Click To Exclude Child 2</p>
                </label>
              </div>
            ) : (
              <>Note: All fields are mandatory</>
            )}
          </small>
        </div>
      </div>
      {preformDataLocal[category]?.optional === "true" &&
      preformDataLocal[category]?.required === "false" ? (
        <></>
      ) : (
        <div className="row">
          <div className="col-lg-6">
            <div className="form--malaysian personal--info--box">
              <h4>Malaysian</h4>
              <div className="input--group">
                <input
                  className="form-control"
                  value={
                    preformDataLocal.nationality === "malaysian"
                      ? preformDataLocal[category]?.name || ""
                      : ""
                  }
                  onChange={(e) => handleChange("name", e)}
                  type="text"
                  name="m-full-name"
                  id="m-full-name"
                  placeholder="Fullname as per NRIC"
                  ref={(input) =>
                    setCursorPositionIfConditionMet(
                      "malaysian",
                      preformDataLocal,
                      input,
                      "name",
                      category
                    )
                  }
                  {...(preformDataLocal.nationality === "malaysian"
                    ? {}
                    : { disabled: true })}
                />
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("name") &&
                      preformDataLocal.nationality === "malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  {customErrors[category] && customErrors[category]["name"]
                    ? customErrors[category]["name"]
                    : "Full Name is required"}
                </div>
              </div>
              <div className="input--group">
                <input
                  value={
                    preformDataLocal.nationality === "malaysian"
                      ? preformDataLocal[category]?.nric || ""
                      : ""
                  }
                  className="form-control"
                  onChange={(e) => handleChange("nric", e)}
                  type="text"
                  name="m-nric-number"
                  id="m-nric-number"
                  placeholder="NRIC Number"
                  {...(preformDataLocal.nationality === "malaysian"
                    ? {}
                    : { disabled: true })}
                  ref={(input) =>
                    setCursorPositionIfConditionMet(
                      "malaysian",
                      preformDataLocal,
                      input,
                      "nric",
                      category
                    )
                  }
                />
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      ((customErrors[category] &&
                        customErrors[category]["nric"]) ||
                        errors[category].includes("nric")) &&
                      preformDataLocal.nationality === "malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  {customErrors[category] && customErrors[category]["nric"]
                    ? customErrors[category]["nric"]
                    : "NRIC Number is required"}
                </div>
              </div>
              <div className="input--group">
                <input
                  className="form-control"
                  value={
                    preformDataLocal.nationality === "malaysian"
                      ? preformDataLocal[category]?.mobile || ""
                      : ""
                  }
                  onChange={(e) => handleChange("mobile", e)}
                  type="text"
                  name="m-mobile-number"
                  id="m-mobile-number"
                  placeholder="Mobile Number [with prefix +601]"
                  {...(preformDataLocal.nationality === "malaysian"
                    ? {}
                    : { disabled: true })}
                  ref={(input) =>
                    setCursorPositionIfConditionMet(
                      "malaysian",
                      preformDataLocal,
                      input,
                      "mobile",
                      category
                    )
                  }
                />
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      ((customErrors[category] &&
                        customErrors[category]["mobile"]) ||
                        errors[category].includes("mobile")) &&
                      preformDataLocal.nationality === "malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  {customErrors[category] && customErrors[category]["mobile"]
                    ? customErrors[category]["mobile"]
                    : "Mobile Number is required"}
                </div>
              </div>
              <div className="input--group">
                <input
                  className="form-control"
                  value={
                    preformDataLocal.nationality === "malaysian"
                      ? preformDataLocal[category]?.email || ""
                      : ""
                  }
                  onChange={(e) => handleChange("email", e)}
                  type="text"
                  name="m-email"
                  id="m-email"
                  placeholder="Email"
                  {...(preformDataLocal.nationality === "malaysian"
                    ? {}
                    : { disabled: true })}
                  ref={(input) =>
                    setCursorPositionIfConditionMet(
                      "malaysian",
                      preformDataLocal,
                      input,
                      "email",
                      category
                    )
                  }
                />
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("email") &&
                      preformDataLocal.nationality === "malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  {customErrors[category] && customErrors[category]["email"]
                    ? customErrors[category]["email"]
                    : "Email is required"}
                </div>
              </div>
              <div className="input--group">
                <input
                  className="form-control"
                  value={
                    preformDataLocal.nationality === "malaysian"
                      ? preformDataLocal[category]?.address || ""
                      : ""
                  }
                  onChange={(e) => handleChange("address", e)}
                  type="text"
                  name="m-address"
                  id="m-address"
                  placeholder="Correspondence Address"
                  {...(preformDataLocal.nationality === "malaysian"
                    ? {}
                    : { disabled: true })}
                  ref={(input) =>
                    setCursorPositionIfConditionMet(
                      "malaysian",
                      preformDataLocal,
                      input,
                      "address",
                      category
                    )
                  }
                />
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("address") &&
                      preformDataLocal.nationality === "malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  Address is required
                </div>
              </div>
              <div className="input--group select--box">
                <select
                  className="form-select"
                  value={
                    preformDataLocal.nationality === "malaysian"
                      ? preformDataLocal[category]?.state
                      : ""
                  }
                  onChange={(e) => handleChange("state", e)}
                  {...(preformDataLocal.nationality === "malaysian"
                    ? {}
                    : { disabled: true })}
                  id={`state-${category}`}
                >
                  <option value="">Select State</option>
                  {postcodeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("state") &&
                      preformDataLocal.nationality === "malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  Please select a State
                </div>
              </div>
              <div className="input--group">
                <input
                  value={
                    preformDataLocal.nationality === "malaysian"
                      ? preformDataLocal[category]?.postcode || ""
                      : ""
                  }
                  className="form-control"
                  onChange={(e) => handleChange("postcode", e)}
                  type="text"
                  name="m-postcode"
                  id="m-postcode"
                  placeholder="Postcode"
                  {...(preformDataLocal.nationality === "malaysian"
                    ? {}
                    : { disabled: true })}
                  ref={(input) =>
                    setCursorPositionIfConditionMet(
                      "malaysian",
                      preformDataLocal,
                      input,
                      "postcode",
                      category
                    )
                  }
                />
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("postcode") &&
                      preformDataLocal.nationality === "malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  Postcode is required
                </div>
              </div>
              <div className="input--group user-alt-location">
                <input
                  checked={
                    preformDataLocal.nationality === "malaysian" &&
                    preformDataLocal[category]?.riskLocationSameaslocation ===
                      "1"
                  }
                  type="checkbox"
                  className="mt-0 form-check"
                  name="m-location"
                  onChange={(e) => handleCheckBox(e)}
                  id={`m-locationCheck-${category}`}
                  {...(preformDataLocal.nationality === "malaysian"
                    ? { disabled: false }
                    : { disabled: true })}
                />
                <label htmlFor={`m-locationCheck-${category}`}>
                  Click here if your Location of Risk is the same as
                  correspondence address.
                </label>
              </div>

              <div className="input--group">
                <input
                  className="form-control"
                  value={
                    preformDataLocal.nationality === "malaysian"
                      ? preformDataLocal[category]?.risklocation || ""
                      : ""
                  }
                  onChange={(e) => handleChange("risklocation", e)}
                  type="text"
                  name="m-location-field"
                  id="m-location-field"
                  placeholder="Location of Risk (to state accurately for Home Care Insurance claim)"
                  {...(preformDataLocal.nationality === "malaysian"
                    ? {}
                    : { disabled: true })}
                  ref={(input) =>
                    setCursorPositionIfConditionMet(
                      "malaysian",
                      preformDataLocal,
                      input,
                      !preformDataLocal[category]?.risklocation
                        ? "risklocation"
                        : "address",
                      category
                    )
                  }
                />
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("risklocation") &&
                      preformDataLocal.nationality === "malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  Location of Risk is required
                </div>
              </div>
              <div className="input--group nric-upload upload--box mt_25">
                <input
                  className="form-control"
                  onChange={(e) => handleFileChange("nricfile", e.target.files)}
                  type="file"
                  name="nric-upload"
                  id={`nric-upload-${category}`}
                  {...(preformDataLocal.nationality === "malaysian"
                    ? {}
                    : { disabled: true })}
                />
                   
                <label htmlFor={`nric-upload-${category}`}>
                  <img src={getImageURL()} alt="" />
                  <p>Upload NRIC [front and back]</p>
                  <span>
                    Format PDF, JPEG or PNG and file size must not exceed 500KB
                  </span>
                </label>
                {preformDataLocal[category]?.nricfile &&
                  preformDataLocal.nationality === "malaysian" && (
                    <span className="file-selected">
                      Selected file: {preformDataLocal[category]?.nricfile.name}
                    </span>
                  )}
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("nricfile") &&
                      preformDataLocal.nationality === "malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  {customErrors[category] && customErrors[category]["nricfile"]
                    ? customErrors[category]["nricfile"]
                    : " Please choose a NRIC file."}
                </div>
              </div>
              <div className="input--group phone-bill-upload upload--box mt_25" style={{display: preformDataLocal[category]?.phone_file_required ? 'block' : 'none'}}>
                <input
                  className="form-control"
                  onChange={(e) =>
                    handleFileChange("phonefile", e.target.files)
                  }
                  type="file"
                  name="phone-bill-upload"
                  id={`phone-bill-upload-${category}`}
                  {...(preformDataLocal.nationality === "malaysian"
                    ? {}
                    : { disabled: true })}
                />

                <label htmlFor={`phone-bill-upload-${category}`}>
                  <img src={getImageURL()} alt="" />
                  <p>Upload latest phone statement (first page only)</p>
                  <span>
                    Format PDF, JPEG or PNG and file size must not exceed 500KB
                  </span>
                </label>
                {preformDataLocal[category]?.phonefile &&
                  preformDataLocal.nationality === "malaysian" && (
                    <span className="file-selected">
                      Selected file:{" "}
                      {preformDataLocal[category]?.phonefile.name}
                    </span>
                  )}
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("phonefile") &&
                      preformDataLocal.nationality === "malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  {customErrors[category] && customErrors[category]["phonefile"]
                    ? customErrors[category]["phonefile"]
                    : " Please choose a phone statement file."}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form--non-malaysian personal--info--box">
              <h4>Non-Malaysian</h4>
              <div className="input--group">
                <input
                  className="form-control"
                  value={
                    preformDataLocal.nationality === "non-malaysian"
                      ? preformDataLocal[category]?.name || ""
                      : ""
                  }
                  onChange={(e) => handleChange("name", e)}
                  type="text"
                  name="nm-full-name"
                  id="nm-full-name"
                  placeholder="Fullname as per Passport"
                  {...(preformDataLocal.nationality === "non-malaysian"
                    ? {}
                    : { disabled: true })}
                  ref={(input) =>
                    setCursorPositionIfConditionMet(
                      "non-malaysian",
                      preformDataLocal,
                      input,
                      "name",
                      category
                    )
                  }
                />
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("name") &&
                      preformDataLocal.nationality === "non-malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  Full Name is required
                </div>
              </div>
              <div className="input--group">
                <input
                  className="form-control"
                  value={
                    preformDataLocal.nationality === "non-malaysian"
                      ? preformDataLocal[category]?.nric || ""
                      : ""
                  }
                  onChange={(e) => handleChange("nric", e)}
                  type="text"
                  name="nm-pass-number"
                  id="nm-pass-number"
                  placeholder="Passport Number"
                  {...(preformDataLocal.nationality === "non-malaysian"
                    ? {}
                    : { disabled: true })}
                  ref={(input) =>
                    setCursorPositionIfConditionMet(
                      "non-malaysian",
                      preformDataLocal,
                      input,
                      "nric",
                      category
                    )
                  }
                />
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("nric") &&
                      preformDataLocal.nationality === "non-malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  Passport is required
                </div>
              </div>
              <div className="input--group">
                <input
                  className="form-control"
                  value={
                    preformDataLocal.nationality === "non-malaysian"
                      ? preformDataLocal[category]?.mobile || ""
                      : ""
                  }
                  onChange={(e) => handleChange("mobile", e)}
                  type="text"
                  name="nm-mobile-number"
                  id="nm-mobile-number"
                  placeholder="Mobile Number [with prefix +601]"
                  {...(preformDataLocal.nationality === "non-malaysian"
                    ? {}
                    : { disabled: true })}
                  ref={(input) =>
                    setCursorPositionIfConditionMet(
                      "non-malaysian",
                      preformDataLocal,
                      input,
                      "mobile",
                      category
                    )
                  }
                />
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("mobile") &&
                      preformDataLocal.nationality === "non-malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  Mobile Number is required
                </div>
              </div>
              <div className="input--group">
                <input
                  className="form-control"
                  value={
                    preformDataLocal.nationality === "non-malaysian"
                      ? preformDataLocal[category]?.email || ""
                      : ""
                  }
                  onChange={(e) => handleChange("email", e)}
                  type="text"
                  name="nm-email"
                  id="nm-email"
                  placeholder="Email"
                  {...(preformDataLocal.nationality === "non-malaysian"
                    ? {}
                    : { disabled: true })}
                  ref={(input) =>
                    setCursorPositionIfConditionMet(
                      "non-malaysian",
                      preformDataLocal,
                      input,
                      "email",
                      category
                    )
                  }
                />
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("email") &&
                      preformDataLocal.nationality === "non-malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  Email is required
                </div>
              </div>
              <div className="input--group">
                <input
                  className="form-control"
                  value={
                    preformDataLocal.nationality === "non-malaysian"
                      ? preformDataLocal[category]?.address || ""
                      : ""
                  }
                  onChange={(e) => handleChange("address", e)}
                  type="text"
                  name="nm-address"
                  id="nm-address"
                  placeholder="Correspondence Address"
                  {...(preformDataLocal.nationality === "non-malaysian"
                    ? {}
                    : { disabled: true })}
                  ref={(input) =>
                    setCursorPositionIfConditionMet(
                      "non-malaysian",
                      preformDataLocal,
                      input,
                      "address",
                      category
                    )
                  }
                />
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("address") &&
                      preformDataLocal.nationality === "non-malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  Address is required
                </div>
              </div>
              <div className="input--group select--box">
                <select
                  className="form-select"
                  value={
                    preformDataLocal.nationality === "non-malaysian"
                      ? preformDataLocal[category]?.state
                      : ""
                  }
                  onChange={(e) => handleChange("state", e)}
                  {...(preformDataLocal.nationality === "non-malaysian"
                    ? {}
                    : { disabled: true })}
                  id={`state-${category}`}
                >
                  <option value="">Select State</option>
                  {postcodeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("state") &&
                      preformDataLocal.nationality === "non-malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  Please select a State
                </div>
              </div>
              <div className="input--group">
                <input
                  value={
                    preformDataLocal.nationality === "non-malaysian"
                      ? preformDataLocal[category]?.postcode || ""
                      : ""
                  }
                  className="form-control"
                  onChange={(e) => handleChange("postcode", e)}
                  type="text"
                  name="nm-postcode"
                  id="nm-postcode"
                  placeholder="Postcode"
                  {...(preformDataLocal.nationality === "non-malaysian"
                    ? {}
                    : { disabled: true })}
                  ref={(input) =>
                    setCursorPositionIfConditionMet(
                      "non-malaysian",
                      preformDataLocal,
                      input,
                      "postcode",
                      category
                    )
                  }
                />
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("postcode") &&
                      preformDataLocal.nationality === "non-malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  Postcode is required
                </div>
              </div>

              <div className="input--group user-alt-location">
                <input
                  type="checkbox"
                  className="mt-0 form-check"
                  name="m-location"
                  checked={
                    preformDataLocal.nationality === "non-malaysian" &&
                    preformDataLocal[category]?.riskLocationSameaslocation ===
                      "1"
                  }
                  onChange={(e) => handleCheckBox(e)}
                  id={`m-locationCheck2-${category}`}
                  {...(preformDataLocal.nationality === "non-malaysian"
                    ? { disabled: false }
                    : { disabled: true })}
                />
                <label htmlFor={`m-locationCheck2-${category}`}>
                  Click here if your Location of Risk is the same as
                  correspondence address.
                </label>
              </div>

              <div className="input--group">
                <input
                  className="form-control"
                  value={
                    preformDataLocal.nationality === "non-malaysian"
                      ? preformDataLocal[category]?.risklocation || ""
                      : ""
                  }
                  onChange={(e) => handleChange("risklocation", e)}
                  type="text"
                  name="m-location-field"
                  id="m-location-field"
                  placeholder="Location of Risk (to state accurately for Home Care Insurance claim)"
                  {...(preformDataLocal.nationality === "non-malaysian"
                    ? {}
                    : { disabled: true })}
                  ref={(input) =>
                    setCursorPositionIfConditionMet(
                      "non-malaysian",
                      preformDataLocal,
                      input,
                      !preformDataLocal[category]?.risklocation
                        ? "risklocation"
                        : "address",
                      category
                    )
                  }
                />
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("risklocation") &&
                      preformDataLocal.nationality === "non-malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  Location of Risk is required
                </div>
              </div>
              <div className="input--group nationality select--box">
                <select
                  value={
                    preformDataLocal.nationality === "non-malaysian"
                      ? preformDataLocal[category]?.country
                      : ""
                  }
                  onChange={(e) => handleChange("country", e)}
                  className="form-select"
                  id="nationality"
                  name="nationality"
                  {...(preformDataLocal.nationality === "non-malaysian"
                    ? {}
                    : { disabled: true })}
                >
                  {countryOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("country") &&
                      preformDataLocal.nationality === "non-malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  Please select a Nationality
                </div>
              </div>
              <div className="input--group">
                <div className="customDatePickerWidth">
                <DatePicker
                  selected={
                    preformDataLocal.nationality === "non-malaysian"
                      ? preformDataLocal[category]?.dob || ""
                      : ""
                  }
                  onChange={(date) => {
                    handleDateChange("dob", date); // assuming your handleChange function accepts a date object
                  }}
                  className="form-control date-picker"
                  placeholderText="Date of Birth [dd/mm/yyyy]"
                  disabled={preformDataLocal.nationality !== "non-malaysian"}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown // Enable the year dropdown
                  showMonthDropdown // Enable the month dropdown
                  yearDropdownItemNumber={80} // Display 15 years in the year dropdown
                  scrollableYearDropdown // Enable scrolling in the year dropdown
                  scrollableMonthYearDropdown // Enable scrolling in the month and year dropdown
                />

                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      (customErrors[category] &&
                        customErrors[category]["dob"]) ||
                      (errors[category].includes("dob") &&
                        preformDataLocal.nationality === "non-malaysian")
                        ? "block"
                        : "none",
                  }}
                >
                  {customErrors[category] && customErrors[category]["dob"]
                    ? customErrors[category]["dob"]
                    : " Date of birth is required"}
                </div>
                </div>
               
              </div>
              <div className="input--group gender mt_25">
                <h4>Gender</h4>
                <div className="gender--option radio--box--default">
                  <div className="input--group">
                    <input
                      type="radio"
                      id={`male-${category}`}
                      value={"male"}
                      checked={
                        preformDataLocal.nationality === "non-malaysian" &&
                        preformDataLocal[category]?.gender === "male"
                      }
                      onChange={(e) => handleChange("gender", e)}
                      name={`gander--group-${category}`}
                      {...(preformDataLocal.nationality === "non-malaysian"
                        ? {}
                        : { disabled: true })}
                    />
                    <label htmlFor={`male-${category}`}>
                      <p>Male</p>
                    </label>
                  </div>
                  <div className="input--group">
                    <input
                      type="radio"
                      id={`female-${category}`}
                      value={"female"}
                      name={`gander--group-${category}`}
                      checked={
                        preformDataLocal.nationality === "non-malaysian" &&
                        preformDataLocal[category]?.gender === "female"
                      }
                      onChange={(e) => handleChange("gender", e)}
                      {...(preformDataLocal.nationality === "non-malaysian"
                        ? {}
                        : { disabled: true })}
                    />
                    <label htmlFor={`female-${category}`}>
                      <p>Female</p>
                    </label>
                  </div>
                  <div
                    className="invalid-feedback"
                    style={{
                      display:
                        errors[category].includes("gender") &&
                        preformDataLocal.nationality === "non-malaysian"
                          ? "block"
                          : "none",
                    }}
                  >
                    Gender is required
                  </div>
                </div>
              </div>
              <div className="input--group phone-bill-upload upload--box mt_25">
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => handleFileChange("nricfile", e.target.files)}
                  name="non-passport--upload"
                  id={`non-passport-upload-${category}`}
                  {...(preformDataLocal.nationality === "non-malaysian"
                    ? {}
                    : { disabled: true })}
                />
                <label htmlFor={`non-passport-upload-${category}`}>
                  <img src={getImageURL()} alt="" />
                  <p>Upload Passport (page with personal details and photo)</p>
                  <span>
                    Format PDF, JPEG or PNG and file size must not exceed 500KB
                  </span>
                </label>
                {preformDataLocal[category]?.nricfile &&
                  preformDataLocal.nationality === "non-malaysian" && (
                    <span className="file-selected">
                      Selected file: {preformDataLocal[category]?.nricfile.name}
                    </span>
                  )}
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("nricfile") &&
                      preformDataLocal.nationality === "non-malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  {customErrors[category] && customErrors[category]["nricfile"]
                    ? customErrors[category]["nricfile"]
                    : " Please choose Passport file."}
                </div>
              </div>
              <div className="input--group phone-bill-upload upload--box mt_25" style={{display: preformDataLocal[category]?.phone_file_required ? 'block' : 'none'}}>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) =>
                    handleFileChange("phonefile", e.target.files)
                  }
                  name="phone-bill-upload"
                  id={`non-nirc-upload-${category}`}
                  {...(preformDataLocal.nationality === "non-malaysian"
                    ? {}
                    : { disabled: true })}
                />
                <label htmlFor={`non-nirc-upload-${category}`}>
                  <img src={getImageURL()} alt="" />
                  <p>Upload latest phone statement (first page only)</p>
                  <span>
                    Format PDF, JPEG or PNG and file size must not exceed 500KB
                  </span>
                </label>
                {preformDataLocal[category]?.phonefile &&
                  preformDataLocal.nationality === "non-malaysian" && (
                    <span className="file-selected">
                      Selected file:{" "}
                      {preformDataLocal[category]?.phonefile.name}
                    </span>
                  )}
                <div
                  className="invalid-feedback"
                  style={{
                    display:
                      errors[category].includes("phonefile") &&
                      preformDataLocal.nationality === "non-malaysian"
                        ? "block"
                        : "none",
                  }}
                >
                  {customErrors[category] && customErrors[category]["phonefile"]
                    ? customErrors[category]["phonefile"]
                    : " Please choose phone statement file."}
                </div>
              </div>
            </div>
          </div>
          {shouldRenderForm && (
            <AgreementBox
              category={category}
              errors={errors}
              onAgreementChange={handleAgreementChange}
              onTermsChange={handleAgreementChange}
              preformData={preformData}
              classTitle="aggrement--box mt_65"
            />
          )}
        </div>
      )}
      {/* --personal box heading */}
    </div>
  );
};

const PersonalDetailsForm = ({
  category,
  onFormUpdate,
  errors,
  preformData,
  customErrors,
  classValue = "",
  title = "",
  label = "",
}) => {
  const shouldRenderForm = title && label;
  return (
    <>
      {!shouldRenderForm && (
        <div className="personal--details--wrapper mt_65">
          <h3>Personal Details</h3>
          <GetForm
            category={category}
            onFormUpdate={onFormUpdate}
            errors={errors}
            classValue={classValue}
            title={title}
            label={label}
            preformData={preformData}
            customErrors={customErrors}
          />
        </div>
      )}
      {shouldRenderForm && (
        <>
          <GetForm
            category={category}
            onFormUpdate={onFormUpdate}
            errors={errors}
            classValue={classValue}
            title={title}
            label={label}
            preformData={preformData}
            customErrors={customErrors}
          />
        </>
      )}
    </>
  );
};

export default PersonalDetailsForm;
