import React from "react";


const PaymentConfirmation = ({ formData }) => {
  return (
    <>
      {/* <section
        className="inner--hero about--hero--area"
        style={{ backgroundImage: `url(assets/images/background_hero.png)` }}
      > */}

      <section
        className="inner--hero about--hero--area theme-background"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="inner--text">
                <h3 className="heading">Payment Confirmation</h3>
                <p>
                  Thank you for your payment. If you have any queries, kindly
                  contact our customer service at +603 2779 2419 or
                  enquiry@mysignal.com.my We look forward to serving you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
     
    </>
  );
};

export default PaymentConfirmation;
