import React, { useState } from "react";
import { AssetsPDFiles } from "../utils/utils";
const Footer = ({ hasPartner = false }) => {
  const [year] = useState(new Date().getFullYear());
  const facebook = "https://www.facebook.com/mysignalsdn/";
  const instagram = "https://www.instagram.com/mysignalsdn/";
  const twitter = "https://www.twitter.com/mysignalsdn";
  const tiktok = "https://www.tiktok.com/@mysignalsdn";
  const linkedin = "https://www.linkedin.com/company/mysignalsdn/";
  const youtube = "https://www.youtube.com/@MySIGNALTelcoInsurance";

  return (
    /*==== Footer :: Start ==== */
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-sm-9">
            <div className="copyright">
              <p>© {year} MySignal Marketing Sdn Bhd. All rights reserved.</p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-3">
            <div className="footer--links">
              <ul className="footer--menu">
                <li>
                  <a href={AssetsPDFiles("SIGNAL_privacy_notice.pdf", hasPartner)}>
                    Privacy Notice
                  </a>
                </li>
                <li>
                  <a href={AssetsPDFiles("SIGNAL_condition_of_use.pdf", hasPartner)}>
                    Conditions of Use
                  </a>
                </li>
              </ul>
              <div className="social--icon">
                <a target="blank" href={facebook}>
                  <i className="bi bi-facebook"></i>
                </a>
                <a target="blank" href={instagram}>
                  <i className="bi bi-instagram"></i>
                </a>
                <a target="blank" href={twitter}>
                  <i className="bi bi-twitter-x"></i>
                </a>
                <a target="blank" href={youtube}>
                  <i className="bi bi-youtube"></i>
                </a>
                <a target="blank" href={tiktok}>
                  <i className="bi bi-tiktok"></i>
                </a>
                <a target="blank" href={linkedin}>
                  <i className="bi bi-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    /* ==== Footer :: End ==== */
  );
};

export default Footer;
