import React ,  { useEffect } from "react";
import Footer from "../components/footer";
import ConfirmationSection from "../components/sections/confirmation";
import { useNavigate,useLocation } from 'react-router-dom';


const ConfirmationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const formDataFromPreviousPage = location.state?.formData;
  // console.log("formDataFromPreviousPage",formDataFromPreviousPage)
  useEffect(() => {
    if (!formDataFromPreviousPage) {
      // Redirect to home page if formDataFromPreviousPage is not available
      navigate('/');
    }
  }, [formDataFromPreviousPage, navigate]);
  return (
    <>
      
      <main>
        <ConfirmationSection formData={formDataFromPreviousPage} />
      </main>
      <Footer />
    </>
  );
};

export default ConfirmationPage;
