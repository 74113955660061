import React, { useState } from 'react';

const EligibilityBox = ({ labelText,warningText, onSelectionChange,onAgeChange,preFormData }) => {
    const [isAdultdefaultValue, setIsAdultdefaultValue] = useState(true);
    const [myStyle, setToggleStyle] = useState("none");

    const handleAdultSelectionChange = (event) => {
       
        if(event.target.value !=="adult-yes"){
            setToggleStyle("block")
        } else {
            setToggleStyle("none")
        }
        setIsAdultdefaultValue(event.target.value === "adult-yes");
        onAgeChange(event.target.value === "adult-yes");
    };

    const handleNationalityChange = (event) => {
        onSelectionChange(event.target.value);
    };

    return (
        <div className="eligibility--box--wrapper mt_65">
            <h3>Eligibility</h3>
            <div className="eligibility--box radio--box--default card--default">
                <div className="adult--select--box">
                    <h4>{labelText}</h4>
                    <div className="input--group">
                        <input type="radio" id="adult-yes" name="adult-check-group" value="adult-yes" checked={isAdultdefaultValue} onChange={handleAdultSelectionChange} />
                        <label htmlFor="adult-yes">Yes</label>
                    </div>
                    <div className="input--group">
                        <input type="radio" id="adult-no" name="adult-check-group" value="adult-no" checked={!isAdultdefaultValue} onChange={handleAdultSelectionChange} />
                        <label htmlFor="adult-no">No</label>
                    </div>
                    <p style={{ display: myStyle }} className="eligibility--hidden--text">{warningText}</p>
                </div>
                <div className="nationality--select--box">
                    <h4>Select your nationality status</h4>
                    <div className="input--group">
                        <input type="radio" id="malaysian" name="nationality--group" value="malaysian" checked={preFormData.nationality === "malaysian"} onChange={handleNationalityChange} />
                        <label htmlFor="malaysian">Malaysian</label>
                    </div>
                    <div className="input--group">
                        <input type="radio" id="non-malaysian" name="nationality--group" value="non-malaysian" checked={preFormData.nationality === "non-malaysian"} onChange={handleNationalityChange} />
                        <label htmlFor="non-malaysian">Non-Malaysian (legally residing, studying or working in Malaysia)</label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EligibilityBox;
