import React from 'react';
import {  PDFViewer,Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';

// Styles for the PDF
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    padding: 20,
    fontSize: 12,
  },
  header: {
    fontSize: 20,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    paddingBottom: 5,
    textAlign: 'center', // Center align the header
    fontWeight: 'bold', // Make the header bold
  },
  card: {
    marginTop:10,
    marginBottom: 20,
    borderWidth: 1,
    borderColor: '#000',
  },
  cardBody: {
    padding: 10,
  },
  table: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    marginBottom: 10,
    width: '100%',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
  },
  tableHeader: {
    backgroundColor: '#f0f0f0',
    padding: 5,
    fontWeight: 'bold',
  },
  tableCell: {
    padding: 5,
    textAlign: 'center', // Center align all table cells
  },
  totalRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  logo: {
    width: 80,
    height: 80,
   
  },
  headerValuePair: {
    flexDirection: 'row',
    justifyContent: 'right', // Align items to the start
    marginBottom: 5,
    alignItems: 'center', // Align items vertically in the center
  },
  headerText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#65523c', // Red color
    textAlign: 'right', // Align header text to the right
    width: '50%', // Set width for header text
  },
  valueText: {
    fontWeight: 'normal',
    textAlign: 'right', // Align value text to the right
    width: '50%', // Set width for value text
  },
  invoiceDetails: {
    flex: 1, // Take remaining space
    textAlign: 'right', // Align content to the right
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    marginLeft: 'auto', // Align to the right
  },
  headerRow: {
    backgroundColor: '#a48b6dd0', // Set background color for table header row
  },
});

const invoiceData = {
  logo: "assets/images/logo.png",
  title:"Payment Details",
  from: {
    name: 'Webz Poland',
    address: 'Madalinskiego 8, 71-101 Szczecin, Poland',
    email: 'info@webz.com.pl',
    phone: '+48 444 666 3333',
    text1:'Feb 2024 to March 2024',
    date:'2024-01-01'
  },
  to: {
    name: 'Bob Mart',
    address: 'Attn: Daniel Marek, 43-190 Mikolow, Poland',
    email: 'marek@daniel.com',
    phone: '+48 123 456 789',
    text1:'Feb 2024 to March 2024',
    date:'2024-01-01'
  },
  items: [
    { id: 1, name: 'Origin License', description: 'Extended License', unitCost: 999.00, quantity: 1 },
  ],
  subtotal: 8497.00,
  discount: 1699.40,
  vat: 679.76,
  total: 7477.36,
};

// Invoice component
// Invoice component
const PDFDETAILS = ({invoiceData}) => (
  <Document>
    <Page size={{ width: 1200, height: 800 }} style={styles.page}>
      {/* Heading */}
      <View style={styles.header}>
        <Text>{invoiceData.title}</Text>
      </View>
      
      {/* Invoice Details */}
      <View style={{ flexDirection: 'row' }}>
      
        <View style={styles.invoiceDetails}>
        <View style={styles.logo}>
          <Image src={invoiceData.logo} style={styles.logo} />
        </View>
        <View style={styles.headerValuePair}>
    <Text style={styles.headerText}>Customer Name:</Text>
    <Text style={styles.valueText}>{invoiceData.from.name}</Text>
  </View>
  <View style={styles.headerValuePair}>
    <Text style={styles.headerText}>Address:</Text>
    <Text style={styles.valueText}>{invoiceData.from.address}</Text>
  </View>
  <View style={styles.headerValuePair}>
    <Text style={styles.headerText}>Email:</Text>
    <Text style={styles.valueText}>{invoiceData.from.email}</Text>
  </View>
  <View style={styles.headerValuePair}>
    <Text style={styles.headerText}>Phone:</Text>
    <Text style={styles.valueText}>{invoiceData.from.phone}</Text>
  </View>
  <View style={styles.headerValuePair}>
    <Text style={styles.headerText}>Plan Period:</Text>
    <Text style={styles.valueText}>{invoiceData.from.text1}</Text>
  </View>
  <View style={styles.headerValuePair}>
    <Text style={styles.headerText}>Payment Date:</Text>
    <Text style={styles.valueText}>{invoiceData.from.date}</Text>
  </View>
        </View>
      </View>
      
      {/* Invoice Table */}
      <View style={styles.card}>
        <View style={styles.cardBody}>
          <View style={styles.table}>
            <View style={[styles.tableRow,styles.headerRow,]}>
              <Text style={[styles.tableCell, { width: '10%' }]}>No.</Text>
              <Text style={[styles.tableCell, { width: '60%' }]}>Item</Text>
              <Text style={[styles.tableCell, { width: '30%' }]}>Price</Text>
            </View>
            {invoiceData.items.map(item => (
              <View key={item.id} style={styles.tableRow}>
                <Text style={[styles.tableCell, { width: '10%' }]}>{item.id}</Text>
                <Text style={[styles.tableCell, { width: '60%' }]}>{item.name}</Text>
                <Text style={[styles.tableCell, { width: '30%' }]}>${item.unitCost.toFixed(2)}</Text>
              </View>
            ))}
            <View style={[styles.tableRow,]}>
              <Text style={[styles.tableCell, { width: '10%' }]}></Text>
              <Text style={[styles.tableCell, { width: '60%', textAlign: 'right' }]}>Subtotal</Text>
              <Text style={[styles.tableCell, { width: '30%' }]}>${invoiceData.subtotal.toFixed(2)}</Text>
            </View>
            <View style={[styles.tableRow,]}>
              <Text style={[styles.tableCell, { width: '10%' }]}></Text>
              <Text style={[styles.tableCell, { width: '60%', textAlign: 'right' }]}>Additional Charges</Text>
              <Text style={[styles.tableCell, { width: '30%' }]}>${(invoiceData.total - invoiceData.subtotal).toFixed(2)}</Text>
            </View>
            <View style={[styles.tableRow,]}>
              <Text style={[styles.tableCell, { width: '10%' }]}></Text>
              <Text style={[styles.tableCell, { width: '60%', textAlign: 'right' }]}>Total</Text>
              <Text style={[styles.tableCell, { width: '30%' }]}>${invoiceData.total.toFixed(2)}</Text>
            </View>
          </View>
        </View>
      </View>
      
      {/* Footer */}
      <View style={styles.footer}>
        <Text>This document is computer-generated and does not require a signature.</Text>
      </View>
    </Page>
  </Document>
);



const PDFDETAILSs = () => (
<PDFViewer style={{ width: '100%', height: '100vh' }}>

    <PDFDETAILS invoiceData={invoiceData} />
  </PDFViewer>
);



export default PDFDETAILSs;
