import React from "react";

const HeroSection = () => {
  return (
    /*==== Hero Area :: Start ==== */

    // <section
    //   className="inner--hero about--hero--area"
    //   style={{ backgroundImage: `url(assets/images/background_hero.png)` }}
    // >
       <section
      className="inner--hero about--hero--area theme-background"
    >
      
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="inner--text">
              <h4 className="tag-box">Discover Our Journey</h4>
              <h3 className="heading">Unveiling the Heartbeat of MYSIGNAL</h3>
              <p>
                Crafting Connections, Shaping Futures - Explore the Story Behind
                MYSIGNAL's Visionary Odyssey in Telco-Infused Insurance and
                Takaful.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    /*==== Hero Area :: End ==== */
  );
};

export default HeroSection;
