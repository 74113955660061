import React  from 'react';

const SignalPremierPlans = ({value,onPlanChange}) => {
    
    const handlePlanChange = (event) => {
            
        
        onPlanChange(event.target.value,92.00);
    };

    return (
        <div className="select--plan">
        <h4>Select Plan</h4>
        <div className="plan--option">
            <div className="input--group">
                <input type="radio" id="single-life" value="signalpremier" name="signalpremier" checked onChange={handlePlanChange} />
                <label htmlFor="single-life">
                <p>SIGNAL Premier</p>
				 <span>RM92.00/Month</span>
                </label>
            </div>
        </div>
    </div>
    );
}

export default SignalPremierPlans;
