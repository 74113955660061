import React, { useEffect } from "react";

function GoogleTagManager() {
  useEffect(() => {
    // Insert GTM script into the head of the document
    const script = document.createElement("script");
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-WTWZWDBT');
    `;
    document.head.appendChild(script);

    // Insert noscript tag immediately after the opening body tag
    const noscript = document.createElement("noscript");
    noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WTWZWDBT"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.prepend(noscript);

    // Cleanup function to remove the script and noscript tags when the component unmounts
    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, []);

  return null; // Google Tag Manager doesn't render anything to the DOM
}

export default GoogleTagManager;
