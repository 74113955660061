import React from "react";

const FAQSection = () => {
  return (
    /*==== Faq Area :: Start ==== */
    <section className="faq--area" id="faq">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mt_30 order--md-2">
            {/*FAQ Tab :: Start */}
            <div className="faq--tab">
              <div className="faq--nav--wrapper">
                {/*FAQ Nav :: Start */}
                <div className="faq--nav">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-general"
                        type="button"
                        role="tab"
                        aria-controls="pills-general"
                        aria-selected="true"
                      >
                        GENERAL
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-telco"
                        type="button"
                        role="tab"
                        aria-controls="pills-telco"
                        aria-selected="false"
                      >
                        TELCO SERVICES
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-protection"
                        type="button"
                        role="tab"
                        aria-controls="pills-protection"
                        aria-selected="false"
                      >
                        INSURANCE
                        <br />
                        PROTECTION
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-claims"
                        type="button"
                        role="tab"
                        aria-controls="pills-claims"
                        aria-selected="false"
                      >
                        CLAIMS
                      </button>
                    </li>
                  </ul>
                </div>
                {/*FAQ Nav :: End */}
              </div>
              <div className="tab-content" id="pills-tabContent">
                {/*FAQ General Tab: Start */}
                <div
                  className="tab-pane fade show active"
                  id="pills-general"
                  role="tabpanel"
                  aria-labelledby="pills-general-tab"
                  tabIndex="0"
                >
                  <div className="faq--box">
                    <div className="accordion" id="general--accordion--example">
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <p>What is SIGNAL?</p>
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#general--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              SIGNAL is a first of its kind telco-based
                              insurance and takaful plan, providing valuable
                              protection benefits encompassing Lifestyle,
                              Medical, Personal Accident, Life, Home etc, paired
                              with a SIM card with exceptional telco services.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}

                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <p>What is unique about SIGNAL?</p>
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              SIGNAL offers crucial insurance and takaful
                              benefits, paired with a SIM card and offered at
                              the cost of a standard telco subscription fee i.e.
                              at no additional cost.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}

                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <p>
                              Do customers have to pay extra for the insurance
                              and takaful benefits offered by SIGNAL?
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              All SIGNAL plans come with both telco services
                              and insurance/takaful benefits. Customers will
                              just have to pay the monthly subscription for the
                              SIGNAL plan subscribed, to access the telco
                              services and benefit from the insurance and
                              takaful protection.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}

                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <p>
                              Who is the Telecommunications Provider providing
                              the telco services for SIGNAL?
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              The telco services are provided by Celcomdigi Bhd
                              (hereinafter referred to “Partner”).
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}

                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <p>
                              Who is the provider of insurance protection under
                              SIGNAL?
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              The insurance is underwritten by several leading
                              insurance providers and takaful operators in
                              Malaysia licensed under the Financial Services Act
                              2013 and regulated by Bank Negara Malaysia. You
                              may refer to the respective SIGNAL plans for more
                              information.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}

                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                          >
                            <p>How do I subscribe to SIGNAL? </p>
                          </button>
                        </h2>
                        <div
                          id="collapseSix"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general--accordion--example"
                        >
                          <div className="accordion-body">
                            <div>
                              Just follow these steps:
                              <ul className="mt_10">
                                <li className="mt_5">1. Select plan</li>
                                <li className="mt_5">
                                  2. Select telco service provider{" "}
                                </li>
                                <li className="mt_5">
                                  3. Select mobile line category (new line,
                                  porting or switch plan)
                                </li>
                                <li className="mt_5">
                                  4. Register by keying in your personal details
                                </li>
                                <li className="mt_5">5. Make payment</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}

                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSeven"
                            aria-expanded="false"
                            aria-controls="collapseSeven"
                          >
                            <p>Who is eligible to subscribe SIGNAL? </p>
                          </button>
                        </h2>
                        <div
                          id="collapseSeven"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general--accordion--example"
                        >
                          <div className="accordion-body">
                            <div>
                              The following criteria must be met to subscribe
                              SIGNAL:
                              <ul className="mt_10">
                                <li className="mt_5">
                                  • You must be a Malaysian or non-Malaysian
                                  legally residing in Malaysia with Permanent
                                  Residence, Work Permit, Student Permit or MM2H
                                  status.
                                </li>
                                <li className="mt_5">
                                  • You must fulfil the age criteria spelt out
                                  in the insurance/takaful policy of the plan
                                  you selected.
                                </li>
                                <li className="mt_5">
                                  You are not eligible to subscribe to SIGNAL if
                                  you do not meet any of the criteria mentioned
                                  above.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                    </div>
                  </div>
                </div>
                {/*FAQ General Tab: End */}

                {/*FAQ Telco Tab: Start */}
                <div
                  className="tab-pane fade"
                  id="pills-telco"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabIndex="0"
                >
                  <div className="faq--box">
                    <div className="accordion" id="telco--accordion--example">
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <p>Can I maintain my existing mobile number?</p>
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#telco--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              Yes, you can maintain your existing mobile number
                              irrespective of your existing telco provider.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <p>
                              What if I am on a contract with my existing telco
                              provider?
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#telco--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              You will need to terminate your existing contract
                              and settle all outstanding amounts before
                              subscribing to SIGNAL and retaining your existing
                              number.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <p>
                              How else can I subscribe if I don’t wish to cancel
                              my existing contract?{" "}
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#telco--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              You can subscribe for SIGNAL and obtain a new
                              mobile number and SIM card.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <p>What if I am blacklisted?</p>
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          data-bs-parent="#telco--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              You will not be able to subscribe to SIGNAL if you
                              are blacklisted.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <p> Is the line transferrable? </p>
                          </button>
                        </h2>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          data-bs-parent="#telco--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>No, the line is not transferrable. </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                          >
                            <p>
                              Can I have a supplementary line for my family
                              members?{" "}
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapseSix"
                          className="accordion-collapse collapse"
                          data-bs-parent="#telco--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              No, we do not have the option for supplementary
                              lines.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSeven"
                            aria-expanded="false"
                            aria-controls="collapseSeven"
                          >
                            <p>
                              Can I subscribe for additional internet data (and
                              other such services)?
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapseSeven"
                          className="accordion-collapse collapse"
                          data-bs-parent="#telco--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              Yes, you can but subject to additional cost and
                              T&C apply. Kindly contact our Customer Service for
                              more information.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEight"
                            aria-expanded="false"
                            aria-controls="collapseEight"
                          >
                            <p>
                              What happens if my line is activated by a third
                              party?{" "}
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapseEight"
                          className="accordion-collapse collapse"
                          data-bs-parent="#telco--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              MySignal Marketing Sdn Bhd shall not be
                              responsible in the event the Customer’s
                              subscription was activated by a third party
                              without his/her consent. MySignal Marketing Sdn
                              Bhd shall not refund nor compensate the Customer
                              in any manner whatsoever in such situations.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseNine"
                            aria-expanded="false"
                            aria-controls="collapseNine"
                          >
                            <p> How and where do I pay my monthly charges? </p>
                          </button>
                        </h2>
                        <div
                          id="collapseNine"
                          className="accordion-collapse collapse"
                          data-bs-parent="#telco--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>Via online banking. </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTen"
                            aria-expanded="false"
                            aria-controls="collapseTen"
                          >
                            <p>
                              {" "}
                              Can I subscribe for SIGNAL at celcomdigi outlet
                              and likewise pay my bills etc at celcomdigi
                              outlets?{" "}
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapseTen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#telco--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              No, you can only subscribe for SIGNAL online at
                              www.mysignal.com.my and likewise pay your bills
                              via online banking.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEleven"
                            aria-expanded="false"
                            aria-controls="collapseEleven"
                          >
                            <p>
                              {" "}
                              What if I don’t pay my monthly invoice on time?
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapseEleven"
                          className="accordion-collapse collapse"
                          data-bs-parent="#telco--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              All invoices must be paid on the due dates, with
                              reminders being sent before the due date.
                              Non-settlement of invoices will result in the
                              phone line and insurance benefits being suspended.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwelve"
                            aria-expanded="false"
                            aria-controls="collapseTwelve"
                          >
                            <p>
                              {" "}
                              What are the unlimited calls allocated within
                              SIGNAL?
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapseTwelve"
                          className="accordion-collapse collapse"
                          data-bs-parent="#telco--accordion--example"
                        >
                          <div className="accordion-body">
                            <div>
                              <ul>
                                <li>
                                  • Applies to domestic mobile/fixed on-net and
                                  off-net usages (excluding video calls, calls
                                  to special numbers, calls to toll-free
                                  1-300/1-800 numbers, and calls to 121
                                  numbers).
                                </li>
                                <li>
                                  • Strictly for standard person-to-person
                                  calls; and not meant for any
                                  commercial/non-personal usages. For any
                                  excessive usage, or on suspicion of fraud, any
                                  illegal practice or unusual activity in
                                  respect of the Customer’s Account with
                                  MySignal Marketing Sdn Bhd, MySignal Marketing
                                  Sdn Bhd at its sole and absolute discretion
                                  reserves the right at any time without being
                                  liable to the Customer or any third party to
                                  discontinue, disconnect, interrupt, bar or
                                  suspend the service for such period of time as
                                  MySignal Marketing Sdn Bhd shall deem fit.
                                </li>
                                <li>
                                  • Non-transferrable, whether by operation of
                                  law or otherwise, either to any other person,
                                  entity, or any other Postpaid Account.{" "}
                                </li>
                                <li>
                                  • Can only be utilised domestically i.e.
                                  within Malaysian networks only. Calls made
                                  when overseas are not allowed.{" "}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThirteen"
                            aria-expanded="false"
                            aria-controls="collapseThirteen"
                          >
                            <p> Who is the owner of the mobile number?</p>
                          </button>
                        </h2>
                        <div
                          id="collapseThirteen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#telco--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              Ownership of the mobile number will be with
                              MySignal Marketing Sdn Bhd.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourteen"
                            aria-expanded="false"
                            aria-controls="collapseFourteen"
                          >
                            <p>
                              {" "}
                              If I were to retain my existing mobile number and
                              subscribe to SIGNAL, would I continue to be the
                              owner of the mobile number?
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapseFourteen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#telco--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              No, you will continue to use the same mobile
                              number but ownership will be with MySignal
                              Marketing Sdn Bhd.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFifteen"
                            aria-expanded="false"
                            aria-controls="collapseFifteen"
                          >
                            <p>
                              What if I were to terminate my SIGNAL subscription
                              and/or don’t wish to continue with the SIGNAL
                              subscription after the initial 12-month contract,
                              can I retain my mobile number?{" "}
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapseFifteen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#telco--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              Yes, ownership of the mobile number will be
                              transferred back to the user, once all outstanding
                              (if any) has been settled.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                    </div>
                  </div>
                </div>
                {/*FAQ Telco Tab: End */}
                {/*FAQ Protection Tab: Start */}
                <div
                  className="tab-pane fade"
                  id="pills-protection"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                  tabIndex="0"
                >
                  <div className="faq--box">
                    <div
                      className="accordion"
                      id="protection--accordion--example"
                    >
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-protectionOne"
                            aria-expanded="true"
                            aria-controls="collapse-protectionOne"
                          >
                            <p>
                              What type of benefits and insurance protections
                              are provided under SIGNAL?
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapse-protectionOne"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#protection--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              Our various plans cater to different segments of
                              the society and each plan has its own unique
                              proposition. You may refer to the respective plans
                              or PDS and policy wordings for more information.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}

                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-protectionTwo"
                            aria-expanded="false"
                            aria-controls="collapse-protectionTwo"
                          >
                            <p>
                              Can I cancel my insurance cover and get a refund
                              of my contribution?
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapse-protectionTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#protection--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              You may cancel your subscription for SIGNAL by
                              providing MySignal Marketing Sdn Bhd with a
                              written notice of the intended cancellation. The
                              cancellation will take effect for the entire
                              SIGNAL subscription including the telco plan,
                              insurance protections, and other benefits. There
                              shall be no refund of subscriptions including
                              contribution for the insurance/takaful protection,
                              by the insurance provider/takaful operator or
                              MySignal Marketing Sdn Bhd.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}

                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-protectionThree"
                            aria-expanded="false"
                            aria-controls="collapse-protectionThree"
                          >
                            <p>What is the period of insurance coverage?</p>
                          </button>
                        </h2>
                        <div
                          id="collapse-protectionThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#protection--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              The period of coverage for the insurance/takaful
                              protection is twelve (12) months and the
                              certificate will be renewed on a yearly basis upon
                              renewal of SIGNAL subscription.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}

                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-protectionFour"
                            aria-expanded="false"
                            aria-controls="collapse-protectionFour"
                          >
                            <p>
                              When is the effective date of the insurance
                              coverage, assuming I successfully subscribe to
                              SIGNAL today?
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapse-protectionFour"
                          className="accordion-collapse collapse"
                          data-bs-parent="#protection--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              The period of coverage for insurance/takaful
                              protection shall take effect seven (7) days from
                              successful subscription to SIGNAL. You will
                              receive a confirmation via email within four (4)
                              working days of registration if your subscription
                              is successful or otherwise. However, please take
                              note that the coverage is subject to a waiting
                              period as outlined in the policy wording.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}

                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-protectionFive"
                            aria-expanded="false"
                            aria-controls="collapse-protectionFive"
                          >
                            <p>
                              After I participated and made contributions, how
                              would I know that I have been covered by this
                              insurance?
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapse-protectionFive"
                          className="accordion-collapse collapse"
                          data-bs-parent="#protection--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              Once you have successfully registered for SIGNAL,
                              you will receive an email notification within four
                              (4) working days with your certificate number, the
                              effective and expiry date of the certificate, and
                              other relevant information.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}

                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-protectionSix"
                            aria-expanded="false"
                            aria-controls="collapse-protectionSix"
                          >
                            <p>
                              Is the coverage automatically extended every month
                              and what happens at the end of the 12-month
                              contract period?
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapse-protectionSix"
                          className="accordion-collapse collapse"
                          data-bs-parent="#protection--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              The certificate will be automatically extended
                              every month subject to the timely receipt of the
                              monthly subscription fee. You will receive a
                              notification via email informing you of the expiry
                              of the initial twelve-month (12-month) contract,
                              two months before the expiry date, and requesting
                              if you wish to continue for a further period of
                              twelve (12) months. There will not be any waiting
                              period for insurance/takaful protection upon
                              renewal.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                    </div>
                  </div>
                </div>
                {/*FAQ Protection Tab: End */}
                {/*FAQ Claims Tab: Start */}
                <div
                  className="tab-pane fade"
                  id="pills-claims"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                  tabIndex="0"
                >
                  <div className="faq--box">
                    <div className="accordion" id="clams--accordion--example">
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <p>What is the procedure for a claim?</p>
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#clams--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              Fill in the claim form and submit the completed
                              form together with supporting documents to{" "}
                              <a href="mailto:enquiry@mysignal.com.my">
                                enquiry@mysignal.com.my
                              </a>
                              . We will then forward this information to the
                              respective insurance provider/takaful operator.
                              <br />
                              The claim form and list of supporting documents
                              vary for each SIGNAL plan as these requirements
                              are determined by the respective insurance
                              provider/takaful operators.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}

                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <p>
                              How long will it take for my claim to be
                              processed?{" "}
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#clams--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              Approximately fourteen (14) working days from the
                              date of claims submission. However, the turnaround
                              time may vary subject to the insurance
                              provider/takaful operator's assessment of the
                              claims. In the event of insufficient/incomplete
                              information, the claims processing will take a
                              longer time to be completed.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}

                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <p>
                              What are the contact details for inquiries etc
                              relating to claims?
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#clams--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              You may contact our Customer Service at +603 2779
                              2419 or{" "}
                              <a href="mailto:enquiry@mysignal.com.my">
                                enquiry@mysignal.com.my
                              </a>{" "}
                              (Monday to Friday, 9 am to 6 pm).{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            <p>
                              What is Reimbursement Benefits for Outpatient
                              Clinical Coverage?
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          data-bs-parent="#clams--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              This benefit provides reimbursement of charges for
                              treatment or consultations by registered doctors
                              in Malaysia for common sicknesses or injuries not
                              requiring hospitalisation, up to specified limits
                              in the SIGNAL Plan subscribed.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            <p>How does this Reimbursement Benefits work?</p>
                          </button>
                        </h2>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          data-bs-parent="#clams--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              For Individual SIGNAL Plans, only one (1) claim is
                              permitted every three (3) months, while for Family
                              SIGNAL Plans, up to two (2) claims are allowed
                              within the same period. Each claim is limited to a
                              reimbursement of fifty percent (50%) of the total
                              bill
                              <span style={{ backgroundColor: "yellow" }}>
                                {" "}
                                subject to a maximum amount as offered in the
                                respective plans
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                          >
                            <p>
                              How do I know if I have exceeded my claim limit?
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapseSix"
                          className="accordion-collapse collapse"
                          data-bs-parent="#clams--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              Please contact SIGNAL Customer Service at
                              enquiry@mysignal.com.my
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSeven"
                            aria-expanded="false"
                            aria-controls="collapseSeven"
                          >
                            <p>
                              What is the estimated processing time for
                              reimbursement?
                            </p>
                          </button>
                        </h2>
                        <div
                          id="collapseSeven"
                          className="accordion-collapse collapse"
                          data-bs-parent="#clams--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              All claims shall be processed and payment credited
                              to the nominated bank account within seven (7)
                              days of receipt of the duly completed claim form
                              and relevant receipts.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEight"
                            aria-expanded="false"
                            aria-controls="collapseEight"
                          >
                            <p>Is Reimbursement Benefits transferrable?</p>
                          </button>
                        </h2>
                        <div
                          id="collapseEight"
                          className="accordion-collapse collapse"
                          data-bs-parent="#clams--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>No, it is not transferrable.</p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                      {/*Que : Start */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseNine"
                            aria-expanded="false"
                            aria-controls="collapseNine"
                          >
                            <p>What happens to unclaimed benefits?</p>
                          </button>
                        </h2>
                        <div
                          id="collapseNine"
                          className="accordion-collapse collapse"
                          data-bs-parent="#clams--accordion--example"
                        >
                          <div className="accordion-body">
                            <p>
                              Any unclaimed benefits within a three (3)-month
                              cycle{" "}
                              <span style={{ backgroundColor: "yellow" }}>
                                {" "}
                                is not allowed to{" "}
                              </span>{" "}
                              be carried forward to the next cycle and will be
                              forfeited.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Que : End */}
                    </div>
                  </div>
                </div>
                {/*FAQ Claims Tab: End */}
              </div>
            </div>
            {/*FAQ Tab :: End */}
          </div>
          <div className="col-lg-6 mt_30 order--md-1">
            <div className="faq--text--box">
              <h4 className="tag-box">FAQ</h4>
              <h3 className="sub--heading">Your Queries Answered</h3>
              <p>
                Find Clarifications on MYSIGNAL’s Insurance and Takaful Plans,
                Addressing Common Questions
              </p>
              <div className="img--area">
                <img className="w-100" src="assets/images/faq.webp" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    /*==== Faq Area :: End ==== */
  );
};

export default FAQSection;
