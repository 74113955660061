import React from "react";
import { AssetsFiles, AssetsURLWhenInfluencer } from "../../utils/utils";

const DocumentsDownload = ({ policy_doc, pds_doc }) => {
  const getImageURL = () => {
    let url = AssetsURLWhenInfluencer("download.svg");
    return url;
  };
  const getFile = (file) => {
    let url = AssetsFiles(file);
    return url;
  };
  return (
    /* documents download  */
    <div className="documents--upload--wrapper mt_55">
      <div className="row">
        {policy_doc && pds_doc && (
          <>
            <div className="col-lg-4 col-sm-6 mt_20">
              {policy_doc &&
                policy_doc.split(",").map((doc, index) => (
                  <div
                    key={index}
                    className={`upload--box ${index > 0 ? "mt-3" : ""}`}
                  >
                    <a href={doc ? getFile(doc) : "#"} target="_blank" rel="noreferrer">
                      <label>
                        {`Policy Document ${index > 0 ? index + 1 : ""}`}

                        <img src={getImageURL()} alt="" />
                      </label>
                    </a>
                  </div>
                ))}
            </div>
            <div className="col-lg-4 col-sm-6 mt_20">
              {pds_doc &&
                pds_doc.split(",").map((doc, index) => (
                  <div
                    key={index}
                    className={`upload--box ${index > 0 ? "mt-3" : ""}`}
                  >
                    <a href={doc ? getFile(doc) : "#"} target="_blank" rel="noreferrer">
                      <label>
                        {`Product Disclosure Sheet (PDS) ${
                          index > 0 ? index + 1 : ""
                        }`}

                        <img src={getImageURL()} alt="" />
                      </label>
                    </a>
                  </div>
                ))}
            </div>
          </>
        )}

        <div className="col-lg-4 col-sm-6 mt_20">
          <div className="upload--box">
            <a href={getFile("MYSIGNAL Terms _ Conditions.pdf")} target="_blank" rel="noreferrer">
              <label htmlFor="policy-document">
                Terms & Conditions
                <img src={getImageURL()} alt="" />
              </label>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentsDownload;
