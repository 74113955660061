import React from "react";

const HeroSection = () => {
  return (
    /*==== Hero Area :: Start ==== */
    // <section
    //   className="hero--area d-flex align-items-center" id="main"
    //   style={{ backgroundImage: `url(assets/images/background_hero.png)` }}
    // >
       <section  className="hero--area d-flex align-items-center theme-background" id="main">
      <div className="container hero">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="hero--content">
              <h4 className="tag-box">Introducing Unprecedented Fusion</h4>
              <h2>
                Pioneering Telco-Infused Insurance & Takaful Protection for
                Comprehensive Coverage
              </h2>
              <p>
                Our Innovative Fusion of Telco Services and Comprehensive
                Insurance & Takaful Protection Ensures Superior Coverage Across
                Lifestyle, Medical, Personal Accident, Life, Home, and More.
              </p>

              <a href="/#plans" className="hero--btn button">
                Subscribe now!
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="hero-images">
              <img className="w-100" src="assets/images/hero.webp" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    /*==== Hero Area :: End ==== */
  );
};

export default HeroSection;
