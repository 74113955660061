import React, { useState, useEffect } from "react";
import Footer from "../components/footer";
import { useNavigate, useLocation } from "react-router-dom";
import GoogleCaptcha from "../components/captcha";

import axiosWithAuth from "../contexts/axiosWithAuth";
const ResetPasswordPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [code, setCode] = useState("");
  const [errors, setErrors] = useState([]);
//  const [isChecked, setIsChecked] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [resetDone, setresetDone] = useState(false);
  const [globalError, setGlobalError] = useState("");

  const [captcha, setCaptchaValue] = useState("");

  const handleRecaptchaChange = (value) => {
    // Handle CAPTCHA value change
    setCaptchaValue(value);
  };

  // Effect to extract the token parameter from the URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenParam = searchParams.get("token");
    if (tokenParam) {
      setCode(tokenParam);
    } else {
      // If no token found, navigate back to login page
      navigate("/login");
    }
  }, [location.search, navigate]);

  const resetPassword = async (credentials) => {
    try {
      const response = await axiosWithAuth().put(
        "/api/v1/update-password",
        credentials
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = [];
    setGlobalError("");
    // Validate email
    if (!password) {
      newErrors.push("Password");
    } else if (password !== confirmPassword) {
      if (!confirmPassword) {
        newErrors.push("ConfirmPassword");
        setPasswordError("");
      } else {
        newErrors.push("ConfirmPassword");
        setPasswordError("Password and Confirm Password Does not Match");
      }
    }

    if (!code) {
      newErrors.push("Code");
    }

    // Validate checkbox
    // if (!isChecked) {
    //   //newErrors.push("Terms");
    // }
    if (!captcha) {
      setGlobalError("Captcha is required.");
    }

    // Set errors if any
    setErrors(newErrors);
    // If there are no errors, submit the form
    if (newErrors.length === 0) {
      try {
        let data = {
          password: password,
          confirm_password: confirmPassword,
          code: code,
          captcha: captcha,
        };
        await resetPassword(data);
        setresetDone(true);
      } catch (error) {
        setGlobalError([error.response.data.message]);
      }
    }
  };

  return (
    <>
      <main>
        {/* ==== Hero Area :: Start ==== */}
        <section className="inner--hero confirmation--hero">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="inner--text">
                  <h3 className="heading">Reset your Password?</h3>
                  <p>Update Your Account Password</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ==== Hero Area :: End ==== */}
        {/* ==== Confirmation Table Area :: Start ==== */}
        <section className="Login--area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-8 col-md-8">
                <div className="login--form card--default">
                  {globalError && (
                    <div class="alert alert-danger">
                      <strong>Error!</strong> {globalError}
                    </div>
                  )}
                  <form onSubmit={handleSubmit}>
                    <div className="input--group">
                      <label for="forget-email">
                        Code
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="forget-email"
                        name="forget-email"
                        placeholder="Confirm Code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                      />
                      <div
                        className="invalid-feedback"
                        style={{
                          display: errors.includes("Code") ? "block" : "none",
                        }}
                      >
                        Code is required
                      </div>
                    </div>
                    <div className="input--group">
                      <label for="forget-email">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="forget-email"
                        name="forget-email"
                        placeholder="New Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div
                        className="invalid-feedback"
                        style={{
                          display: errors.includes("Password")
                            ? "block"
                            : "none",
                        }}
                      >
                        Password is required
                      </div>
                    </div>
                    <div className="input--group">
                      <label for="forget-email">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="forget-email"
                        name="forget-email"
                        placeholder="Confirm New Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <div
                        className="invalid-feedback"
                        style={{
                          display: errors.includes("ConfirmPassword")
                            ? "block"
                            : "none",
                        }}
                      >
                        {passwordError
                          ? passwordError
                          : "Confrim Password is required"}
                      </div>
                    </div>
                    {/* <div className="forget--area input--group">
                      <div className="check--box">
                        <input
                          type="checkbox"
                          name="remember--me"
                          id="remember--me"
                          onChange={(e) => setIsChecked(e.target.checked)}
                        />
                        <label for="remember--me" style={{ marginLeft: "5px" }}>
                          I Accept the <a href="/terms">Terms and Conditions</a>
                        </label>
                        <div
                          className="invalid-feedback"
                          style={{
                            display: errors.includes("Terms")
                              ? "block"
                              : "none",
                          }}
                        >
                          Please Confirm Terms and Conditions Check
                        </div>
                      </div>
                    </div> */}
                    <div className="forget--area input--group">
                      <GoogleCaptcha onChange={handleRecaptchaChange} />
                    </div>
                    {resetDone ? (
                      <div className="custom">
                        <div class="alert alert-success">
                          {" "}
                          <strong>Password Updated successfully</strong>{" "}
                          <a href="/login">
                            <b>Click Here to Login?</b>
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div className="btn--area mt_5">
                        <button
                          disabled={resetDone}
                          type="submit"
                          className="button w-100"
                        >
                          Update Password
                        </button>
                      </div>
                    )}

                    {/* <p className="register--btn">
                      Don’t have an account? <a href="/#plans">Register</a>
                    </p> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ==== Confirmation Table Area :: End ==== */}
      </main>
      <Footer />
    </>
  );
};

export default ResetPasswordPage;
