import React, { useState } from 'react';

const TableAccordion = ({ tables }) => {
  const [activeIndices, setActiveIndices] = useState(Array.from({ length: tables.length }, (_, i) => i));

  const toggleAccordion = (index) => {
    setActiveIndices((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((idx) => idx !== index);
      } else {
        return [...prevIndices, index];
      }
    });
  };

  const flattenCellData = (cellData) => {
    return cellData.map((cell, index) => {
      if (typeof cell === 'object') {
        if (cell.key === 'text-left' || cell.key === 'text-right') {
          return <span key={index}>{cell.value}</span>;
        } else if (cell.isHead) {
          
          return <strong key={index}>{cell.value}</strong>;
        } else {
          return `${cell.key}: ${cell.value}`;
        }
      } else if (cell === '') {
        return ' ';
      }
      return cell;
    });
  };

  return (
    <div className="accordion-container">
      {tables.map((table, tableIndex) => (
        <div className="accordion" key={tableIndex}>
          <div className="accordion-item">
            <h2 className="accordion-header" id={`heading${tableIndex}`}>
              <button
                className={`accordion-button accordion-button-custom ${activeIndices.includes(tableIndex) ? '' : 'collapsed'}`}
                type="button"
                onClick={() => toggleAccordion(tableIndex)}
                aria-expanded={activeIndices.includes(tableIndex) ? 'true' : 'false'}
                aria-controls={`collapse${tableIndex}`}
              >
                <span>{table.heading}</span>
              </button>
            </h2>
            <div
              id={`collapse${tableIndex}`}
              className={`accordion-collapse collapse ${activeIndices.includes(tableIndex) ? 'show' : ''}`}
              aria-labelledby={`heading${tableIndex}`}
              data-bs-parent="#accordion"
            >
              <div className="accordion-body">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className='thead-light'>
                      <tr>
                        {table.headers.map((header, headerIndex) => (
                          <th  key={headerIndex} style={{ width: headerIndex === 0 ? '55%' : headerIndex === 1 ? '5%' : 'auto' }}>
                            <span className="head-text">{header}</span>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {table.data.map((row, rowIndex) => (
                        <tr  key={rowIndex}>
                          {flattenCellData(row).map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TableAccordion;
