import React from "react";
import { getURL } from "../../utils/utils";
import ImagePopup from "./image-popup";
const InsurancePlansSection = () => {
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState('');
  const openPopup = (imageUrl) => {
    setImageUrl(imageUrl);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setImageUrl('');
    setPopupOpen(false);
  };
  return (
    /*==== Soldify Area :: Start ==== */
    <section
      className="soldify--area"
      id="plans"
      style={{ backgroundImage: "url(assets/images/soldify-bg.webp)" }}
    >
      <div className="container">
        {/*start section title  */}
        <div className="section--title">
          <h4 className="tag-box">Insurance + Telco Plans</h4>
          <h3>Solidify Your Future with Insurance & Telco Coverage</h3>
          <p>
            Explore MYSIGNAL’s Insurance & Telco Plans for Comprehensive
            Coverage and Trusted Security
          </p>
        </div>
        {/*end section title  */}
        <div className="row">
          {/*single soldify card  */}
          <div className="col-md-6 mt_30 d-flex flex-column">
            <div className="soldify--card card--default d-flex flex-column h-100">
              <h3 className="small--heading">SIGNAL Gold</h3>
              <div className="img-container">
                <img
                  className="w-50 cursor-pointer"
                  loading="lazy"
                  src="assets/images/flyers/sgoldf.webp"
                  alt=""
                  onClick={() => openPopup("assets/images/flyers/sgoldf.webp")}
                />
                <img
                  className="w-50 cursor-pointer"
                  loading="lazy"
                  src="assets/images/flyers/sgold.webp"
                  alt=""
                  onClick={() => openPopup("assets/images/flyers/sgold.webp")}
                />
                {popupOpen && <ImagePopup imageUrl={imageUrl} onClose={closePopup} />}
              </div>
              <div>
                <p>
                  <strong>SIGNAL Gold</strong> is a customised SIGNAL Plan
                  developed exclusively for Senior Members of our society (
                  <strong>aged 60 to 80 years</strong>) - paired with a SIM card
                  with up to 59GB data and unlimited calls
                </p>
              </div>
              <div className="mt-auto">
                <a href={getURL("signalgold")} className="button btn--soldify">
                  Select Plan
                </a>
              </div>
            </div>
          </div>
          {/*single soldify card  */}
          <div className="col-md-6 mt_30 d-flex flex-column">
            <div className="soldify--card card--default d-flex flex-column h-100">
              <h3 className="small--heading">SIGNAL Life</h3>
              <div className="image-container">
                <img
                  className="w-50 cursor-pointer"
                  loading="lazy"
                  src="assets/images/flyers/signallifef.webp"
                  alt=""
                  onClick={() => openPopup("assets/images/flyers/signallifef.webp")}
                />
                <img
                  className="w-50 cursor-pointer"
                  loading="lazy"
                  src="assets/images/flyers/signallife.webp"
                  alt=""
                  onClick={() => openPopup("assets/images/flyers/signallife.webp")}
                />
                {popupOpen && <ImagePopup imageUrl={imageUrl} onClose={closePopup} />}
              </div>
              <div>
                {" "}
                <p>
                  <strong>SIGNAL Life</strong> is a customised and affordable
                  SIGNAL Plan, providing valuable Personal Accident, Home &
                  Lifestyle Insurance - paired with a SIM card with up to 56GB data
                  and unlimited calls
                </p>
              </div>
              <div className="mt-auto">
                <a href={getURL("signallife")} className="button btn--soldify">
                  Select Plan
                </a>
              </div>
            </div>
          </div>
          {/*single soldify card  */}
          <div className="col-md-6 mt_30 d-flex flex-column">
            <div className="soldify--card card--default d-flex flex-column h-100">
              <h3 className="small--heading">SIGNAL Premier</h3>
              <div className="image-container">
                <img
                  className="w-50 cursor-pointer"
                  loading="lazy"
                  src="assets/images/flyers/signalpremierf.webp"
                  alt=""
                   onClick={() => openPopup("assets/images/flyers/signalpremierf.webp")}
                />
                <img
                  className="w-50 cursor-pointer"
                  loading="lazy"
                  src="assets/images/flyers/signalpremier.webp"
                  alt=""
                   onClick={() => openPopup("assets/images/flyers/signalpremier.webp")}
                />
                {popupOpen && <ImagePopup imageUrl={imageUrl} onClose={closePopup} />}
              </div>
              <div>
                <p>
                  <strong>SIGNAL Premier</strong> is an exclusive SIGNAL Plan
                  offering valuable Medical (Hospitalisation & Surgical),
                  Personal Accident, Lifestyle and Home Care Insurance - paired
                  with a SIM card with up to 65GB data and unlimited calls
                </p>
              </div>
              <div className="mt-auto">
                {/* <a
                href="/registration/signal_premier"
                className="button btn--soldify"
              >
                Select Plan
              </a> */}
                <a href={getURL("signal_premier")} className="button btn--soldify">
                  Select Plan
                </a>
                {/* <button className="button btn--soldify coming-soon">Coming Soon</button> */}
              </div>
            </div>
          </div>
          {/*single soldify card  */}
          <div className="col-md-6 mt_30 d-flex flex-column">
            <div className="soldify--card card--default d-flex flex-column h-100">
              <h3 className="small--heading">SIGNAL Family</h3>
              <div className="image-container">
                <img
                  className="w-50 cursor-pointer"
                  loading="lazy"
                  src="assets/images/flyers/sfamilyf.webp"
                  alt=""
                   onClick={() => openPopup("assets/images/flyers/sfamilyf.webp")}
                />
                <img
                  className="w-50 cursor-pointer"
                  loading="lazy"
                  src="assets/images/flyers/sfamily.webp"
                  alt=""
                   onClick={() => openPopup("assets/images/flyers/sfamily.webp")}
                />
                {popupOpen && <ImagePopup imageUrl={imageUrl} onClose={closePopup} />}
              </div>
              <div>
                <p>
                  <strong>SIGNAL Family</strong> is a customised SIGNAL Plan
                  specially designed for families (up to 4 members in a family),
                  providing valuable Home, Lifestyle and Personal Accident
                  protection - paired with a SIM card with 200GB data (shared)
                  and unlimited calls
                </p>
              </div>
              <div className="mt-auto">
                <a
                  href={getURL("signalfamily")}
                  className="button btn--soldify"
                >
                  Select Plan
                </a>   
                 {/* <button className="button btn--soldify coming-soon">Coming Soon</button>
               */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    /*==== Soldify Area :: End ==== */
  );
};



export default InsurancePlansSection;
