import React from "react";

const PartnershipSection = () => {
  return (
    /*==== Partnership Area :: Start ==== */
    <section className="Partnership--area" id="Partnership">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="row">
              <div className="col-lg-6 mt_30">
                <div className="partnership--text">
                  <h4 className="tag-box">Collaborative Safeguarding</h4>
                  <h3 className="sub--heading">
                    Empowering Partnerships with Telco, Insurance and Takaful
                    Providers
                  </h3>
                  <p>
                    Pioneering Telco-Infused Insurance & Takaful Solutions for
                    Comprehensive Coverage
                  </p>
                  <div className="img--area text-center">
                    <img
                      className="w-100"
                      src="assets/images/partnership.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="partner--logo">
                  <div className="row">
                    <div className="col-sm-6 mt_30">
                      <div className="partner--box card--default">
                        <img
                          className="w-100"
                          src="assets/images/partner1.webp"
                          alt="allianz"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 mt_30">
                      <div className="partner--box card--default">
                        <img
                          className="w-100"
                          src="assets/images/partner5.webp"
                          alt="Berjaya sompo insurance"
                        />
                      </div>
                    </div>
                    {/* <div className="col-sm-6 mt_30">
                      <div className="partner--box card--default">
                        <img
                          className="w-100"
                          src="assets/images/partner2.webp"
                          alt=""
                        />
                      </div>
                    </div> */}
                     <div className="col-sm-3 mt_30"></div>
                    <div className="col-sm-6 mt_30">
                      <div className="partner--box card--default">
                        <img
                          className="w-100"
                          src="assets/images/CelcomDigi.webp"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    /*==== Partnership Area :: End ==== */
  );
};

export default PartnershipSection;
