import React, { useState, useEffect } from "react";
import Footer from "../components/footer";
import GoogleCaptcha from "../components/captcha";
import { useNavigate, useParams } from "react-router-dom";
import axiosWithAuth from "../contexts/axiosWithAuth";


const InfluencerLoginPage = () => {
  const [password, setPassword] = useState("");
 
  const { slug } = useParams();
  const navigate = useNavigate();

  const [captcha, setCaptchaValue] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (!slug) {
      navigate("/");
    }
  }, [slug, navigate]);


  const handleRecaptchaChange = (value) => {
    // Handle CAPTCHA value change
    setCaptchaValue(value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    if (password === "") {
      setError("Password Can Not be Empty.");
      return false;
    }
    if (captcha === "") {
      setError("Captcha is required.");
      return false;
    }
    try {
      const response = await axiosWithAuth().post(
        `/api/v1/influencer-validate/${slug}`,
        { InfluencerId: slug, password: password, captcha: captcha }
      );
      if (response.status === 200) {
        localStorage.setItem("accessStatsToken", response.data.accessToken);
        localStorage.setItem("refreshStatsToken", response.data.refreshToken);
        localStorage.setItem("lastAuthentication", new Date());
        navigate(`/partners/dashboard/${slug}`);
      }
    } catch (error) {
      setError(error.response.data.message || "Invalid credentials");
    }
  };

  return (
    <>
      <main>
        {/* /* ==== Hero Area :: Start ==== */}
        <section className="inner--hero confirmation--hero">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="inner--text">
                  <h3 className="heading">
                    Enter Your Password to View Your Dashboard Stats
                  </h3>
                  <p>
                    Please enter your password to access your Influencer
                    Dashboard.
                  </p>
                  <div className="go--back">
                    <a href="/" className="button">
                      <img
                        src="../../assets/images/chevron-left.svg"
                        alt=""
                      />
                      <span>Go back</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ==== Hero Area :: End ==== */}
        {/* ==== Confirmation Table Area :: Start ==== */}
        <section className="Login--area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-8 col-md-8">
                <div className="login--form card--default">
                  {error && (
                    <div class="alert alert-danger">
                      <strong>Error!</strong> {error}
                    </div>
                  )}

                  <form onSubmit={handleSubmit}>
                    <div className="input--group">
                      <label htmlFor="login--password">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="login--password"
                        name="login--password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="forget--area input--group">
                      <GoogleCaptcha onChange={handleRecaptchaChange} />
                    </div>
                    <div className="btn--area mt_5">
                      <button type="submit" className="button w-100">
                        Confirm
                      </button>
                    </div>
                    {/* <p className="register--btn">
                    Don’t have an account? <a href="/#plans">Register</a>
                  </p> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ==== Confirmation Table Area :: End ==== */}
      </main>
      <Footer hasPartner={true} />
    </>
  );
};

export default InfluencerLoginPage;
