import React from "react";
import Footer from "../components/footer";
import Header from "../components/header";
const CareerTeam = () => {
  return (
    <>
    <Header title={"team"} />
    <div>
    
      <section className="partners--area theme-background">
        <div className="container">
      
        <div className="section--title">
              <div className="col-md-12">
                <div className="inner--text">
                <h4 className="tag-box">Team</h4>
                <h3 className="heading">
                  Embark on a Thrilling Journey with MYSIGNAL!
                </h3>
                </div>
              </div>
            </div>
          {/* end section title  */}
          <div className="row justify-content-center">
            <div className="row">
              <div className="col-md-12">
                <div className="evo--text--box card--default">
                  <p className="font-20">
                    Join us at MYSIGNAL, a pioneering collaboration between
                    top insurance and takaful providers and leading telco
                    operators in Malaysia. As we prepare to introduce our innovative SIGNAL Plans, we
                    are on the lookout for talented individuals to join us in
                    this exciting venture.  Our goal is to empower Malaysian residents with essential
                    insurance protection and extend our reach to neighbouring
                    countries.
                  </p>
               
                  <p className="font-20">
                    We are on the lookout for dedicated professionals who
                    possess the right expertise to help us build a strong
                    organization that consistently exceeds expectations.
                    Whether your expertise lies in sales, marketing,
                    operations, ICT, admin & accounts, or customer service, we
                    have thrilling opportunities waiting for you.
                  </p>
                </div>
              </div>
              <div className="col-md-12 mt-5">
                <div className="evo--text--box card--default">
                  <h3>What Matters to Us?</h3>
                  <ul style={{ listStyleType: 'square' }}>
                    <li className="font-20">
                      <strong>Extensive Experience:</strong> Your past
                      achievements and expertise are invaluable assets to our
                      team.
                    </li>
                    <li className="font-20">
                      <strong>Ability to Think Outside the Box:</strong> We
                      value innovative thinking and creative problem-solving.
                    </li>
                    <li className="font-20">
                      <strong>Team Player:</strong> Collaboration is at the
                      heart of our success.
                    </li>
                    <li className="font-20">
                      <strong>Committed & Result-Oriented:</strong> We are
                      looking for individuals who are dedicated to achieving
                      excellence.
                    </li>
                    <li className="font-20">
                      <strong>Honest & Ethical:</strong> Integrity is
                      non-negotiable in all aspects of our work.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 mt-5">
                <div className="evo--text--box card--default">
                  <h3>What Does Not Matter to Us : </h3>
                  <ul style={{ listStyleType: 'square' }}>
                    <li className="font-20">
                      <strong>Age</strong>
                    </li>
                    <li className="font-20">
                      <strong>Gender</strong>
                    </li>
                    <li className="font-20">
                      <strong>Race & Religion</strong>
                    </li>
                    <li className="font-20">
                      <strong>Educational Qualification(s)</strong>
                    </li>
                    <li className="font-20">
                      <strong>Credit Background</strong>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 mt-5">
                <div className="evo--text--box card--default">
                  <p className="font-20">
                    As a startup navigating the challenges ahead, we
                    prioritize efficiency while offering competitive perks and
                    benefits that grow with the success of our business.
                  </p>
                  <p className="font-20">
                    Interested candidates are invited to send their concise
                    resumes directly to <b> talent@mysignal.com.my</b> c/o Richard.
                    All submissions will be treated with utmost confidence. Be
                    sure to highlight why you are the perfect fit for the
                    role, how you can contribute to our objectives, and what
                    unique strengths you bring to our organization.
                  </p>
                  <p className="font-20">
                    We are also open to hearing from individuals interested in
                    other roles and how they can contribute to our team,
                    including those who believe they are the right person to
                    spearhead the organization.
                  </p>
                  <p className="font-20">
                    Join us at MYSIGNAL and become part of an extraordinary
                    journey!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </>
  );
};

export default CareerTeam;

