import React, { useState, useEffect, useRef , useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosWithAuth from "../contexts/axiosWithAuth";
import Footer from "../components/footer";



const InfluencerDashboardPage = () => {

  const { slug } = useParams();
  const navigate = useNavigate();

  const fetchedRef = useRef(false);
  let [filterLoading, setFilterLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [stats, setStats] = useState(null);
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    const currentDayOfWeek = today.getDay();
    const diff = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;
    const monday = new Date(today);
    monday.setDate(today.getDate() - diff);
    return monday.toISOString().split("T")[0];
  });

  const [endDate, setEndDate] = useState(() => {
    const today = new Date();
    const currentDayOfWeek = today.getDay();
    const diff = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;
    const sunday = new Date(today);
    sunday.setDate(today.getDate() - diff + 6);
    return sunday.toISOString().split("T")[0];
  });


  const fetchStats = useCallback(
    async (isPage = 1) => {
      try {


        const response = await axiosWithAuth("stats").get(
          `/api/v1/influencer-stats/${slug}`,
          {
            params: {
              startDate: startDate,
              endDate: endDate,
              page: isPage,
            },
          }
        );

        if (response.status === 200) {
          const newStats = response.data;

          setStats((prevStats) => ({
            ...prevStats,
            impressions: (isPage === 1 || !prevStats)
              ? newStats.impressions || []
              : [
                ...(prevStats.impressions || []),
                ...(newStats.impressions || []),
              ],
            registrations: (isPage === 1 || !prevStats)
              ? newStats.registrations || []
              : [
                ...(prevStats.registrations || []),
                ...(newStats.registrations || []),
              ],
          }));
          setPage(response.data.currentPage || 1);
          setTotalPages(response.data.totalPages || 1);

        }
      } catch (error) {
        //console.error("Error fetching stats:", error);

        navigate(`/partners/login/${slug}`);


        // Handle error
      }
    },
    [slug, endDate, page, startDate,navigate]
  );

  useEffect(() => {
    if (!slug) {
      navigate("/");
    } else {
      if (!fetchedRef.current) {
        fetchedRef.current = true;
        fetchStats()
      }

    }
  }, [slug,fetchStats,navigate]);





  const handleApplyFilter = async () => {
    // Check if the dates have changed before fetching data
    if (
      prevStartDateRef.current !== startDate ||
      prevEndDateRef.current !== endDate
    ) {
      try {
        // reset page.
        setPage(1);
        setFilterLoading(1);

        await fetchStats();
        // Update previous dates
        prevStartDateRef.current = startDate;
        prevEndDateRef.current = endDate;
        setFilterLoading(0);
      } catch (error) {
        setFilterLoading(0);
      }
    }
  };


  // Refs to store previous dates
  const prevStartDateRef = useRef(startDate);
  const prevEndDateRef = useRef(endDate);
  // Handlers for input changes
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };









  // State to manage expanded registrations
  const [expandedRegistrations, setExpandedRegistrations] = useState([]);

  // Function to toggle expanded registrations
  const toggleExpandedRegistration = (day, date) => {
    const key = `${day}-${date}`;
    setExpandedRegistrations((expandedRegistrations) => {
      if (expandedRegistrations.includes(key)) {
        return expandedRegistrations.filter((item) => item !== key);
      } else {
        return [...expandedRegistrations, key];
      }
    });
  };

  // Helper method to push rows dynamically
  // Helper method to push rows dynamically
  const pushRows = (stats) => {
    const rows = [];
    const impressions = stats.impressions || [];
    const registrations = stats.registrations || [];

    // Check if there are impressions available
    if (impressions.length === 0) {
      rows.push(
        <tr key="no-stats-found">
          <td colSpan="3">No Stats Found</td>
        </tr>
      );
      return rows;
    }

    impressions.forEach((impression) => {
      const registrationsForDay = registrations.filter(
        (registration) =>
          registration.day === impression.day &&
          registration.date === impression.date
      );
      // Parse registration data to calculate counts for each plan
      const planCounts = {};
      registrationsForDay.forEach((registration) => {
        const plans =
          registrationsForDay.length > 0
            ? registrationsForDay[0].all_plans.split(",")
            : [];

        plans.forEach((plan) => {
          //console.log("plans", plan);
          const trimmedPlan = plan.trim();
          if (!planCounts[trimmedPlan]) {
            planCounts[trimmedPlan] = 1;
          } else {
            planCounts[trimmedPlan]++;
          }
        });
      });

      // Generate UI for plan counts
      const planCountElements = Object.entries(planCounts).map(
        ([plan, count]) => (
          <div key={plan}>
            <span className="text-left">{plan}:</span>
            <span className="text-right">{count}</span>
          </div>
        )
      );
      rows.push(
        <React.Fragment key={`${impression.day}-${impression.date}`}>
          <tr>
            <td>{`${impression.day} (${impression.date})`}</td>
            <td>{impression.count || 0}</td>
            <td
              onClick={() =>
                toggleExpandedRegistration(impression.day, impression.date)
              }
            >
              {registrationsForDay.length > 0
                ? `${registrationsForDay[0].count} (Click here For More Details)`
                : 0}
            </td>
          </tr>
          {expandedRegistrations.includes(
            `${impression.day}-${impression.date}`
          ) && (
              <tr>
                <td colSpan="3">
                  <div>{planCountElements}</div>
                </td>
              </tr>
            )}
        </React.Fragment>
      );
    });
    if (totalPages > 1 && page < totalPages) {
      rows.push(
        <tr
          key="load-more"
          style={{ textAlign: "center", marginBottom: "2px" }}
        >
          <td colSpan="3">
            <button className="custom-btn small" onClick={()=>handleLoadMore(page+1)}>
              Load More
            </button>
          </td>
        </tr>
      );
    }

    return rows;
  };

  // Define a function to handle loading more data
  const handleLoadMore = async (current) => {
    try {
      fetchStats(current);
    } catch (error) {
      alert("Unable To Load More Data... Please Try ")
      //console.error("Error loading more data:", error);
    }
  };

  // Render table rows
  const renderRows = () => {
    //console.log("stats,",stats,loading,)
    const rows = pushRows(stats || {});

    return rows;
  };


  if (!fetchedRef.current) {
    return (
      <>
        <p>Loading</p>
      </>
    )
  }
  return (
    <>
      <main>
        {/* <section
          className="inner--hero registration-hero single--life--registration"
          style={{
            backgroundImage: "url(../assets/images/background_hero.png)",
          }}
        > */}
        <section className="inner--hero registration-hero single--life--registration theme-background">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="inner--text">
                  <h4 className="tag-box">Dashboard Stats</h4>
                  <h3 className="heading">
                    Influencer Connectivity with MYSIGNAL
                  </h3>
                  <p>
                    Here You Can See Activity Stats Through Your Influencer
                    Link.
                  </p>
                  <div className="go--back mt-10">
                    <a href="/" className="button">
                      <img src="../../assets/images/chevron-left.svg" alt="" />
                      <span>Go back</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="singlelife--registration singlefamily--registration">
          <div className="container d-flex align-items-center mb-3">
            <input
              type="date"
              className="form-control form-control-sm m-2"
              value={startDate}
              onChange={handleStartDateChange}
            />

            <input
              type="date"
              className="form-control form-control-sm m-2"
              value={endDate}
              onChange={handleEndDateChange}
            />

            <button
              className="custom-btn small m-2"
              onClick={handleApplyFilter}
              data-id={filterLoading === 1 ? "ss" : "sss"}
              disabled={filterLoading === 1}
            >
              {filterLoading === 1 ? "Filtering" : " Filter"}
            </button>
          </div>

          <div className="container">
            <div className="table-responsive">
              <h5 className="">Stats Data</h5>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="text-center">Day</th>
                    <th className="text-center">Impressions Count</th>
                    <th className="text-center">Total Registrations</th>
                  </tr>
                </thead>
                <tbody>{renderRows()}</tbody>
              </table>
            </div>
          </div>
        </section>
      </main>
      <Footer hasPartner={true} />
    </>
  );
};

export default InfluencerDashboardPage;
