import React from "react";

const TestimonialSection = () => {
  return (
    /*==== Testimonial Area :: Start ==== */
    <section
      className="testimonial--area"
      id="testimonial"
      style={{ backgroundImage: "url(assets/images/testi-bg.webp)" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-4 mt_25">
            <div className="testi--text--box">
              <h4 className="tag-box">Testimonials</h4>
              <h3 className="sub--heading">Client Experiences, Our Pride</h3>
              <p>
                Discover How MYSIGNAL Plans Have Secured Lives: Testimonials
                Reflecting Trust and Satisfaction
              </p>
              <div className="img--area">
                <img className="w-100" src="assets/images/testi.webp" alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-8 pl_50">
            <div className="row">
              <div className="col-md-6 mt_25">
                <div className="card--default testi--card">
                  <p>
                    Finding comprehensive protection for my family used to be a
                    challenge until we discovered SIGNAL Family. This innovative
                    plan combines insurance coverage for home, lifestyle, and
                    personal accidents with outpatient medical reimbursement.
                    What's more, each family member gets a SIM card with
                    top-notch telco services. It's the perfect blend of safety
                    and connectivity in one package.
                  </p>
                  <div className="client--area">
                    <h4>SIGNAL Family</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt_25">
                <div className="card--default testi--card">
                  <p>
                    SIGNAL Gold has been a true blessing for me as a senior.
                    This unique insurance plan not only provides tailored
                    protection benefits, including outpatient medical
                    reimbursement, but also includes a top-notch telco plan.
                    It's like having peace of mind and connectivity all in one
                    package. SIGNAL Gold offers seniors like me the best of both
                    worlds at an unbeatable value.
                  </p>
                  <div className="client--area">
                    <h4>SIGNAL Gold</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt_25">
                <div className="card--default testi--card">
                  <p>
                    SIGNAL Life has truly exceeded my expectations. It's not
                    just a telco plan; it's a lifeline. With robust coverage for
                    personal accident, home, and lifestyle, coupled with
                    outpatient medical reimbursement, it provides the security I
                    need. This coupled with an excellent telco package, staying
                    connected has never been easier. SIGNAL Life is simply
                    indispensable for safeguarding both my well-being and
                    connectivity needs.
                  </p>
                  <div className="client--area">
                    <h4>SIGNAL Life</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt_25">
                <div className="card--default testi--card">
                  <p>
                    Choosing SIGNAL Premier was one of the best decisions I've
                    made. It offers unparalleled worldwide protection round the
                    clock, including medical, personal accident, home, and
                    lifestyle insurance. With a SIM card providing exceptional
                    telco services, it's the ultimate in convenience and peace
                    of mind. SIGNAL Premier sets a new standard in comprehensive
                    coverage and connectivity.
                  </p>
                  <div className="client--area">
                    <h4>SIGNAL Premier</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    /*==== Testimonial Area :: End ==== */
  );
};

export default TestimonialSection;
