import React, { useState, useEffect } from "react";
import EligibilityBox from "./eligibility";

import { useNavigate, useLocation } from "react-router-dom";

import DocumentsDownload from "./documentsDownload";
import AgreementBox from "./agreementBox";
import PersonalDetailsForm from "./detailsForm";
import TableAccordion from "./TableAccordion";
import Plans from "./plans";
import {
  ValidatePlanFields,
  PlanRequiredFields,
  PlanSkipables,
  PlanTables,
  PlansFormData,
  hasValidationErrors,
  NRICAndDOBValidation,
  ValidationErrorMessage,
  ValidateFormKeys,
} from "../../utils/utils";
const SignalLife = ({ title, planType, details }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const tables = PlanTables("signallife");
  const dataObject = PlansFormData("signallife");
  const [formData, setFormData] = useState(dataObject);
  const [errors, setErrors] = useState({ signallife: [] });
  const [customErrors, setCustomErrors] = useState({ signallife: {} });

  
  const [loading, setloading] = useState(false);

  const [validationErrors, setValidationErrors] = useState(null);

  const [myStyle, setToggleStyle] = useState("block");
  useEffect(() => {
    if (location.state && location.state.formData) {
      // Set the formData state using the data from location.state
      setFormData(location.state.formData);
    }
  }, [location.state]);

  const handlePurchaseChange = (value, category) => {
    const updatedData = { ...formData[category], purchase: value };
    if (value !== "new_sim_card") {
      updatedData["phone_file_required"] = true;
    } else {
      updatedData["phone_file_required"] = false;
      updatedData["phone_file"] = "";
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [category]: updatedData,
    }));
  };
  const handleServiceChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      service: value,
    }));
  };
  const handlePlanChange = (value, price) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      plan: value,
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      amount: price,
    }));
  };
  const handleNationalityChange = (nationality) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      nationality: nationality,
    }));
  };

  const handleAgeChange = (isAdultdefaultValue) => {
    if (!isAdultdefaultValue) {
      setToggleStyle("none");
    } else {
      setToggleStyle("block");
    }
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setloading(true);
    // Reset errors
    setErrors({
      signallife: [],
    });

    // Check for missing fields
    const requiredFields = PlanRequiredFields();
    const skipables = PlanSkipables();
    if (formData.nationality !== "malaysian") {
      requiredFields.push("dob");
      requiredFields.push("country");
      requiredFields.push("gender");
    }

    // Function to check for missing fields in each index of formData
    const missingFields = {};
    Object.entries(formData).forEach(([index, fields]) => {
      if (skipables.includes(index)) {
        return;
      }
      const missingInIndex = requiredFields.filter((field) => !fields[field]);
      if (missingInIndex.includes("phonefile")) {
        if (!formData[index]["phone_file_required"]) {
          const indexToRemove = missingInIndex.indexOf("phonefile");
          if (indexToRemove !== -1) {
            missingInIndex.splice(indexToRemove, 1);
          }
        }
      }
      if (missingInIndex.length > 0) {
        missingFields[index] = missingInIndex;
      }
    });

    if (Object.keys(missingFields).length > 0) {
      Object.keys(missingFields).forEach((key) => {
        const errors = missingFields[key];
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: [...prevErrors[key], ...errors],
        }));
      });
      setloading(false);
      return; // Don't proceed with submission if there are missing fields
    }

    const { DOBupdatedErrors } = NRICAndDOBValidation(formData, "18-70");

    setCustomErrors((prevCustomErrors) => ({
      ...prevCustomErrors,
      ...DOBupdatedErrors,
    }));

    if (
      !Object.values(DOBupdatedErrors).every(
        (obj) => Object.keys(obj).length === 0
      )
    ) {
      setloading(false);
      return;
    }
    try {
      await ValidateFormKeys(formData);
      navigate("/confirmation", { state: { formData: formData } });
    } catch (error) {
      setloading(false);
      setValidationErrors(
        error.response.data.errors || [error.response.data.message]
      );
    }
    
  };

  // Function to handle changes to input fields
  const handleChange = (category, name, value) => {
    // Make a copy of the nested array based on the category

    const { isValid, message } = ValidatePlanFields(
      name,
      value,
      formData.nationality
    );
    if (!isValid) {
      const errors = name;
      setErrors((prevErrors) => ({
        ...prevErrors,
        [category]: [...prevErrors[category], errors],
      }));
      setCustomErrors((prevErrors) => ({
        ...prevErrors,
        [category]: { ...prevErrors[category], [name]: message },
      }));
      setFormData((prevFormData) => ({
        ...prevFormData,
        [category]: { ...prevFormData[category], [name]: "" },
      }));
      return;
    } else {
      // Reset the error message for the specified name within the specified category
      setErrors((prevErrors) => ({
        ...prevErrors,
        [category]: prevErrors[category].filter((error) => error !== name),
      }));

      setCustomErrors((prevErrors) => ({
        ...prevErrors,
        [category]: {
          ...prevErrors[category],
          [name]: "",
        },
      }));
    }

    const updatedData = { ...formData[category], [name]: value };
    if (name === "riskLocationSameaslocation" && value === "1") {
      updatedData["risklocation"] = updatedData["address"]
        ? updatedData["address"]
        : "";
    } else if (name === "address") {
      updatedData["risklocation"] =
        updatedData["riskLocationSameaslocation"] === "1"
          ? updatedData["address"]
          : "";
    }
    // Update the specific index in the copied array

    // Update the state with the modified array
    setFormData((prevFormData) => ({
      ...prevFormData,
      [category]: updatedData,
    }));
  };
  return (
    /*==== Connect Area :: Start ==== */
    <section className="singlelife--registration">
      <div className="container">
        {/* start section title  */}
        <div className="section--title">
          <h3>{title}</h3>
          <p>{details}</p>
        </div>
        {/* end section title  */}
        <div className="row justify-content-center">
          <div className="col-md-11 mt_30">
            <div className="benefit--box">
              <h3>
                <img src="../assets/images/gridicons_dropdown.svg" alt="" />
                Benefits
              </h3>
              <form className="benfit--form" onSubmit={handleSubmit}>
                {/* benefit tab  */}
                <TableAccordion tables={tables} />
                {/* documents upload  */}
                <DocumentsDownload
                  pds_doc={"SIGNAL Life - PDS.pdf"}
                  policy_doc={"SIGNAL Life - Policy.pdf"}
                />
                <EligibilityBox
                  labelText="Are you between 18-70 years old currently?"
                  warningText="(We are sorry. You need to be between 18-70 years old to register for this plan)"
                  onSelectionChange={handleNationalityChange}
                  onAgeChange={handleAgeChange}
                  preFormData={formData}
                />

                <div
                  className="eligibility--premium--hidden--items"
                  style={{ display: myStyle }}
                >
                  {/* plan box wrapper  */}
                  <div className="plan--box--wrapper radio--box--default mt_65">
                    <h3>Plans</h3>
                    <Plans
                      categories={["signallife"]}
                      preFormData={formData}
                      setPurchaseOption={handlePurchaseChange}
                      setServiceOption={handleServiceChange}
                      setPlanOption={handlePlanChange}
                    />
                  </div>

                  {/* personal details  */}

                  <PersonalDetailsForm
                    category="signallife"
                    onFormUpdate={handleChange}
                    errors={errors}
                    preformData={formData}
                    customErrors={customErrors}
                  />
                  {/* aggrement box */}
                  <AgreementBox
                    category="signallife"
                    errors={errors}
                    preformData={formData}
                    onAgreementChange={handleChange}
                    onTermsChange={handleChange}
                  />

                  {(errors || customErrors) &&
                    hasValidationErrors(errors, customErrors) && (
                      <div
                        className="alert alert-danger d-inline-block mt-5"
                        role="alert"
                      >
                        Form Validation Failed, Please Clear Form Errors
                      </div>
                    )}

                  {validationErrors && (
                    <ValidationErrorMessage errors={validationErrors} />
                  )}
                  <div className="benefit--submit--btn mt_35">
                    <button type="submit" className="button">
                    {loading ? `Validating` : "Confirm"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignalLife;
