import React, { useState } from "react";
import Footer from "../components/footer";

import axiosWithAuth from "../contexts/axiosWithAuth";
//import TeamSection from "../components/sections/team";
import Header from "../components/header";
const TestPage = () => {
  const [email, setEmail] = useState("");
  const [mobile, setMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [price , setPrice] = useState(1.00)
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let data = {
        'mobile' : mobile || '1234567889',
        'email' : email || 'hassanali703@gmail.com',
        'name' : name || 'HASSAN ALI',
        'price' : price || 1.00,
        
      }
  
      const response = await axiosWithAuth().post('/api/v1/eghl-staging', data);
      if (response.data.payment_url && response.data.status === 200) {
        window.location.href = response.data.payment_url;
      } else{
        alert("Unable To Process Request Please Try Later...")
      }
    } catch (error) {
      alert("Unable To Process Request Please Try Later...")
    } finally{
      setIsLoading(false);
    }
    
   
  };
  return (
    <>
      <Header title={"about"} />
      <div>
        {/* <section
          className="inner--hero about--hero--area"
          style={{ backgroundImage: `url(assets/images/background_hero.png)` }}
        > */}
           <section
          className="inner--hero about--hero--area theme-background"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="inner--text">
                  <h4 className="tag-box">Discover Our Journey</h4>
                  <h3 className="heading">
                    Unveiling the Heartbeat of MYSIGNAL
                  </h3>
                  <p>
                    Crafting Connections, Shaping Futures - Explore the Story
                    Behind MYSIGNAL's Visionary Odyssey in Telco-Infused
                    Insurance and Takaful.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="evolution--area">
          
          <div className="container">
        
            <div className="row justify-content-center">
            <div className="alert alert-primary">
                <p><strong>For EGHL Payment You Will Redirected to MYSIGNAL payment gateway eGHL site</strong></p>
            </div>
              <div className="col-xl-10 col-md-11 mt-4">
                <h3>EGHL TEST PAYMENT</h3>
                <form onSubmit={handleSubmit} className="mt-4">
                  <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={name}
                      onChange={handleNameChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobileNumber">Mobile Number:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="mobileNumber"
                      value={mobile}
                      onChange={handleMobileNumberChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="plan">Plan Rate</label>
                     <select
                    className="form-select"
                    name="plan"
                    id="plan"
                    onChange={(e) => setPrice(e.target.value)}
                  >
                    <option value="1.00" defaultValue>
                     1.00
                    </option>
                    <option value="0.14">0.14</option>
                    <option value="250.00">250.00</option>
                  </select>
                  </div>
                  <button type="submit" disabled={isLoading } className="btn btn-primary mt-3">
                    {isLoading ? 'processing Please Wait' : `Pay Now RM ${price}`}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default TestPage;
