import React, { useState,  } from "react";
import Footer from "../components/footer";
import axiosWithAuth from "../contexts/axiosWithAuth";
import GoogleCaptcha from "../components/captcha";
const ForgetPasswordPage = () => {

  const [reseted,setReseted] = useState("");
  const [email, setEmail] = useState("");
 
  const [errors, setErrors] = useState([]);
  const [globalError, setGlobalError] = useState("");
  const [captcha, setCaptchaValue] = useState("");

  const handleRecaptchaChange = (value) => {
    // Handle CAPTCHA value change
    setCaptchaValue(value);
  };


  const resetPassword = async (credentials) => {
    try {
      //("response",credentials)
      const response = await axiosWithAuth().post(
        "/api/v1/forget-password",
        credentials
      );
        
      return response;
    } catch (error) {
      throw error;
    }
  };



  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    //console.log('e',e)
    e.preventDefault();
    setGlobalError("");
    const newErrors = [];

    // Validate email
    if (!email) {
      newErrors.push("Email");
    }

    // Validate checkbox
    
    // Set errors if any
    setErrors(newErrors);
    if (!captcha) {
      setGlobalError("Captcha is required.");
    }
    //console.log("newErrors",newErrors)
    // If there are no errors, submit the form
    if (newErrors.length === 0) {
      // Your form submission logic here
      try {
        const response = await resetPassword({ email: email });
        setReseted(response.data.message)
       
      } catch (error) {
        //console.log("error", error.response.data.message);
        setGlobalError([error.response.data.message]);
      }
    }
  };

  return (
    <>
      <main>
        {/* ==== Hero Area :: Start ==== */}
        <section className="inner--hero confirmation--hero">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="inner--text">
                  <h3 className="heading">Forgot your Password?</h3>
                  <p>
                    Don't fret! Just type in your email and we will send you a
                    code to reset your password!
                  </p>
                  <div className="go--back">
                    <a href="/login" className="button">
                      <img src="assets/images/chevron-left.svg" alt="" />
                      <span>Go back</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ==== Hero Area :: End ==== */}
        {/* ==== Confirmation Table Area :: Start ==== */}
        <section className="Login--area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-8 col-md-8">
                <div className="login--form card--default">
                  {globalError && (
                    <div class="alert alert-danger">
                      <strong>Error!</strong> {globalError}
                    </div>
                  )}
                  {reseted && (
                    <div class="alert alert-success">
                      <strong>Alert!</strong> {reseted}
                    </div>
                  )}


                  <form onSubmit={handleSubmit}>
                    <div className="input--group">
                      <label for="forget-email">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="forget-email"
                        name="forget-email"
                        placeholder="Your Email Address"
                        value={email}
                        onChange={handleEmailChange}
                      />
                      <div
                        className="invalid-feedback"
                        style={{
                          display: errors.includes("Email") ? "block" : "none",
                        }}
                      >
                        Email is required
                      </div>
                    </div>
                    {/* <div className="forget--area input--group">
                      <div className="check--box">
                        <input
                          type="checkbox"
                          name="remember--me"
                          id="remember--me"
                          onChange={handleCheckboxChange}
                        />

                        <label for="remember--me" style={{ marginLeft: "5px" }}>
                          I Accept the <a href="/terms">Terms and Conditions</a>
                        </label>
                        <div
                          className="invalid-feedback"
                          style={{
                            display: errors.includes("Terms")
                              ? "block"
                              : "none",
                          }}
                        >
                          Please Confirm Terms and Conditions Check
                        </div>
                      </div>
                    </div> */}
                    <div className="forget--area input--group">
                      <GoogleCaptcha onChange={handleRecaptchaChange} />
                    </div>

                    <div className="btn--area mt_5">
                      <button type="submit" className="button w-100">
                        Reset Password
                      </button>
                    </div>
                    {/* <p className="register--btn">
                      Don’t have an account? <a href="/#plans">Register</a>
                    </p> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ==== Confirmation Table Area :: End ==== */}
      </main>
      <Footer />
    </>
  );
};

export default ForgetPasswordPage;
