import React, {  useEffect, useRef, useCallback,useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosWithAuth from "../contexts/axiosWithAuth";
import Footer from "../components/footer";
import { BuildPaymentData, invoiceTemplate } from "../utils/utils";
import html3pdf from "html3pdf";

const ViewInvoicePage = () => {

  const { invoice_no } = useParams();
  const navigate = useNavigate();

  const fetchedRef = useRef(false);
  const [success,setSuccess] = useState(false)
  const [error,setError] = useState(false)

  const fetchStats = useCallback(
    async () => {
      
      try {
        let isAllow  = 0
        if(localStorage.getItem('setViewInvoice')){
          setSuccess(true)
          isAllow = 1
        }else{
          setError(true)
        }

        if(!isAllow){
          return
        }
        const response = await axiosWithAuth().get(`/api/v1/view-invoice/${invoice_no}`);
        
        if (response.status === 200) {
          try {
            localStorage.removeItem('setViewInvoice')
            let data = response.data
            const paymentData = BuildPaymentData(data, 0, 0);

            const htmlContent = invoiceTemplate(paymentData,true);

            // Options for html2pdf
            const options = {
              filename: `Payment_details_${paymentData.invoice_no}.pdf`,
              html2canvas: { scale: 2 }, // Set HTML2Canvas options
              jsPDF: {
                unit: "in", // Set unit to inches
                format: [15, 20], // Set page format to letter size (8.5x11 inches)
                orientation: "portrait", // Set page orientation to portrait
              },
            };

            // Generate PDF from HTML content
            html3pdf().from(htmlContent).set(options).save();
          } catch (error) {
            alert("Unable to Download PDF At the moment, please try Later.");
            return false;
          }

        }
      } catch (error) {
        console.error("Error fetching PDF Data:", error);
      }
    },
    [invoice_no]
  );

  useEffect(() => {
    if (!invoice_no) {
      navigate("/");
    } else {
      if (!fetchedRef.current) {
        fetchedRef.current = true;
        fetchStats()
      }

    }
  }, [invoice_no, fetchStats, navigate]);


 
  return (
    <>
      <main>
        {/* <section
          className="inner--hero registration-hero single--life--registration"
          style={{
            backgroundImage: "url(../assets/images/background_hero.png)",
          }}
        > */}
        <section className="inner--hero registration-hero single--life--registration theme-background">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="inner--text">
                  <h4 className="tag-box">Downloading Invoice {invoice_no}</h4>
                  {success && <div className="alert alert-success text-center mt-5">
                    Downloaded Successfully Invoice {invoice_no}
                  </div>}
                  {error && <div className="col-md-4 col-md-offset-4 alert alert-danger text-center mt-5 m-5">
                    Error Downloading Invoice {invoice_no}
                  </div>}

                  <div className="go--back mt-10">
                    <a href="/" className="button">
                      <img src="../../assets/images/chevron-left.svg" alt="" />
                      <span>Go back</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default ViewInvoicePage;
