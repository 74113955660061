import React from 'react';
import PurchasePlans from './purchaseplans';
import ServiceProviders from './serviceProviders';
import PlanList from './planList';

const Plans = ({categories,preFormData,setPurchaseOption,setServiceOption,setPlanOption,onCustomerChange,insurers,handleCheckboxChange}) => {
    //console.log("categories",categories)
    const onPurchaseChangeOption = (purchaseValue,category) => {
       
        setPurchaseOption(purchaseValue,category)
    };

    
    const onServiceChangeOption = (value) => {
    
        setServiceOption(value);
    };

    const onPlanChangeOption = (value,price) => {
        setPlanOption(value,price);
    };

   


    return (
        <div className="plan--box card--default">
            <PlanList title={preFormData.title} option={preFormData.plan} onPurchaseChange={onPlanChangeOption} />
            {/* telco service  */}
            <ServiceProviders option={preFormData.service} onPurchaseChange={onServiceChangeOption} />
            {/* purchase plan  */}
            <PurchasePlans categories={categories} preFormData={preFormData}  onPurchaseChange={onPurchaseChangeOption} />
        </div>      
    )
};

export default Plans;
