import React from 'react';

const AgreementBox = ({category,onAgreementChange,onTermsChange,errors,preformData,classTitle=""}) => {
    const handleTermsChange = (event) => {
       
        onTermsChange(category,"terms", event.target.checked ? "1" : '');
    };

    const handleAgreementChange = (event) => {
        
       
        onAgreementChange(category,"agreement", event.target.checked ? "1" : '');
    };


    

    return (
        
	
        <div className={classTitle ? classTitle : "aggrement--box card--default mt_65"}>
        <div className="input--group">
            <input type="checkbox" name={`marketing--agreement-${category}`} id={`marketing--agreement-${category}`} checked={preformData[category]?.agreement || false} onChange={handleAgreementChange} />
            <label htmlFor={`marketing--agreement-${category}`}>I hereby agree the registration and ownership of my mobile number will be held by MySignal Marketing Sdn Bhd (company registration no.: 201901003462 / 1312788-U).</label>
            <div className="invalid-feedback" style={{ display: errors[category].includes('agreement')? 'block' : 'none' }}>Please Confirm Marketing Agreement</div>
        </div>
        <div className="input--group mt_35">
            <input type="checkbox" name={`terms--agreement-${category}`} id={`terms--agreement-${category}`}  checked={preformData[category]?.terms || false} onChange={handleTermsChange} />
            <label htmlFor={`terms--agreement-${category}`}>I hereby agree and accept the governing Terms and Conditions and to be bound by the terms of the same and such additional terms as may be set out in the Policy and/or the certificate of insurance issued for SIGNAL plan.</label>
            <div className="invalid-feedback" style={{ display: errors[category].includes('terms')? 'block' : 'none' }}>Please Confirm Terms Agreement</div>
        </div>
    </div>
    );
}

export default AgreementBox;
