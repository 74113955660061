import React from "react";
import ImagePopup from "./image-popup";
const PlansSection = () => {
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState('');
  const openPopup = (imageUrl) => {
    setImageUrl(imageUrl);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setImageUrl('');
    setPopupOpen(false);
  };
  return (
    /*==== Soldify Area :: Start ==== */
    /*==== Solidarity Area :: Start ==== */
    <section className="solidarity--area" id="solidarity">
      <div className="container">
        {/*start section title  */}
        <div className="section--title">
          <h4 className="tag-box">Takaful + Telco Plans</h4>
          <h3>Solidarity in Safeguarding Your Tomorrow</h3>
          <p>
            Embrace MYSIGNAL’s Takaful and Telco Plans Comprehensive Coverage
            Reflecting Islamic Values for Collective Well-being and Security
          </p>
        </div>
        {/*end section title  */}
        <div className="row">
          {/* single soldify card */}
          <div className="col-md-6 mt_30 d-flex flex-column">
            <div className="soldify--card card--default d-flex flex-column h-100">
              <h3 className="small--heading">SIGNAL i-Family</h3>
              <div className="img-container">
                <img
                  className="w-50 cursor-pointer"
                  loading="lazy"
                  src="assets/images/flyers/signalifamilyf.webp"
                  alt=""
                   onClick={() => openPopup("assets/images/flyers/signalifamilyf.webp")}
                />
                <img
                  className="w-50 cursor-pointer"
                  loading="lazy"
                  src="assets/images/flyers/signalifamily.webp"
                  alt=""
                   onClick={() => openPopup("assets/images/flyers/signalifamily.webp")}
                />
                {popupOpen && <ImagePopup imageUrl={imageUrl} onClose={closePopup} />}
              </div>
              <div>
                <p>
                  <strong>SIGNAL i-Family</strong> is a customised Takaful
                  SIGNAL Plan specially designed for families (up to 4 members
                  in a family), providing valuable Home, Lifestyle and Personal
                  Accident protection - paired with a SIM card with 200GB data
                  (shared) and unlimited calls
                </p>
              </div>
              <div className="mt-auto">
                {/* <a href="/registration/signal-i-family" className="button btn--soldify"> Choose To Select</a> */}
                <button className="button btn--soldify coming-soon">Coming Soon</button>
              </div>
            </div>
          </div>
          {/* single soldify card */}
          <div className="col-md-6 mt_30 d-flex flex-column">
            <div className="soldify--card card--default d-flex flex-column h-100">
              <h3 className="small--heading">SIGNAL i-Life</h3>
              <div className="img-container">
                <img
                  className="w-50 cursor-pointer"
                  loading="lazy"
                  src="assets/images/flyers/signalilifef.webp"
                  alt=""
                   onClick={() => openPopup("assets/images/flyers/signalilifef.webp")}
                />
                <img
                  className="w-50 cursor-pointer"
                  loading="lazy"
                  src="assets/images/flyers/signalilife.webp"
                  alt=""
                   onClick={() => openPopup("assets/images/flyers/signalilife.webp")}
                />
                {popupOpen && <ImagePopup imageUrl={imageUrl} onClose={closePopup} />}
              </div>
              <div>
                <p>
                  <strong>SIGNAL i-Life</strong> is a customised and affordable
                  Takaful SIGNAL Plan, providing valuable Personal Accident
                  benefits and Outpatient Clinical Reimbursement - paired with a
                  SIM card with 50GB data and unlimited calls
                </p>
              </div>
              <div className="mt-auto">
                {/* <a href="/registration/signal-i-life" className="button btn--soldify"> Choose To Select</a> */}
                <button className="button btn--soldify coming-soon">Coming Soon</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    /*==== Solidarity Area :: End ==== */
    /*==== Soldify Area :: End ==== */
  );
};

export default PlansSection;
