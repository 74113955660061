import React from "react";

const ProtectionSection = () => {
  return (
    /*==== Protection Area :: Start ==== */
    <section className="protection--area" id="protection">
      <div className="container">
        <div className="row align-items-end">
          <div className="col-md-7 mt_20">
            <div className="protection--text">
              <h4 className="tag-box">Explore Our Protection Plans</h4>
              <h3 className="sub--heading">
                Explore MYSIGNAL’s Insurance & Takaful Plans paired with a SIM
                Card for Your Secure Future
              </h3>
              <p>
                At MYSIGNAL, we believe in safeguarding your future with
                comprehensive protection plans tailored to your needs. Choose
                between our two distinct yet equally reliable plan options:
                Insurance Plans and Takaful Plans, both with exceptional Telco
                Services. Each offers unique benefits and features, ensuring
                your peace of mind in different ways.
              </p>
            </div>
          </div>
          <div className="col-md-5 mt_20">
            <div className="protection--img">
              <img
                className="w-100"
                src="assets/images/protection.webp"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt_20">
            <div className="protection--card cards">
              <img src="assets/images/pc1.webp" alt="" />
              <div className="text">
                <h3>INSURANCE PLANS + TELCO SERVICES</h3>
                <p>
                  Discover the reliability of our Conventional Plans tailored to
                  meet diverse insurance needs. Our Insurance Plans are crafted
                  based on time-tested principles, offering a wide array of
                  benefits designed to secure your lifestyle, health and
                  valuable assets.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt_20">
            <div className="protection--card cards">
              <img src="assets/images/pc2.webp" alt="" />
              <div className="text">
                <h3>TAKAFUL PLANS + TELCO SERVICES</h3>
                <p>
                  Embrace the strength of our Takaful Plans, rooted in
                  solidarity and guided by Islamic principles. These plans stand
                  as a testament to shared responsibility, providing inclusive
                  coverage aligned with Sharia guidelines.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    /*==== Protection Area :: End ==== */
  );
};

export default ProtectionSection;
