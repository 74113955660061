import React from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import PaymentConfirmation from "../components/sections/paymentConfirmation";



const PaymentConfirmationPage = () => {
  
  return (
    <>
       <Header />
      <main>
        <PaymentConfirmation />
      </main>
      <Footer />
    </>
  );
};

export default PaymentConfirmationPage;
