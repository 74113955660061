import React from 'react';

const SignalGoldPlans = ({value,onPlanChange}) => {
    
    
    const handlePlanChange = (event) => {
        let value = event.target.value
        let price = value === "signalgold" ? 68.00 : 78.00
        
        onPlanChange(value,price);
    };

    return (
        <div className="select--plan">
        <h4>Select Plan</h4>
        <div className="plan--option">
            <div className="input--group">
                <input type="radio" id="single-life" value="signalgold" name="signalgold" checked={value === "signalgold"} onChange={handlePlanChange} />
                <label htmlFor="single-life">
                <p>SIGNAL Gold</p>
				 <span>RM68.00/Month</span>
                </label>
            </div>
            <div className="input--group ml_60">
                <input type="radio" id="single-life-plus" value="signalgoldplus" name="signalgoldplus" checked={value === "signalgoldplus"} onChange={handlePlanChange} />
                <label htmlFor="single-life-plus">
                <p>SIGNAL Gold Plus</p>
				<span>RM78.00/Month</span>
                </label>
            </div>
        </div>
    </div>
    );
}

export default SignalGoldPlans;
