import React  from 'react';

const SignalFamilyPlans = ({value,onPlanChange}) => {
   
    const handlePlanChange = (event) => {

        onPlanChange(event.target.value,192.00);
    };

    return (
        <div className="select--plan">
        <h4>Select Plan</h4>
        <div className="plan--option">
            <div className="input--group">
                <input type="radio" id="single-life" value="signalfamily" name="signalfamily" checked={value === "signalfamily"} onChange={handlePlanChange} />
                <label htmlFor="single-life">
                <p>SIGNAL Family</p>
				 <span>RM192.00/Month</span>
                </label>
            </div>
        </div>
    </div>
    );
}

export default SignalFamilyPlans;
