import  { useState, useEffect } from "react";
import { useNavigate , useParams  } from "react-router-dom";
import axiosWithAuth from "../contexts/axiosWithAuth";

const InfluencerPage = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  let [hasRun,setHasRun] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      

      if (slug && slug !== "" && hasRun === 0) {
     
      
        localStorage.setItem("influencer", slug);
        const data = { InfluencerId: slug };

        try {
          const axiosInstance = axiosWithAuth();
          await axiosInstance.post("/api/v1/influencer-tracking", data, {
            headers: {
              "Content-Type": "application/json",
              "User-Agent": navigator.userAgent,
            },
          });

          // If the request is successful, navigate back to the homepage
          navigate("/");
        } catch (error) {
          console.error("Error while tracking influencer:", error);
          // Handle error if needed
        }
      }
    };

    fetchData();
    setHasRun(hasRun++);
  }, [slug,hasRun,navigate]);

  return null; // No content to render on this page
};

export default InfluencerPage;
