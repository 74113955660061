import React, { useState } from "react";
import Footer from "../components/footer";
import { useAuth } from "../contexts/AuthContext";
import GoogleCaptcha from "../components/captcha";
const Loginpage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { login } = useAuth();
  const [captcha, setCaptchaValue] = useState("");

  const handleRecaptchaChange = (value) => {
    // Handle CAPTCHA value change
    setCaptchaValue(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    if (email === "" || password === "") {
      setError("Email/Password Can Not be Empty.");
      return false;
    }
    if(captcha === ""){
      setError("Captcha is required.");
      return false;
    }
    try {
      await login({ email:email, password:password,captcha:captcha });
    } catch (error) {
     
      if(error.response.status === 429 && error.response.data){
        setError(error.response.data)
      } else if(error.response.data.message){
        setError(error.response.data.message)
      } else {
        setError("Invalid email or password");
      }
    }
  };

  //login
  return (
    <>
      <main>
        {/* /* ==== Hero Area :: Start ==== */}
        <section className="inner--hero confirmation--hero">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="inner--text">
                  <h3 className="heading">Login to your Account</h3>
                  <p>
                    Please enter your ID and password to access your account.
                    There you can see active packages and edit the details of
                    your personal account
                  </p>
                  <div className="go--back">
                    <a href="/" className="button">
                      <img src="assets/images/chevron-left.svg" alt="" />
                      <span>Go back</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ==== Hero Area :: End ==== */}
        {/* ==== Confirmation Table Area :: Start ==== */}
        <section className="Login--area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-8 col-md-8">
                <div className="login--form card--default">
                  {error && (
                    <div class="alert alert-danger">
                      <strong>Error!</strong> {error}
                    </div>
                  )}

                  <form onSubmit={handleSubmit}>
                    <div className="input--group">
                      <label htmlFor="login-id">Email Address</label>
                      <input
                        type="text"
                        className="form-control"
                        id="login-id"
                        name="login-id"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        
                      />
                    </div>
                    <div className="input--group">
                      <label htmlFor="login--password">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="login--password"
                        name="login--password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        
                      />
                    </div>
                    <div className="forget--area input--group">
                      <div className="check--box">
                        <input
                          type="checkbox"
                          
                        />
                        <label
                          htmlFor="remember--me"
                          style={{ marginLeft: "5px" }}
                        >
                          Remember me
                        </label>
                      </div>
                      <a href="/forget-password">Forgot password?</a>
                    </div>
                    <div className="forget--area input--group">
                      <GoogleCaptcha onChange={handleRecaptchaChange} />
                    </div>
                    <div className="btn--area mt_5">
                      <button type="submit" className="button w-100">
                        Login
                      </button>
                    </div>
                    {/* <p className="register--btn">
                      Don’t have an account? <a href="/#plans">Register</a>
                    </p> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ==== Confirmation Table Area :: End ==== */}
      </main>
      <Footer />
    </>
  );
};

export default Loginpage;
