import React from "react";
import { useParams } from "react-router-dom";

import Footer from "../components/footer";
import Common from "../components/registration/common";
import SignalLife from "../components/registration/signalLife";
import SignalGold from "../components/registration/signalGold";
//import SignalILife from "../components/registration/signal-i-life";
import SignalPremier from "../components/registration/signalPremier";
import SignalFamily from "../components/registration/signalFamily";
//import SignalIFamily from "../components/registration/signal-i-family";
const Registrationpage = () => {
  const { title } = useParams();

  // Function to render the appropriate component based on the title
  const renderComponent = () => {
    switch (title) {
      case "signallife":
        return (
          <SignalLife
            title="SIGNAL Life Registration"
            planType="signallife"
            details="SIGNAL Life is a customised and affordable SIGNAL programme, providing valuable Personal Accident Insurance, Home & Lifestyle Insurance and Outpatient Clinical Reimbursement, paired with a SIM card with exceptional telco services"
          />
        );
      case "signalgold":
        return (
          <SignalGold
            title="SIGNAL Gold Registration"
            planType="signalgold"
            details="SIGNAL Gold is a customised SIGNAL Plan developed exclusively for Senior Members of our society, offering Lifestyle, Home & Personal Accident Insurance and Outpatient Clinical Reimbursement, paired with a SIM card with exceptional telco services"
          />
        );
      // case "signal-i-life":
      //   return (
      //     <SignalILife
      //       title="SIGNAL i-Life Registration"
      //       planType="signal-i-life"
      //       details="SIGNAL i-Life is a customised and affordable Takaful SIGNAL Plan, providing valuable Personal Accident benefits and Outpatient Clinical Reimbursement, paired with a SIM card with exceptional telco services"
      //     />
      //   );
      case "signal_premier":
        return (
          <SignalPremier
            title="SIGNAL Premier Registration"
            planType="signal_premier"
            details="SIGNAL Premier is an exclusive SIGNAL Plan offering valuable protection encompassing Medical (Hospitalisation & Surgical), Personal Accident, Lifestyle and Home Care Insurance"
          />
        );
      case "signalfamily":
        return (
          <SignalFamily
            title="SIGNAL Family Registration"
            planType="signalfamily"
            details="SIGNAL Family is a customised SIGNAL Plan specially designed for families (up to 4 members in a
                                family), providing valuable Home, Lifestyle and Personal Accident protection in addition to
                                Outpatient/Clinical Medical Reimbursement"
          />
        );

      // case "signal-i-family":
      //   return (
      //     <SignalIFamily
      //       title="SIGNAL i-Family Registration"
      //       planType="signal-i-family"
      //       details="SIGNAL i-Family is a customised Takaful SIGNAL Plan specially designed for families (up to 4
      //                           members in a family), providing valuable Home, Lifestyle and Personal Accident protection in
      //                           addition to Outpatient/Clinical Medical Reimbursement"
      //     />
      //   );
      default:
        return null; // You can handle other cases accordingly
    }
  };

  return (
    <>
      <main>
        <Common />
        {renderComponent()}
      </main>
      <Footer />
    </>
  );
};

export default Registrationpage;
