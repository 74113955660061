import axiosWithAuth from "../contexts/axiosWithAuth";

export const ValidPlanAttachmentTypes = () => {
  return ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
};

export const ProcessRegistrationPlan = async (data, url) => {
  try {
    const axiosInstance = axiosWithAuth(); // Get the axios instance
    const response = await axiosInstance.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const WeekDays = () => {
  // Get current date
  const today = new Date();

  // Set time to end of today
  today.setHours(23, 59, 59, 999);

  // Get date one week before today
  const weekBefore = new Date(today);
  weekBefore.setDate(today.getDate() - 7);
  weekBefore.setHours(0, 0, 0, 0);

  // Array to store names of days
  const dayNames = [];

  // Loop through each day of the week
  for (let i = 0; i < 7; i++) {
    let temp = {};
    const date = new Date(weekBefore);
    date.setDate(today.getDate() - i);
    let day = date.toLocaleString("en-us", { weekday: "long" });
    temp.date = date.toDateString();
    temp.day = day;
    dayNames.push(temp);
  }
  return dayNames;
};

export const BuildJSONData = (formData) => {
  let jsonData = {
    nationality: formData.nationality,
    service: formData.service,
    planTitle: formData.planTitle,
    title: formData.title,
    plan: formData.plan,
    totalIndexes: formData.totalindexes,
    plan_type_value:
      formData.planTitle === "Signal Family" ? "family" : "single",
  };

  let totalIndexes = formData.totalindexes;
  if (
    formData.planTitle === "Signal Family" &&
    formData["child2"]["required"] === "false"
  ) {
    totalIndexes = totalIndexes.filter((value) => value !== "child2");
  }
  if (totalIndexes.length > 1) {
    jsonData.email = [];
    jsonData.mobile = [];
    jsonData.nric = [];
  }
  for (let i = 0; i < totalIndexes.length; i++) {
    let temp = formData[totalIndexes[i]];

    if (totalIndexes.length > 1) {
      jsonData.email[i] = temp.email;
      jsonData.mobile[i] = temp.mobile;
      jsonData.nric[i] = temp.nric;
      //console.log(temp.email, temp.mobile, temp.nric);
    } else {
      jsonData.email = temp.email;
      jsonData.mobile = temp.mobile;
      jsonData.nric = temp.nric;
    }
  }
  //console.log("jsonData", jsonData);
  return jsonData;
};

export const ValidateFormKeys = async (formData) => {
  try {
    const data = BuildJSONData(formData);
    const axiosInstance = axiosWithAuth(); // Get the axios instance
    const response = await axiosInstance.post(
      "/api/v1/validateFormFields",
      data
    );
    return response;
  } catch (error) {
    //console.log("errorrss", error);
    throw error;
  }
};

export const BuildFormData = (formData) => {
  const formDataToPost = new FormData();
  formDataToPost.append("nationality", formData.nationality);
  //formDataToPost.append("purchase", formData.purchase);
  formDataToPost.append("service", formData.service);
  formDataToPost.append("planTitle", formData.planTitle);
  formDataToPost.append("title", formData.title);
  //formDataToPost.append("existingcustomer", formData.existingcustomer);
  formDataToPost.append("plan", formData.plan);
  //formDataToPost.append("insurers", JSON.stringify(formData.insurers));
  let totalIndexes = formData.totalindexes;
  if (formData.planTitle === "Signal Family") {
    if (formData["child2"]["required"] === "false") {
      totalIndexes = totalIndexes.filter((value) => value !== "child2");
    }
  }

  formDataToPost.append(
    "plan_type_value",
    formData.planTitle === "Signal Family" ? "famly" : "single"
  );
  if (localStorage.getItem("influencer") !== "") {
    formDataToPost.append("influencer", localStorage.getItem("influencer"));
  }
  formDataToPost.append("totalIndexes", totalIndexes);

  let file_keys = ["child1", "child2", "parent2"];

  for (let i = 0; i < totalIndexes.length; i++) {
    let temp = formData[totalIndexes[i]];

    let phone_file_key = `phone_file${file_keys.includes(totalIndexes[i]) ? `_${totalIndexes[i]}` : ""
      }`;
    let nric_file_key = `nric_file${file_keys.includes(totalIndexes[i]) ? `_${totalIndexes[i]}` : ""
      }`;
    formDataToPost.append("name", temp.name);
    formDataToPost.append("address", temp.address);

    if (formData["nationality"] !== "malaysian") {
      formDataToPost.append("dob", temp.dob);
      formDataToPost.append("country", temp.country);
    }
    formDataToPost.append("purchase", temp.purchase);
    formDataToPost.append("email", temp.email);
    formDataToPost.append("gender", temp.gender);
    formDataToPost.append("mobile", temp.mobile);
    formDataToPost.append("nric", temp.nric);

    formDataToPost.append(nric_file_key, temp.nricfile);

    if (temp.purchase !== "new_sim_card") {
      formDataToPost.append(phone_file_key, temp.phonefile);
    }

    formDataToPost.append("postcode", temp.postcode);
    formDataToPost.append("risklocation", temp.risklocation);
    formDataToPost.append("state", temp.state);
  }
  return formDataToPost;
};

export const ValidatePlanFields = (field, value, nationality) => {
  let isValid = 1;
  let message = "";
  if (value) {
    if (field === "nricfile" || field === "phonefile") {
      if (value.size <= 500 * 1024) {
        const fileTypes = ValidPlanAttachmentTypes();
        //console.log("fileTypes", fileTypes,value.type);
        if (!fileTypes.includes(value.type)) {
          isValid = 0;
          message = "File Type not allowed";
        }
      } else {
        isValid = 0;
        message = "File size exceeds limit (500KB)";
      }
    }
  }

  //console.log("isValid", isValid, "message", message);

  return { isValid, message };
};

export const PlanRequiredFields = () => {
  return [
    "name",
    "nric",
    "state",
    "address",
    "email",
    "mobile",
    "postcode",
    "risklocation",
    "nricfile",
    "phonefile",
    "agreement",
    "terms",
  ];
};

export const PlanSkipables = () => {
  return [
    "nationality",
    "title",
    "purchase",
    "amount",
    "plan",
    "planTitle",
    //"existingcustomer",
    "service",
    "totalindexes",
    "exclusionChecked",
    "ageMeet",
    "insurers",
  ];
};

export const BenefitsGoldTable = () => {
  return [
    {
      heading: "INSURANCE BENEFITS",
      headers: ["INSURANCE BENEFITS", "", "SIGNAL Gold", "SIGNAL Gold Plus"],
      data: [
        [
          { isHead: 1, value: "Lifestyle Insurance" },
          "",
          { isHead: 1, value: "RM" },
          { isHead: 1, value: "RM" },
        ],
        [
          { key: "text-left", value: "Snatch theft or attempted snatch theft" },
          { key: "text-right", value: "Up to" },
          "400.00",
          "600.00",
        ],
        [
          { key: "text-left", value: "Online purchase protection" },
          { key: "text-right", value: "Up to" },
          "800.00",
          "1,200.00",
        ],

        [{ isHead: 1, value: "Home Care Insurance" }, "", "", ""],
        [
          { key: "text-left", value: "Loss or damage to home contents due to" },
          { key: "text-right", value: "Up to" },
          "2,000.00",
          "3,000.00",
        ],
        [
          { key: "text-left", value: "fire and/or burglary (forced entry)" },
          "",
          "",
          "",
        ],

        [{ isHead: 1, value: "Personal Accident Insurance" }, "", "", ""],
        [
          {
            key: "text-left",
            value: "Accidental death or permanent disablement",
          },
          { key: "text-right", value: "Up to" },
          "25,000.00",
          "50,000.00",
        ],
        [
          { key: "text-left", value: "Double indemnity on public conveyance" },
          { key: "text-right", value: "Up to" },
          "50,000.00",
          "100,000.00",
        ],
        [
          { key: "text-left", value: "Hospital Income (up to 60 days)" },
          "",
          "50.00/day",
          "100.00/day",
        ],
        [
          { key: "text-left", value: "Nursing care (up to 7 days)" },
          "",
          "100.00/day",
          "200.00/day",
        ],
        [
          { key: "text-left", value: "Serious Burns" },
          { key: "text-right", value: "Up to" },
          "1,000.00",
          "1,000.00",
        ],
        [
          { key: "text-left", value: "Fractures" },
          { key: "text-right", value: "Up to" },
          "1,000.00",
          "1,000.00",
        ],
        [
          {
            key: "text-left",
            value: "Funeral expenses (due to accidental death)",
          },
          "",
          "2,000.00",
          "3,000.00",
        ],
      ],
    },
    {
      heading: "TELCO BENEFITS",
      headers: ["TELCO BENEFITS", "", "SIGNAL Gold", "SIGNAL Gold Plus"],
      data: [
        [{ isHead: 1, value: "" }, "", "celcomdigi", "celcomdigi"],
        [{ key: "text-left", value: "Calls" }, "", "Unlimited", "Unlimited"],
        [{ key: "text-left", value: "Data" }, "", "53GB", "59GB"],
        [{ key: "text-left", value: "SMS" }, "", "300", "300"],
      ],
    },
    {
      heading: "REIMBURSEMENT BENEFIT",
      headers: ["REIMBURSEMENT BENEFIT", "", "SIGNAL Gold", "SIGNAL Gold Plus"],
      data: [
        [{ isHead: 1, value: "" }, "", "celcomdigi", "celcomdigi"],
        [
          {
            key: "text-left",
            value: "Outpatient clinical coverage (incl. prescribed medicine)",
          },
          "",
          "Based on 4x visit",
          "Based on 4x visit",
        ],
        [
          {
            key: "text-left",
            value:
              "50% reimbursement of outpatient clinical & prescribed medicine charges [subject to maximum of 1 visit every 3 months]",
          },
          "",
          "@ RM60.00 per visit",
          "@ RM100.00 per visit",
        ],
      ],
    },
    // Add more tables as needed
  ];
};

export const BenefitsPremierTable = () => {
  return [
    {
      heading: "INSURANCE BENEFITS",
      headers: ["INSURANCE BENEFITS", "", "SIGNAL Premier"],
      data: [
        [
          {
            isHead: 1,
            value:
              "Medical [Hospitalisation & Surgical] - Overall annual limit of RM10,000",
          },
          "",
          { isHead: 1, value: "RM" },
        ],
        [{ isHead: 1, value: "Hospital Expenses" }, "", ""],
        [
          { key: "text-left", value: "Hospital Room & Board" },
          "Up to",
          "200.00",
        ],
        [{ key: "text-left", value: "Intensive Care Unit" }, "Up to", "400.00"],
        [
          { key: "text-left", value: "Hospital Supplies & Services" },
          "",
          "As Charged",
        ],
        [
          { key: "text-left", value: "Ambulance Fees" },
          { key: "text-right", value: "Up to" },
          "250.00",
        ],
        [{ isHead: 1, value: "Surgical Expenses" }, "", ""],
        [{ key: "text-left", value: "Surgical Fees" }, "", "As Charged"],
        [{ key: "text-left", value: "Anaesthetist Fees" }, "", "As Charged"],
        [
          { key: "text-left", value: "Operating Theatre Fees" },
          "",
          "As Charged",
        ],
        [{ isHead: 1, value: "Non-Surgical Expenses" }, "", ""],
        [
          { key: "text-left", value: "Pre-Hospital Diagnostic Tests" },
          "",
          "As Charged",
        ],
        [
          { key: "text-left", value: "Pre-Hospital Specialist Consultation" },
          "",
          "As Charged",
        ],
        [
          { key: "text-left", value: "In-Hospital Physician Visit" },
          "",
          "As Charged",
        ],
        [
          { key: "text-left", value: "Post Hospitalistion Treatment" },
          "",
          "As Charged",
        ],
        [
          { key: "text-left", value: "Second Surgical Opinion" },
          "",
          "As Charged",
        ],
        [{ key: "text-left", value: "Day Care Procedures" }, "", "As Charged"],
        [
          { key: "text-left", value: "Emergency Outpatient Treatment" },
          "Up to",
          "200.00",
        ],
        [{ isHead: 1, value: "Lifestyle Insurance" }, "", ""],
        [
          { key: "text-left", value: "Snatch theft or attempted snatch theft" },
          "Up to",
          "400.00",
        ],
        // [
        //   { key: "text-left", value: "Smart device protection" },
        //   "Up to",
        //   "3,000.00",
        // ],
        [
          { key: "text-left", value: "Online purchase protection" },
          "Up to",
          "800.00",
        ],
        [
          { key: "text-left", value: "Loss of sports equipment" },
          "Up to",
          "300.00",
        ],
        [{ isHead: 1, value: "Home Care Insurance" }, "", ""],
        [
          { key: "text-left", value: "Loss or damage to home contents due to" },
          "Up to",
          "10,000.00",
        ],
        [
          { key: "text-left", value: "fire and/or burglary (forced entry)" },
          "",
          "",
        ],
        [{ isHead: 1, value: "Personal Accident Insurance" }, "", ""],
        [
          {
            key: "text-left",
            value: "Accidental death or permanent disablement",
          },
          "Up to",
          "30,000.00",
        ],
        [
          {
            key: "text-left",
            value: "Double indemnity on public conveyance or overseas",
          },
          "Up to",
          "60,000.00",
        ],
        [
          { key: "text-left", value: "Hospital Income (up to 60 Days) " },
          "",
          "300.00/day",
        ],
        //[{ key: "text-left", value: "Nursing care" }, "Up to", "2,000.00"],
        // [
        //   { key: "text-left", value: "Lifestyle modification expenses" },
        //   "Up to",
        //   "20,000.00",
        // ],
        [
          { key: "text-left", value: "Outpatient accident treatment" },
          "Up to",
          "3,000.00",
        ],
        [
          {
            key: "text-left",
            value: "Funeral expenses (due to accidental death)",
          },
          "Up to",
          "2,000.00",
        ],
        [
          { key: "text-left", value: "Credit card and loan indemnity" },
          "Up to",
          "3,000.00",
        ],
      ],
    },
    {
      heading: "TELCO BENEFITS",
      headers: ["TELCO BENEFITS", "", "SIGNAL Premier"],
      data: [
        [
          { isHead: 1, value: "" },
          { isHead: 1, key: "text-right", value: "" },
          "celcomdigi",
        ],
        [{ key: "text-left", value: "Calls" }, "", "Unlimited"],
        [{ key: "text-left", value: "Data" }, "", "65GB"],
        [{ key: "text-left", value: "SMS" }, "", "300"],
      ],
    },
  ];
};
export const BenefitsLifeTable = () => {
  return [
    {
      heading: "INSURANCE BENEFITS",
      headers: ["INSURANCE BENEFITS", "", "SIGNAL Life", "SIGNAL Life Plus"],
      data: [
        [
          { isHead: 1, value: "Lifestyle Insurance" },
          "",
          { isHead: 1, value: "RM" },
          { isHead: 1, value: "RM" },
        ],
        [
          { key: "text-left", value: "Snatch theft or attempted snatch theft" },
          { key: "text-right", value: "Up to" },
          "400.00",
          "500.00",
        ],
        [
          { key: "text-left", value: "Smart device protection" },
          { key: "text-right", value: "Up to" },
          "3,000.00",
          "5,000.00",
        ],
        [
          { key: "text-left", value: "Online purchase protection" },
          { key: "text-right", value: "Up to" },
          "800.00",
          "1,500.00",
        ],
        [
          { key: "text-left", value: "Loss of sports equipment" },
          { key: "text-right", value: "Up to" },
          "1,500.00",
          "2,500.00",
        ],

        [
          { isHead: 1, value: "Home Care Insurance" },
          "",
          { isHead: 1, value: "RM" },
          { isHead: 1, value: "RM" },
        ],
        [
          { key: "text-left", value: "Loss or damage to home contents due to" },
          { key: "text-right", value: "Up to" },
          "2,000.00",
          "3,000.00",
        ],
        [
          { key: "text-left", value: "fire and/or burglary (forced entry)" },
          { key: "text-right", value: "" },
          "",
          "",
        ],

        [
          { isHead: 1, value: "Personal Accident Insurance" },
          "",
          { isHead: 1, value: "RM" },
          { isHead: 1, value: "RM" },
        ],
        [
          {
            key: "text-left",
            value: "Accidental death or permanent disablement",
          },
          { key: "text-right", value: "Up to" },
          "30,000.00",
          "50,000.00",
        ],
        [
          { key: "text-left", value: "Double indemnity on public conveyance" },
          { key: "text-right", value: "Up to" },
          "60,000.00",
          "100,000.00",
        ],
        [
          { key: "text-left", value: "Hospital Income" },
          "",
          "300.00/day",
          "500.00/day",
        ],
        [
          { key: "text-left", value: "Nursing care" },
          { key: "text-right", value: "Up to" },
          "2,000.00",
          "4,000.00",
        ],
        [
          { key: "text-left", value: "Lifestyle modification expenses" },
          { key: "text-right", value: "Up to" },
          "20,000.00",
          "30,000.00",
        ],
        [
          { key: "text-left", value: "Rehabilitation expenses" },
          { key: "text-right", value: "Up to" },
          " 3,000.00",
          "5,000.00",
        ],
        [
          {
            key: "text-left",
            value: "Funeral expenses (due to accidental death)",
          },
          "",
          "3,000.00",
          "5,000.00",
        ],
        [
          { key: "text-left", value: "Credit card and loan indemnity" },
          { key: "text-right", value: "Up to" },
          "3,000.00",
          "5,000.00",
        ],
      ],
    },
    {
      heading: "TELCO BENEFITS",
      headers: ["TELCO BENEFITS", "", "SIGNAL Life", "SIGNAL Life Plus"],
      data: [
        [
          { isHead: 1, value: "" },
          { isHead: 1, key: "text-right", value: "" },
          "celcomdigi",
          "celcomdigi",
        ],
        [{ key: "text-left", value: "Calls" }, "", "Unlimited", "Unlimited"],
        [{ key: "text-left", value: "Data" }, "", "50GB", "56GB"],
        [{ key: "text-left", value: "SMS" }, "", "300", "300"],
      ],
    },
    {
      heading: "REIMBURSEMENT BENEFIT",
      headers: ["REIMBURSEMENT BENEFIT", "", "SIGNAL Life", "SIGNAL Life Plus"],
      data: [
        [
          { isHead: 1, value: "" },
          { isHead: 1, key: "text-right", value: "" },
          "celcomdigi",
          "celcomdigi",
        ],
        [
          {
            key: "text-left",
            value: "Outpatient clinical coverage (incl. prescribed medicine)",
          },
          "",
          "Based on 4x visit",
          "Based on 4x visit",
        ],
        [
          {
            key: "text-left",
            value:
              "50% reimbursement of outpatient clinical & prescribed medicine charges [subject to maximum of 1 visit every 3 months]",
          },
          "",
          "@ RM60.00 per visit",
          "@ RM60.00 per visit",
        ],
      ],
    },
    // Add more tables as needed
  ];
};
export const BenefitsFamilyTable = () => {
  return [
    {
      heading: "INSURANCE BENEFITS",
      headers: [
        "INSURANCE BENEFITS",
        "",
        "Parent-1",
        "Parent-2",
        "Child-1",
        "Child-2",
      ],
      data: [
        [
          { isHead: 1, value: "Personal Accident Insurance" },
          "",
          { isHead: 1, value: "RM" },
          { isHead: 1, value: "RM" },
          { isHead: 1, value: "RM" },
          { isHead: 1, value: "RM" },
        ],
        [
          {
            key: "text-left",
            value: "Accidental death or permanent disablement",
          },
          { key: "text-right", value: "Up to" },
          "50,000.00",
          "30,000.00",
          "25,000.00",
          "25,000.00",
        ],
        [
          { key: "text-left", value: "Double indemnity on public conveyance" },
          { key: "text-right", value: "Up to" },
          "100,000.00",
          "60,000.00",
          "50,000.00",
          "50,000.00",
        ],
        [
          {
            key: "text-left",
            value: "Accidental Hospital Income (up to 60 Days)",
          },
          { key: "text-right", value: "" },
          "500.00/day",
          "300.00/day",
          "50.00/day",
          "50.00/day",
        ],
        [
          { key: "text-left", value: "Accidental Medical Reimbursement" },
          { key: "text-right", value: "Up to" },
          "2,000.00",
          "1,000.00",
          "500.00",
          "500.00",
        ],
        [
          { key: "text-left", value: "Loan Indemnity" },
          { key: "text-right", value: "Up to" },
          "5,000.00",
          "3,000.00",
          "N/A",
          "N/A",
        ],
        [
          { key: "text-left", value: "Nursing Care (Up to 7 Days)" },
          { key: "text-right", value: "Up to" },
          "4,000.00",
          "2,000.00",
          "N/A",
          "N/A",
        ],
        [
          { key: "text-left", value: "Lifestyle Modification Expenses" },
          { key: "text-right", value: "Up to" },
          "30,000.00",
          "20,000.00",
          "N/A",
          "N/A",
        ],
        [
          {
            key: "text-left",
            value: "Funeral expenses (due to accidental death)",
          },
          { key: "text-right", value: "" },
          "5,000.00",
          "3,000.00",
          "1,500.00",
          "1,500.00",
        ],

        [
          { isHead: 1, value: "Lifestyle Insurance" },
          "",
          { isHead: 1, value: "RM" },
          { isHead: 1, value: "RM" },
          { isHead: 1, value: "RM" },
          { isHead: 1, value: "RM" },
        ],
        [
          { key: "text-left", value: "Snatch theft" },
          { key: "text-right", value: "Up to" },
          "500.00",
          "400.00",
          "N/A",
          "N/A",
        ],
        [
          {
            key: "text-left",
            value:
              "Online purchase protection [combined coverage for all 4 family members ie   total combined coverage of RM1,500.00 across all 4 family members]",
          },
          { key: "text-right", value: "Up to" },
          "1,500.00",
          "1,500.00",
          "1,500.00",
          "1,500.00",
        ],
        // [
        //   { key: "text-left", value: "Device protection" },
        //   { key: "text-right", value: "Up to" },
        //   "5,000.00",
        //   "3,000.00",
        //   "1,000.00",
        //   "1,000.00",
        // ],

        [
          { isHead: 1, value: "Home Care Insurance" },
          "",
          { isHead: 1, value: "RM" },
          { isHead: 1, value: "RM" },
          { isHead: 1, value: "RM" },
          { isHead: 1, value: "RM" },
        ],
        [
          {
            key: "text-left",
            value: "Loss or damage to home contents due to fire/burglary",
          },
          { key: "text-right", value: "Up to" },
          "10,000.00",
          "N/A",
          "N/A",
          "N/A",
        ],
        // [
        //   { key: "text-left", value: "TV and Astro Decoder Protection" },
        //   { key: "text-right", value: "Up to" },
        //   "3,000.00",
        //   "N/A",
        //   "N/A",
        //   "N/A",
        // ],
        // [
        //   { key: "text-left", value: " Flood Cover" },
        //   { key: "text-right", value: "Up to" },
        //   "3,000.00",
        //   "N/A",
        //   "N/A",
        //   "N/A",
        // ],
      ],
    },
    {
      heading: "TELCO BENEFITS",
      headers: [
        "TELCO BENEFITS",
        "",
        "Parent-1",
        "Parent-2",
        "Child-1",
        "Child-2",
      ],
      data: [
        [
          { isHead: 1, value: "" },
          { isHead: 1, key: "text-right", value: "" },
          "celcomdigi",
          "celcomdigi",
          "celcomdigi",
          "celcomdigi",
        ],
        [
          { key: "text-left", value: "Calls" },
          "",
          "Unlimited",
          "Unlimited",
          "Unlimited",
          "Unlimited",
        ],
        [
          {
            key: "text-left",
            value:
              "Data [combined coverage for all 4 family members ie 200GB to be shared between all 4 family members]",
          },
          "",
          "up to 200GB Shared",
          "up to 200GB Shared",
          "up to 200GB Shared",
          "up to 200GB Shared",
        ],
      ],
    },
    {
      heading: "REIMBURSEMENT BENEFIT",
      headers: [
        "REIMBURSEMENT BENEFIT",
        "",
        "Parent-1",
        "Parent-2",
        "Child-1",
        "Child-2",
      ],
      data: [
        [
          { isHead: 1, value: "" },
          { isHead: 1, key: "text-right", value: "" },
          "celcomdigi",
          "celcomdigi",
          "celcomdigi",
          "celcomdigi",
        ],
        [
          {
            key: "text-left",
            value: "Outpatient clinical coverage (incl. prescribed medicine)",
          },
          "",
          "Based on 8x visit",
          "Based on 8x visit",
          "Based on 8x visit",
          "Based on 8x visit",
        ],
        [
          {
            key: "text-left",
            value:
              "50% reimbursement of outpatient clinical & prescribed medicine charges [subject to maximum of 2 visit every 3 months - combined coverage for all 4 family members ie total 8x visits per family per annum]",
          },
          "",
          "@RM60.00 per visit",
          "@RM60.00 per visit",
          "@RM60.00 per visit",
          "@RM60.00 per visit",
        ],
      ],
    },
    // Add more tables as needed
  ];
};
export const PlanTables = (type) => {
  let table = [];
  switch (type) {
    case "signalgold":
      table = BenefitsGoldTable();
      break;
    case "signal_premier":
      table = BenefitsPremierTable();
      break;
    case "signallife":
      table = BenefitsLifeTable();
      break;
    case "signalfamily":
      table = BenefitsFamilyTable();
      break;
    default:
      table = [];
      break;
  }
  return table;
};

export const GoldDataObject = () => {
  return {
    title: "signalgold",
    nationality: "malaysian",
    purchase: "port_existing",
    service: "celcomdigi",
    plan: "signalgold",
    amount: 68.0,
    //existingcustomer: "yes",
    planTitle: "Signal Gold",
    totalindexes: ["signalgold"],
    signalgold: {
      purchase: "new_sim_card",
      required: "true",
      optional: "false",
      phone_file_required: false,
    },
    insurers: ["self"],
  };
};

export const LifeDataObject = () => {
  return {
    title: "signallife",
    nationality: "malaysian",
    purchase: "port_existing",
    service: "celcomdigi",
    planTitle: "Signal Life",
    plan: "signallife",
    amount: 62.0,
    //existingcustomer: "yes",
    totalindexes: ["signallife"],
    signallife: {
      purchase: "new_sim_card",
      required: "true",
      optional: "false",
      phone_file_required: false,
    },
    insurers: ["self"],
  };
};

export const PremierDataObject = () => {
  return {
    title: "signal_premier",
    nationality: "malaysian",
    purchase: "port_existing",
    service: "celcomdigi",
    planTitle: "Signal Premier",
    plan: "signal_premier",
    //existingcustomer: "yes",
    exclusionChecked: false,
    amount: 92.0,
    ageMeet: true,
    totalindexes: ["signal_premier"],
    signal_premier: {
      purchase: "new_sim_card",
      required: "true",
      optional: "false",
      phone_file_required: false,
    },
    insurers: ["self"],
  };
};

export const FamilyDataObject = () => {
  return {
    title: "signalfamily",
    nationality: "malaysian",
    purchase: "port_existing",
    service: "celcomdigi",
    planTitle: "Signal Family",
    plan: "signalfamily",
    //existingcustomer: "yes",
    amount: 192.0,
    totalindexes: ["parent1", "parent2", "child1", "child2"],
    insurers: ["parent1"],
    parent1: {
      purchase: "new_sim_card",
      required: "true",
      optional: "false",
      phone_file_required: false,
    },
    parent2: {
      purchase: "new_sim_card",
      required: "true",
      optional: "false",
      phone_file_required: false,
    },
    child1: {
      purchase: "new_sim_card",
      required: "true",
      optional: "false",
      phone_file_required: false,
    },
    child2: {
      purchase: "new_sim_card",
      required: "true",
      optional: "true",
      phone_file_required: false,
    },
  };
};

export const PlansFormData = (type) => {
  let data = [];
  switch (type) {
    case "signalgold":
      data = GoldDataObject();
      break;
    case "signallife":
      data = LifeDataObject();
      break;
    case "signal_premier":
      data = PremierDataObject();
      break;
    case "signalfamily":
      data = FamilyDataObject();
      break;
    default:
      data = {};
      break;
  }
  return data;
};

export const renderStatusBadge = (status) => {
  let badgeClass = "custom-badge"; // Default class
  if (status === "paid") {
    badgeClass += " badge-paid"; // Add custom class for paid status
  } else {
    badgeClass += " badge-not-paid"; // Add custom class for not paid status
  }

  return <span className={badgeClass}>{status}</span>;
};

export const formatDate = (dateString) => {
  // Create a new Date object
  const date = new Date(dateString);

  // Extract year, month, and day from the date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const day = String(date.getDate()).padStart(2, "0");

  // Concatenate year, month, and day with dashes
  const formattedDate = `${year}-${month}-${day}`;

  // Return the formatted date
  return formattedDate;
};

function AddDaysToDate(date, durationInDays = 30) {
  const startDate = new Date(date); // Get the current date
  const endDate = new Date(startDate); // Create a copy of the start date
  // Check if the month has 31 days
  const isMonthWith31Days = [0, 2, 4, 6, 7, 9, 11].includes(
    startDate.getMonth()
  );

  // Adjust durationInDays if necessary
  if (durationInDays === 29 && isMonthWith31Days) {
    durationInDays = 30;
  }
  // Add the duration to the start date to get the end date
  endDate.setDate(startDate.getDate() + durationInDays);

  return endDate;
}

export const formatMonthRange = (startDateString) => {
  const startDate = new Date(startDateString);
  // Add one month to the start date to get the end date
  const endDate = AddDaysToDate(startDateString, 29);

  // Format both dates
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  //console.log("startDateString", startDateString);

  const formattedDateRange = `${formattedStartDate} to ${formattedEndDate}`;
  return formattedDateRange;
};
function isValidDate(dateString) {
  // If dateString is empty or null, it's not a valid date
  if (!dateString) return false;

  // Attempt to create a Date object from the string
  const date = new Date(dateString);

  // Check if the date object is valid
  // Also, check if the date string is not "Invalid Date"
  return !isNaN(date.getTime());
}
export const formatPlanDates = (
  registered_date,
  first_line_activation_date
) => {
  if (isValidDate(first_line_activation_date)) {
    const registeredDateafter12Months = new Date(registered_date);

    registeredDateafter12Months.setMonth(
      registeredDateafter12Months.getMonth() + 12
    );
    registeredDateafter12Months.setDate(
      registeredDateafter12Months.getDate() - 1
    );

    return formatDateRange(registered_date, registeredDateafter12Months);
  } else {
    const registeredDateafter12Months = new Date(registered_date);
    registeredDateafter12Months.setMonth(
      registeredDateafter12Months.getMonth() + 12
    );
    registeredDateafter12Months.setDate(
      registeredDateafter12Months.getDate() - 1
    );

    return formatDateRange(registered_date, registeredDateafter12Months);
  }
};

export const getPolicyDocName = (title, Name) => {
  let file = "";
  let file1 = "";
  let link = "assets/pdf/plans/";
  switch (title) {
    case "Signal Gold":
      file = "SIGNAL Gold - Policy.pdf";
      break;
    case "Signal Life":
      file = "SIGNAL Life - Policy.pdf";
      break;
    case "Signal Premier":
      file = "SIGNAL Premier - Policy (Medical).pdf";
      file1 = "SIGNAL Premier - Policy (Personal Accident).pdf";
      break;
    case "Signal Family":
      file = "Signal Family - Policy.pdf";
      //file1 = "SIGNAL Family - Policy 2.pdf";
      break;
    default:
      break;
  }
  return (
    <>
      {file !== "" && ( // Conditional rendering of div
        <div className="plan">
          <span>{Name}</span>
          <ul>
            <li>
              <a target="_blank" rel="noreferrer" href={link + file}>
                Download Here
              </a>
            </li>
            {file1 && file1 !== "" && (
              <li>
                <a target="_blank" rel="noreferrer" href={link + file1}>
                  Download Here
                </a>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export const getPDSDocName = (title, Name) => {
  let file = "";
  let file1 = "";
  let link = "assets/pdf/plans/";
  switch (title) {
    case "Signal Gold":
      file = "SIGNAL Gold - PDS.pdf";
      break;
    case "Signal Life":
      file = "SIGNAL Life - PDS.pdf";
      break;
    case "Signal Premier":
      file = "SIGNAL Premier - PDS (Medical).pdf";
      file1 = "SIGNAL Premier - PDS (Personal Accident).pdf";

      break;
    case "Signal Family":
      file = "Signal Family PDS.pdf";
      //file1 = "SIGNAL Family - PDS 2.pdf";
      break;
    default:
      break;
  }
  return (
    <>
      {file !== "" && ( // Conditional rendering of div
        <div className="plan">
          <span>{Name}</span>
          <ul>
            <li>
              <a target="_blank" rel="noreferrer" href={link + file}>
                Download Here
              </a>
            </li>
            {file1 && file1 !== "" && (
              <li>
                <a target="_blank" rel="noreferrer" href={link + file1}>
                  Download Here
                </a>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export const getTCDocName = (title, Name) => {
  let file = "MYSIGNAL Terms _ Conditions.pdf";
  let link = "assets/pdf/plans/";
  return (
    <>
      {file !== "" && ( // Conditional rendering of div
        <div className="plan">
          <span>{Name}</span>
          <ul>
            <li>
              <a target="_blank" rel="noreferrer" href={link + file}>
                Download Here
              </a>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export const getReimbursementClaimFormDocname = (title, Name) => {
  let file = "SIGNAL Claim Form for Outpatient Clinical Coverage.pdf";
  let link = "assets/pdf/forms/";
  return (
    <>
      {file !== "" && ( // Conditional rendering of div
        <div className="plan">
          <span>{Name}</span>
          <ul>
            <li>
              <a target="_blank" rel="noreferrer" href={link + file}>
                Download here
              </a>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export const getClaimFormDocname = (title, Name) => {
  let file1 = "";
  let file2 = "";
  let file3 = "";

  let link = "assets/pdf/forms/";
  switch (title) {
    case "Signal Gold":
      file1 = "Berjaya Sompo - PA Claim Form (Fatal).pdf";
      file2 = "Berjaya Sompo - PA Claim Form (Non-fatal).pdf";
      break;
    case "Signal Life":
      file1 = "Allianz_E-payment Form.pdf";
      file2 = "Allianz_PA Claim Form.pdf";
      break;
    case "Signal Premier":
      file1 = "Berjaya Sompo - PA Claim Form (Fatal).pdf";
      file2 = "Berjaya Sompo - PA Claim Form (Non-fatal).pdf";
      break;
    case "Signal Family":
      file1 = "Berjaya Sompo - PA Claim Form (Fatal).pdf";
      file2 = "Berjaya Sompo - PA Claim Form (Non-fatal).pdf";
      file3 = "Berjaya Sompo H_S Claim Form Part I  Part II_26.10.2020.pdf";
      break;
    default:
      break;
  }
  return (
    <>
      {file1 !== "" && ( // Conditional rendering of div
        <div className="plan">
          <span>{Name}</span>
          <ul>
            <li>
              <a target="_blank" rel="noreferrer" href={link + file1}>
                Download Here
              </a>
            </li>
            {file2 !== "" && (
              <li>
                <a target="_blank" rel="noreferrer" href={link + file2}>
                  Download Here
                </a>
              </li>
            )}
            {file3 !== "" && (
              <li>
                <a target="_blank" rel="noreferrer" href={link + file3}>
                  Download Here
                </a>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export const formatDateRange = (startDateStr, endDateStr) => {
  if (startDateStr === "" || endDateStr === "") {
    return "";
  }
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const startMonth = startDate.toLocaleString("default", { month: "short" });
  const startDay = startDate.getDate();
  const startYear = startDate.getFullYear();

  const endMonth = endDate.toLocaleString("default", { month: "short" });
  const endDay = endDate.getDate();
  const endYear = endDate.getFullYear();

  return `${startDay} ${startMonth} ${startYear} to ${endDay} ${endMonth} ${endYear}`;
};

export const amountPaid = (amount) => {
  if (amount) {
    return `RM ${amount}`;
  }
  return "RM 0.00";
};

export const totalAmount = (amount) => {
  return `RM ${amount}`;
};

export const BuildPaymentData = (data, index, byIndex) => {
  try {
    //console.log(data.CustomerCurrentInvoice);
    let object = {
      name: data.name,
      email: data.email,
      nric_or_passport: data.nric_or_passport,
      address: data.address,
      mobile_no: data.signal_mobile_no,
      plan_type: data.plan,
      plan_period: formatMonthRange(
        !byIndex
          ? data.CustomerCurrentInvoice["current_billing_start_Date"]
          : data.CustomerInvoices[index]["current_billing_start_Date"]
      ),
      is_first: !byIndex
        ? data.CustomerCurrentInvoice["is_first"]
        : data.CustomerInvoices[index]["is_first"],
      invoice_date: !byIndex
        ? data.CustomerCurrentInvoice["invoice_date"]
        : data.CustomerInvoices[index]["invoice_date"],
      invoice_no: !byIndex
        ? data.CustomerCurrentInvoice["invoice_no"]
        : data.CustomerInvoices[index]["invoice_no"],
      status: !byIndex
        ? data.CustomerCurrentInvoice["invoice_status"]
        : data.CustomerInvoices[index]["invoice_status"],
      domestic_usage_amount: !byIndex
        ? data.CustomerCurrentInvoice["domestic_usage_amount"]
        : data.CustomerInvoices[index]["domestic_usage_amount"],
      initial_deposit_amount: !byIndex
        ? data.CustomerCurrentInvoice["initial_deposit_amount"]
        : data.CustomerInvoices[index]["initial_deposit_amount"],
      international_usage_amount: !byIndex
        ? data.CustomerCurrentInvoice["international_usage_amount"]
        : data.CustomerInvoices[index]["international_usage_amount"],
      invoice_status: !byIndex
        ? data.CustomerCurrentInvoice["invoice_status"]
        : data.CustomerInvoices[index]["invoice_status"],
      other_charges_amount: !byIndex
        ? data.CustomerCurrentInvoice["other_charges_amount"]
        : data.CustomerInvoices[index]["other_charges_amount"],
      payment_due_date: !byIndex
        ? data.CustomerCurrentInvoice["payment_due_date"]
        : data.CustomerInvoices[index]["payment_due_date"],
      pending_overdue_amount: !byIndex
        ? data.CustomerCurrentInvoice["pending_overdue_amount"]
        : data.CustomerInvoices[index]["pending_overdue_amount"],
      plan_amount: !byIndex
        ? data.CustomerCurrentInvoice["plan_amount"]
        : data.CustomerInvoices[index]["plan_amount"],
      plan_total_amount: !byIndex
        ? data.CustomerCurrentInvoice["plan_total_amount"]
        : data.CustomerInvoices[index]["plan_total_amount"],
      sst_charges_amount: !byIndex
        ? data.CustomerCurrentInvoice["sst_charges_amount"]
        : data.CustomerInvoices[index]["sst_charges_amount"],
      sub_total: !byIndex
        ? data.CustomerCurrentInvoice["sub_total"]
        : data.CustomerInvoices[index]["sub_total"],
      international_roaming_amount: !byIndex
        ? data.CustomerCurrentInvoice["international_roaming_amount"]
        : data.CustomerInvoices[index]["international_roaming_amount"],
      current_invoice_amount_after_sst: !byIndex
        ? data.CustomerCurrentInvoice["current_invoice_amount_after_sst"]
        : data.CustomerInvoices[index]["current_invoice_amount_after_sst"],
      current_invoice_amount_before_sst: !byIndex
        ? data.CustomerCurrentInvoice["current_invoice_amount_before_sst"]
        : data.CustomerInvoices[index]["current_invoice_amount_before_sst"],
    };
    //console.log("object", object);
    return object;
  } catch (error) {
    // Handle the error gracefully
    console.error("Error occurred in BuildPaymentData function:", error);
    throw error;
  }
};

export const totalWithoutGst = (value) => {
  return Number(value * 2).toFixed(2);
};
export const gstValue = (value) => {
  return Number(value * 0.06).toFixed(2);
};
export const grandTotal = (value) => {
  let number = Number(value);
  const firstMonth = number;
  let sum = firstMonth + number;
  let gst = Number(gstValue(value));
  return sum + gst;
};

export const getPurchasevalue = (value) => {
  if (value === "existing_without_simcard") {
    return "Port Existing Non-DIGI customers – with new SIM card";
  }
  if (value === "new_sim_card") {
    return "Register for new Mobile Number and Plan (with new SIM card)";
  }
  if (value === "port_existing") {
    return "Port Existing DIGI customers – with existing SIM card";
  }
};

export const getUserData = (data, key, type = "") => {
  if (data.title === "signalfamily") {
    return data[type]?.[key] ?? "";
  } else {
    return data[data.title]?.[key] ?? "";
  }
};

export const registrationEndPoint = (title) => {
  if (title.includes("family")) {
    return "/api/v1/familyPlanRegistration";
  }
  return "/api/v1/normalPlanRegistration";
};

export const invoiceTemplate = (paymentData,viewInvoice = false) => {

  let logo = `assets/images/logo.webp`
  if(viewInvoice){
    logo = `../assets/images/logo.webp`
  }   
  let content = `<!DOCTYPE html>
  <html lang="en">
  
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Invoice</title>
    <style>
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
    body {
      font-family: 'Roboto', Arial, sans-serif;
    }
    .green-text {
      color: green;
    }
    .red-text {
      color: red;
    }
    .invoice {
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    .section {
      flex: 1;
      min-width: 45%;
      padding: 10px;
    }
    .section.left {
      align-self: flex-start;
    }
    .section.right {
      align-self: flex-end;
    }
    .section-title {
      font-weight: bold;
      font-size: 1.2em;
      margin-bottom: 5px;
    }
    .heading-title {
      font-weight: bold;
      font-size: 1.5em;
      margin-top: 5px;
      text-align: center;
    }
    .sub-heading-title {
      font-weight: bold;
      font-size: 1.0em;
    }
    .table {
      width: 100%;
      border-collapse: collapse;
    }
    .table th, .table td {
      padding: 8px;
      border: 1px solid #ddd;
      text-align: left;
    }
    .table th {
      background-color: #f2f2f2;
      font-weight: bold;
    }
    .logo {
      width: 100px;
      margin-bottom: 20px;
    }
    .key {
      font-weight: bold;
      width: 200px;
      display: inline-block;
    }
    .value {
      display: inline-block;
    }
    .custom {
      width: 90%;
      margin: 20px auto;
    }
    .info-table {
      width: 100%;
      border-collapse: collapse;
    }
    .info-table th, .info-table td {
      padding: 8px;
      border: none;
      text-align: left;
    }
    .info-table th {
      background-color: #f2f2f2;
      font-weight: bold;
    }
  </style>
  </head>
  
  <body>
    <div class="invoice">
      <div class="section left"> <img src="${logo}" alt="Logo" class="logo">
        <div class="section-title">Outstanding Amount</div>
         <div class="sub-heading-title">RM ${paymentData.plan_total_amount
    }</div>
         <div class="sub-heading-title">[due on ${paymentData.payment_due_date
    }]</div>
      </div>
      <div class="section right">
      <div class="heading-title"><h1>Invoice</h1></div>
      <table class="info-table">
      <tr>
      <th colspan="2" style="text-align: center;">Basic Details</th>
      </tr>
      <tr>
      <td class="sub-heading-title"><strong>Name</strong></td>
      <td class="sub-heading-title">${paymentData.name}</td>
      </tr>
      <tr>
      <td class="sub-heading-title"><strong>Mobile Number</strong></td>
      <td class="sub-heading-title">${paymentData.mobile_no}</td>
      </tr>
      <tr>
      <td class="sub-heading-title"><strong>Account Number</td>
      <td class="sub-heading-title">${paymentData.mobile_no}</td>
      </tr>
      <tr>
      <td class="sub-heading-title"><strong>Invoice Date</strong></td>
      <td class="sub-heading-title">${paymentData.invoice_date}</td>
      </tr>
      <tr>
      <td class="sub-heading-title"><strong>Invoice Number</strong></td>
      <td class="sub-heading-title">${paymentData.invoice_no}</td>
      </tr>
      <tr>
      <td class="sub-heading-title"><strong>Invoice Period</strong></td>
      <td class="sub-heading-title">${paymentData.plan_period}</td>
      </tr>
      <tr>
      <td class="sub-heading-title"><strong>Payment Due Date</strong></td>
      <td class="sub-heading-title">${paymentData.payment_due_date}</td>
      </tr>
      <tr>
      <td class="sub-heading-title"><strong>Payment Status</strong></td>
      <td class="sub-heading-title ${paymentData.status === "paid" ? "green-text" : "red-text"}">${paymentData.status}</td>
      </tr>
      </table>
      </div>
    </div>
    <div class="invoice">
    <div class="section">
      <div class="section-title">Invoice Details</div>
      <table class="table">
        <tr>
          <th colspan="4">Summary of Charges/Payment</th>
        </tr>
        <tr>
          <td>Previous Balance</td>
          <td><strong>RM</strong></td>
          <td><strong>RM</strong></td>
          <td><strong>RM</strong></td>
        </tr>
        <tr>
          <td><strong>Previous Invoices</strong></td>
          <td></td>
          <td>${paymentData.plan_amount}</td>
          <td></td>
        </tr>
        <tr>
          <td><strong>Payment received on</strong></td>
          <td></td>
          <td>${paymentData.plan_amount}</td>
          <td></td>
        </tr>
        <tr>
          <td><strong>Previous overdue amount</strong></td>
          <td></td>
          <td></td>
          <td>${paymentData.status === "paid"
      ? Number(0).toFixed(2)
      : paymentData.pending_overdue_amount
    }</td>
        </tr>
        <tr>
          <td><strong>Current Charges</strong></td>
          <td></td>
          <td><strong>RM</strong></td>
          <td><strong>RM</strong></td>
        </tr>
        ${paymentData.is_first === 1
      ? `<tr>
            <td>${paymentData.plan_type} - Deposit</td>
            <td></td>
            <td>${paymentData.plan_amount}</td>
            <td></td>
          </tr>`
      : ""
    }
        <tr>
          <td>${paymentData.plan_type} - Monthly subscription fee</td>
          <td></td>
          <td>${paymentData.plan_amount}</td>
          <td></td>
        </tr>
        <tr>
        <th colspan="4"><strong>Additional charges</strong></th>
        </tr>
        <tr>
          <td><strong>Domestic Usage</strong></td>
          <td>${paymentData.domestic_usage_amount}</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><strong>International Usage</strong></td>
          <td>${paymentData.international_usage_amount}</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
        <td><strong>International Roaming - Total Charges</strong></td>
        <td>${paymentData.international_roaming_amount}</td>
        <td></td>
        <td></td>
      </tr>
        <tr>
          <td><strong>Others</strong></td>
          <td>${paymentData.other_charges_amount}</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><strong>Total current charges [before SST]</strong></td>
          <td></td>
          <td>${paymentData.current_invoice_amount_before_sst}</td>
          <td></td>
        </tr>
        <tr>
          <td><strong>SST [6%]</strong></td>
          <td></td>
          <td>${paymentData.sst_charges_amount}</td>
          <td></td>
        </tr>
        <tr>
          <td><strong>Total current charges [after SST]</strong></td>
          <td></td>
          <td>${paymentData.current_invoice_amount_after_sst}</td>
          <td></td>
        </tr>
        <tr>
          <td><strong>Total Outstanding Amount</strong></td>
          <td></td>
          <td></td>
          <td>${paymentData.current_invoice_amount_after_sst}</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="custom">
    <div class="section">
      <p>Please arrange payment on or before the due date to avoid service disruption and suspension of insurance coverage.</p>
      <p>Payment should be remitted as follows;</p>
      <div>
        <div class="key">Bank Account Name:</div> <span class="value"> MySignal Marketing Sdn Bhd </span> </div>
      <div>
        <div class="key">Bank Name:</div> <span class="value"> OCBC Al-Amin Bank Berhad </span></div>
      <div>
        <div class="key">Bank Account Number:</div> <span class="value"> 172 101 7957</span></div>
      <div>
        <div class="key">Swift Code:</div> <span class="value"> OABBMYKLXXX </span></div>
    </div>
    <div></div>
    <div class="section">
      <p>MySignal Marketing Sdn Bhd (201901003462 / 1312788-U)</p>
    <p>A-15-1, Block A, Jaya One, 72A Jalan Prof. Diraja Ungku Aziz, 46200 Petaling Jaya, Selangor Darul Ehsan</p>
    <p>Customer Service Contact Details      +603 2779 2419 / enquiry@mysignal.com.my</p>
    <p>This is a computer generated document. No signature is required</p>
  </div>
  </div>
  </body>
  </html>`;
    console.log("content",content)
  return content;
};
export const CheckUniqueValues = (formData) => {
  const updatedErrors = {};
  const updatedUniqueNRICs = new Set();
  const updatedUniquePhones = new Set();

  const totalindexes = formData.totalindexes;
  for (const index of totalindexes) {
    const { nric, mobile } = formData[index];
    if (nric) {
      if (updatedUniqueNRICs.has(nric)) {
        updatedErrors[index] = {
          ...updatedErrors[index],
          nric: `NRIC can be duplicate for other members`,
        };
      } else {
        updatedUniqueNRICs.add(nric);
      }
    }
    if (mobile) {
      if (updatedUniquePhones.has(mobile)) {
        updatedErrors[index] = {
          ...updatedErrors[index],
          mobile: `Mobile Phone number can not be duplicate for other members`,
        };
      } else {
        updatedUniquePhones.add(mobile);
      }
    }
  }

  return { updatedErrors };
};

export function DateReadingFormat(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  const suffix = getOrdinalSuffix(day);
  return `${day}${suffix} ${month} ${year}`;
}

function getOrdinalSuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export function DOBValidation(value, range) {
  // Parse the date string into a Date object
  const dob = new Date(value);

  // Calculate the age
  const today = new Date();
  let age = today.getFullYear() - dob.getFullYear();
  const monthDiff = today.getMonth() - dob.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
    age--;
  }

  // Parse the range string into minimum and maximum ages
  const [minAge, maxAge] = range.split("-").map(Number);

  // Check if the age falls within the specified range
  return age >= minAge && age <= maxAge;
}

export function NRICValidation(value) {
  //console.log(value, value.length);
  // Check if the length of the value is exactly 12
  if (value.length !== 12) {
    return false;
  }

  // Extract year, month, and day from the input value
  var year = value.substr(0, 2);
  var month = value.substr(2, 2);
  var day = value.substr(4, 2);

  // Get the current year
  var currentYear = new Date().getFullYear().toString().substr(-2);

  // Check if the year is less than or equal to the current year
  if (parseInt(year) > parseInt(currentYear)) {
    year = "19" + year; // Interpret as 19xx
  } else {
    year = "20" + year; // Interpret as 20xx
  }

  // Check if month is between 01 and 12
  if (parseInt(month) < 1 || parseInt(month) > 12) {
    return false;
  }

  // Check if day is between 01 and 31 based on the month
  var daysInMonth = new Date(parseInt(year), parseInt(month), 0).getDate();
  if (parseInt(day) < 1 || parseInt(day) > daysInMonth) {
    return false;
  }

  // Format the date as yyyy-mm-dd
  var formattedDate =
    year + "-" + month.padStart(2, "0") + "-" + day.padStart(2, "0");

  return formattedDate;
  // Return true and the formatted date
  //return { isValid: true, formattedDate: formattedDate };
}

export const NRICAndDOBValidation = (formData, range) => {
  const DOBupdatedErrors = {};

  const totalindexes = formData.totalindexes;

  for (const index of totalindexes) {
    if (formData[index]["required"] === "false") {
      continue;
    }
    if (formData.nationality !== "malaysian") {
      let dob = formData[index]["dob"];
      let isValid = DOBValidation(dob, range);
      //console.log("isValid", isValid, dob, range);
      if (!isValid) {
        DOBupdatedErrors[index] = {
          ...DOBupdatedErrors[index],
          dob: `DOB does not meet Eligibility Criteria`,
        };
      }
    } else {
      let nric_or_passport = formData[index]["nric"];
      // console.log(
      //   "nric_or_passport",
      //   nric_or_passport,
      //   formData,
      //   formData[index]
      // );
      let NRICDOB = NRICValidation(nric_or_passport);
      //console.log("NRICDOB", NRICDOB);
      if (NRICDOB) {
        let isValid = DOBValidation(NRICDOB, range);
        //console.log("isValid", isValid, NRICDOB, range);
        if (!isValid) {
          DOBupdatedErrors[index] = {
            ...DOBupdatedErrors[index],
            nric: `AS Per Your NRIC Number you do not meet Age Limit Eligibility Criteria`,
          };
        }
      } else {
        DOBupdatedErrors[index] = {
          ...DOBupdatedErrors[index],
          nric: `Invalid NRIC Number`,
        };
      }
    }
  }
  // console.log(
  //   "formData, range",
  //   formData,
  //   range,
  //   totalindexes,
  //   DOBupdatedErrors,
  //   "DOBupdatedErrors"
  // );
  return { DOBupdatedErrors };
};

export function hasValidationErrors(errors, customErrors) {
  return (
    Object.values(errors).some((errorArray) => errorArray.length > 0) ||
    Object.values(customErrors).some((errorObject) =>
      Object.values(errorObject).some((error) => error)
    )
  );
}

export const ValidationErrorMessage = ({ errors }) => {
  return (
    <div className="alert alert-danger d-inline-block mt-5" role="alert">
      <h4 className="alert-heading">Errors</h4>
      <ul>
        {errors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </div>
  );
};

export const getURL = (plan) => {
  const hasPartner = localStorage.getItem("influencer");
  let url = "/registration/" + plan;
  if (hasPartner && hasPartner !== "") {
    url = "/registration/partner/" + hasPartner + "/" + plan;
  }
  return url;
};
export const getURLSLUG = (url) => {
  const arr = {
    "signal-premier": "signal_premier",
    "signal-life": "signallife",
    "signal-gold": "signalgold",
    "signal-family": "signalfamily",
    "signal-ilife": "signal-i-life",
    "signal-ifamily": "signal-i-family",
  };

  return arr[url] || ""; // Return the corresponding URL slug or an empty string if not found
};
export const PlanRedirect = (plan) => {
  let url = "";
  // const arr2 = [
  //   "signal-premier",
  //   "signal-life",
  //   "signal-gold",
  //   "signal-family",
  //   "signal-ilife",
  //   "signal-ifamily",
  // ];
  const arr = ["signal-life", "signal-gold", "signal-family", "signal-premier"];
  if (arr.includes(plan)) {
    let urlSLUG = getURLSLUG(plan);
    if (urlSLUG && urlSLUG !== "") {
      url = getURL(urlSLUG);
    }
  }
  return url;
};

export const PlansDetailsTableHeaders = (data) => {
  let headers;
  if (data.plan === "Signal Family") {
    headers = [
      "Personal Accident -1",
      //"Personal Accident -2",
      //"",
    ];
  } else if (data.plan === "Signal Premier") {
    headers = [
      "Personal Accident -1",
      //"Personal Accident -2",
      "Medical (H&S)",
      "",
    ];
  } else if (data.plan === "Signal Gold") {
    headers = ["Personal Accident -1"];
  } else if (data.plan === "Signal Life") {
    headers = ["Personal Accident -1"];
  }

  return (
    <thead>
      <tr>
        {headers.map((header, index) => (
          <th
            key={index}
            className="text-center"
            colSpan={data.plan === "Signal Premier" ? 1 : 2}
          >
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export const PlansDetailsCertificatesTableHeaders = (data, key) => {
  let headers;
  if (data.plan === "Signal Family") {
    headers =
      key === "Certificate"
        ? ["Certificate Number", "Period"]
        : ["Policy Number"];
  } else if (data.plan === "Signal Premier") {
    headers = [
      key === "Certificate" ? "Certificate Number" : "Policy Number",
      //key === 'Certificate' ? 'Certificate Number' : 'Policy Number',
      key === "Certificate" ? "Certificate Number" : "Policy Number",
      key === "Certificate" ? "Period" : "",
    ];
  } else if (data.plan === "Signal Gold") {
    headers =
      key === "Certificate"
        ? ["Certificate Number", "Period"]
        : ["Policy Number"];
  } else if (data.plan === "Signal Life") {
    headers =
      key === "Certificate"
        ? ["Certificate Number", "Period"]
        : ["Policy Number"];
  }

  return (
    <tr>
      {headers.map((header, index) => (
        <th
          key={index}
          className="text-center"
          colSpan={
            data.plan === "Signal Premier" ? 1 : key !== "Certificate" ? 2 : 1
          }
        >
          {header}
        </th>
      ))}
    </tr>
  );
};

export const PlansDetailsTableRows = (data, data1, key) => {
  let headers;
  if (data.plan === "Signal Family") {
    headers =
      key === "Certificate"
        ? [
          data1.certificate_no_pd_1 || "N/A",
          `${data1.current_billing_start_Date || ""} to ${data.current_billing_end_Date || ""
          }`,
        ]
        : [data.current_policy_pd_1 || "N/A"];
  } else if (data.plan === "Signal Premier") {
    headers = [
      key === "Certificate"
        ? data1.certificate_no_pd_1 || "N/A"
        : data.current_policy_pd_1 || "N/A",
      //key === "Certificate" ? data1.certificate_no_pd_2 || "N/A"  : data.current_policy_pd_2 || "N/A",
      key === "Certificate"
        ? data1.certificate_no_pd_2 || "N/A"
        : data.current_policy_pd_2 || "N/A",
      key === "Certificate"
        ? `${data1.current_billing_start_Date || ""} to ${data.current_billing_end_Date || ""
        }`
        : "",
    ];
  } else if (data.plan === "Signal Gold") {
    headers =
      key === "Certificate"
        ? [
          data1.certificate_no_pd_1 || "N/A",
          `${data1.current_billing_start_Date || ""} to ${data.current_billing_end_Date || ""
          }`,
        ]
        : [data.current_policy_pd_1 || "N/A"];
  } else if (data.plan === "Signal Life") {
    headers =
      key === "Certificate"
        ? [
          data1.certificate_no_pd_1 || "N/A",
          `${data1.current_billing_start_Date || ""} to ${data.current_billing_end_Date || ""
          }`,
        ]
        : [data.current_policy_pd_1 || "N/A"];
  }

  return (
    <tr>
      {headers.map((header, index) => (
        <th
          key={index}
          className="text-center"
          colSpan={
            data.plan === "Signal Premier" ? 1 : key !== "Certificate" ? 2 : 1
          }
        >
          {header}
        </th>
      ))}
    </tr>
  );
};

export const AssetsURLWhenInfluencer = (title) => {
  let url = `../assets/images/${title}`;
  const hasPartner = localStorage.getItem("influencer");

  if (hasPartner && hasPartner !== "") {
    url = `../../../assets/images/${title}`;
  }
  return url;
};

export const AssetsFiles = (file) => {
  let url = `../assets/pdf/plans/${file}`;
  const hasPartner = localStorage.getItem("influencer");
  
  if (hasPartner && hasPartner !== "") {
    url = `../../../assets/pdf/plans/${file}`;
  }
  return url;
};


export const AssetsPDFiles = (file,hasPartner) => {
  let url = `../assets/pdf/${file}`;

 
  if (hasPartner && hasPartner !== "") {
    url = `../../../assets/pdf/${file}`;
  }
  return url;
};

export const AssetsExclusionFiles = () => {
  let url = `../assets/pdf/exclusion.pdf`;
  const hasPartner = localStorage.getItem("influencer");

  if (hasPartner && hasPartner !== "") {
    url = `../../../assets/pdf/exclusion.pdf`;
  }
  return url;
};

export const getDatavalue = (type) => {
  let value = "N/A";
  //console.log('type',type)
  switch (type) {
    case "signalgoldplus":
      value = "50GB + 9GB";
      break;
    case "signalgold":
      value = "50GB + 3GB";
      break;
    case "signallife":
      value = "50GB";
      break;
    case "signallifeplus":
      value = "50GB + 6GB";
      break;
    case "signalpremier":
    case "signal_premier":
      value = "50GB + 15GB";
      break;
    case "signalfamily":
      value = "200GB";
      break;
    default:
      break;
  }
  return value;
};

export const getCallsvalue = (type) => {
  let value = "Unlimited";
  switch (type) {
    case "signalgoldplus":
      value = "Unlimited";
      break;
    case "signalgold":
      value = "Unlimited";
      break;
    case "signallife":
      value = "Unlimited";
      break;
    case "signallifeplus":
      value = "Unlimited";
      break;
    case "signalpremier":
    case "signal_premier":
      value = "Unlimited";
      break;
    case "signalfamily":
      value = "Unlimited";
      break;
    default:
      break;
  }
  return value;
};

export const getSMSvalue = (type) => {
  let value = "300";
  switch (type) {
    case "signalgoldplus":
      value = "300";
      break;
    case "signalgold":
      value = "300";
      break;
    case "signallife":
      value = "300";
      break;
    case "signallifeplus":
      value = "300";
      break;
    case "signalpremier":
    case "signal_premier":
      value = "300";
      break;
    case "signalfamily":
      value = "300";
      break;
    default:
      break;
  }
  return value;
};
