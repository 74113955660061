import React from "react";

const PurchasePlans = ({ categories, preFormData, onPurchaseChange }) => {
  const handlePurchaseChange = (value, category) => {
    onPurchaseChange(value, category);
  };
  // const handleExistingCustomerChange = (event) => {

  //     onExistingCustomerChange(event.target.value);
  // };

  const Title = (category) => {
    let key = category.toUpperCase();
    let title = "";
    switch (key) {
      case "PARENT1":
        title = "(Parent 1)";
        break;
      case "PARENT2":
        title = "(Parent 2)";
        break;
      case "CHILD1":
        title = "(Child 1)";
        break;
      case "CHILD2":
        title = "(Child 2)";
        break;

      default:
        return "";
    }
    return title;
  };

  return (
    <>
      {categories.map((category, index) => (
        <React.Fragment key={index}>
          <div className="select--purchase plan--box mt_35">
            <div className="select--plan">
              <h4>Select Purchase {Title(category)}</h4>
              <div className="plan--option">
                <div className="input--group">
                  <input
                    type="radio"
                    id={`new_sim_card-${category}`}
                    value="new_sim_card"
                    name={`new_sim_card-${category}`}
                    onChange={() =>
                      handlePurchaseChange("new_sim_card", category)
                    }
                    checked={
                      preFormData[category]["purchase"] === "new_sim_card"
                    }
                  />
                  <label htmlFor={`new_sim_card-${category}`}>
                    <p>
                      Register for new Mobile Number and Plan (with new SIM
                      card)
                    </p>
                  </label>
                </div>
                <div className="input--group">
                  <label
                    htmlFor={`new_sim_card-${category}`}
                    style={{ backgroundColor: "#FFF7ED" }}
                  >
                    <p>Maintain and port existing Mobile Number to new Plan?</p>
                  </label>
                </div>
                <div className="input--group">
                  <input
                    type="radio"
                    id={`port_existing-${category}`}
                    value="port_existing"
                    name={`port_existing-${category}`}
                    onChange={() =>
                      handlePurchaseChange("port_existing", category)
                    }
                    checked={
                      preFormData[category]["purchase"] === "port_existing"
                    }
                  />
                  <label htmlFor={`port_existing-${category}`}>
                    <p>DIGI customers – with existing SIM card</p>
                  </label>
                </div>
                <div className="input--group">
                  <input
                    type="radio"
                    id={`existing_without_card-${category}`}
                    value="existing_without_simcard"
                    name={`existing_without_card-${category}`}
                    onChange={() =>
                      handlePurchaseChange("existing_without_simcard", category)
                    }
                    checked={
                      preFormData[category]["purchase"] ===
                      "existing_without_simcard"
                    }
                  />
                  <label htmlFor={`existing_without_card-${category}`}>
                    <p>Non-DIGI customers – with new SIM card </p>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default PurchasePlans;
