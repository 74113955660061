import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  totalWithoutGst,
  gstValue,
  grandTotal,
  getUserData,
  BuildFormData,
  registrationEndPoint,
  ProcessRegistrationPlan,
  getPurchasevalue,
  getURL,
} from "../../utils/utils";

const Main = ({ formData }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
   
    let url  = getURL(formData.title)
    navigate(url, {
      state: { formData: formData },
    });
  };

  const getImageURL = () => {
    let url = "../assets/images/chevron-left.svg"
    const hasPartner = localStorage.getItem("influencer")
  
    if(hasPartner && hasPartner!==""){
      url = "../../assets/images/chevron-left.svg"
    }
    return url
  }
  const PlanHeadTitle = () => {
    switch (formData.planTitle) {
      case "Signal Gold":
        return (
          <p>
            <strong>SIGNAL Gold</strong> is a customised SIGNAL Plan developed
            exclusively for Senior Members of our society (aged 60 years and
            above).
          </p>
        );
      case "Signal Life":
        return (
          <p>
            <strong>SIGNAL Life</strong> SIGNAL Life is a customised and
            affordable SIGNAL Plan, providing valuable Personal Accident, Home &
            Lifestyle Insurance.
          </p>
        );
      case "Signal Family":
        return (
          <p>
            <strong>SIGNAL Family</strong> is a customised SIGNAL Plan specially
            designed for families (up to 4 members in a family), providing
            valuable Home, Lifestyle and Personal Accident protection.
          </p>
        );
      case "Signal Premier":
        return (
          <p>
            <strong>SIGNAL Premier</strong> is an exclusive SIGNAL Plan offering
            valuable Medical (Hospitalisation & Surgical), Personal Accident,
            Lifestyle and Home Care Insurance.
          </p>
        );
      default:
        return null;
    }
  };
  return (
    <section className="inner--hero confirmation--hero">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="inner--text">
              <h3 className="heading">Confirmation</h3>
              {PlanHeadTitle(formData.PlanTitle)}
              <div className="go--back">
                {/* Call handleGoBack function on button click */}
                <div className="button" onClick={handleGoBack}>
                  <img src={getImageURL()} alt="" />
                  <span>Go back</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ErrorMessage = ({ errors }) => {
  return (
    <div className="alert alert-danger" role="alert">
      <h4 className="alert-heading">Errors</h4>
      <ul>
        {errors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </div>
  );
};

const Dashboard = ({ formData }) => {
  const navigate = useNavigate();
  const [processing, setProcessing] = useState("");

  const handleGoBack = () => {
   
    let url  = getURL(formData.title)
    navigate(url, {
      state: { formData: formData },
    });
  };
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    const postData = BuildFormData(formData);
    const url = registrationEndPoint(formData.title);
    try {
      const response = await ProcessRegistrationPlan(postData, url);
      if (response.data.payment_url && response.data.status === 200) {
        window.location.href = response.data.payment_url;
      } else {
        setError([
          "An unexpected error occurred. Please Try Again By ReUploading Your NRIC/Passport and Latest Phone Statement Files.",
        ]);
      }
      setProcessing(false);
      //console.log("response", response);
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.errors || [error.response.data.message]);
      } else {
        setError([
          "An unexpected error occurred. Please Try Again By ReUploading Your NRIC/Passport and Latest Phone Statement Files.",
        ]);
      }
      setProcessing(false);
    }
  };

  return (
    <section className="confirmation--table--area">
      <div className="container">
        <div className="alert alert-success" role="alert">
          <span>
            <strong>Info! </strong>
            By Clicking Confirm & Proceed To Payment,You will now be redirected
            to our payment gateway eGHL site.
          </span>
        </div>

        {error && <ErrorMessage errors={error} />}
        <div className="row">
          <div className="col-md-12">
            <div className="confirmation--table--wrapper">
              <div className="table-responsive confirmation--table card--default">
                <table className="table table-bordered text-center text-sm-left">
                  <tbody>
                    <tr>
                      <th>SR.NO</th>
                      <th>NAME</th>
                      <th>NRIC or Passport Number</th>
                      <th>EMAIL</th>
                      <th>MOBILE</th>
                      <th>PLAN</th>
                      <th>Purchase</th>
                      <th>TELCO PROVIDER</th>
                      <th>AMOUNT</th>
                    </tr>
                    {formData.title === "signalfamily" ? (
                      <>
                        {formData.totalindexes.map(
                          (key, index) =>
                            formData[key]["required"] === "true" ? (
                              <tr key={key}>
                                {/* Row {index + 1} */}
                                <td>{key.toUpperCase()}</td>
                                <td data-th="NAME">
                                  {getUserData(formData, "name", key)}
                                </td>
                                <td data-th="NRIC or Passport Number">
                                  {getUserData(formData, "nric", key)}
                                </td>
                                <td data-th="EMAIL">
                                  {getUserData(formData, "email", key)}
                                </td>
                                <td data-th="MOBILE">
                                  {getUserData(formData, "mobile", key)}
                                </td>
                                <td data-th="PLAN">{formData.plan}</td>
                                <td data-th="PURCHASE">
                                  {getUserData(formData, "purchase", key)}
                                </td>
                                <td data-th="TELCO PROVIDER">celcomdigi</td>
                                <td data-th="AMOUNT">
                                  {index > 0
                                    ? "-"
                                    : `RM ${Number(formData.amount).toFixed(
                                        2
                                      )}`}
                                </td>
                              </tr>
                            ) : null // Return null if the condition is not met
                        )}
                      </>
                    ) : (
                      <tr>
                        {/* Single row when formData.title is not "signalfamily" */}
                        <td>1</td>
                        <td data-th="NAME">{getUserData(formData, "name")}</td>
                        <td data-th="NRIC or Passport Number">
                          {getUserData(formData, "nric")}
                        </td>
                        <td data-th="EMAIL">
                          {getUserData(formData, "email")}
                        </td>
                        <td data-th="MOBILE">
                          {getUserData(formData, "mobile")}
                        </td>
                        <td data-th="PLAN">{formData.plan}</td>
                        <td data-th="PURCHASE">
                          {" "}
                          {getPurchasevalue(getUserData(formData, "purchase"))}
                        </td>
                        <td data-th="TELCO PROVIDER">celcomdigi</td>
                        <td data-th="AMOUNT">
                          RM {Number(formData.amount).toFixed(2)}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td data-th="NAME" colSpan="8" className="">
                        <b>Deposit [equivalent to 1-month subscription fee]</b>
                      </td>
                      <td data-th="AMOUNT">
                        RM {Number(formData.amount).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="7" className="blank--colspan"></td>
                      <td data-th="TELCO PROVIDER">
                        <b>Total before SST</b>
                      </td>
                      <td data-th="AMOUNT">
                        RM {totalWithoutGst(formData.amount)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="7" className="blank--colspan"></td>
                      <td data-th="TELCO PROVIDER">
                        <b>SST (6%)</b>
                      </td>
                      <td data-th="AMOUNT">RM {gstValue(formData.amount)}</td>
                    </tr>
                    <tr>
                      <td colSpan="7" className="blank--colspan"></td>
                      <td data-th="TELCO PROVIDER">
                        <b>Total After SST</b>
                      </td>
                      <td data-th="AMOUNT">RM {grandTotal(formData.amount)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div></div>
              <div className="btn--area">
                <div onClick={handleGoBack}>Edit</div>
                <button
                  onClick={handleSubmit}
                  disabled={processing}
                  className="button"
                  type="submit"
                >
                  Confirm & Proceed to Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ConfirmationSection = ({ formData }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!formData || Object.keys(formData).length === 0) {
      // Redirect to home page if formDataFromPreviousPage is not available
      navigate("/");
    }
  }, [formData, navigate]);

  if (!formData || Object.keys(formData).length === 0) {
    return <></>;
  }
  return (
    /* ==== Dashboard :: Start ==== */
    <>
      <Main formData={formData} />
      <Dashboard formData={formData} />
    </>

    /*==== Contact Area :: End ==== */
  );
};

export default ConfirmationSection;
